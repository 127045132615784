import { khubHrefMapper } from "../utils/khub-href-mapper";

const slack_contact_url = "https://app.slack.com/client/T0220L0L9NU/C02PL5KMJGY";
export const FooterContent = {
  TEXT: "At Accelerate we’re dedicated to supporting software development and engineering teams that are eager to enhance their performance.",
  SLACK_ICON_URL: slack_contact_url,
  NEWSLETTER_ICON_URL: "mailto:GAccelerateFeedback@bp.com",
  columns: [
    {
      title: "Popular sections",
      links: [
        {
          name: "Code library",
          url: "/marketplace",
        },
        {
          name: "Tooling self-service",
          url: khubHrefMapper("Tooling"),
        },
        {
          name: "DevOps FAQ",
          url: "/best-practices/faq",
        },
      ],
    },
    {
      title: "About",
      links: [
        {
          name: "About Accelerate",
          url: "/about",
        },
        {
          name: "How to guides",
          url: khubHrefMapper("DevOps-Methodology/How_To"),
        },
        {
          name: "What’s new",
          url: "/whats-new",
        },
      ],
    },
    {
      title: "Help",
      links: [
        {
          name: "Submit feedback",
          url: slack_contact_url,
        },
      ],
    },
  ],
};
