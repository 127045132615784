/* istanbul ignore file */

import { Tree, getParents } from "@minoru/react-dnd-treeview";
import styles from "./nav-tree.module.css";
import { Nav } from "reactstrap";
import { isNodeOrParentInvisible, useNavTreeQuery } from "../../api/khub/v2/documents/tree";
import { NavTreeItem } from "./nav-tree-item";
import { NavTreeSkeleton } from "../../components/skeletons/nav-tree";
import { TESTID } from "./enums";
import { useIsAuthenticated } from "../../hooks/use-is-authenticated";

export function NavTree({ docUuid }: { docUuid: string }) {
  const { email } = useIsAuthenticated();
  const {
    isLoading,
    isSuccess,
    data: treeData,
  } = useNavTreeQuery({
    uuid: docUuid,
    email: email || "",
  });

  if (isLoading || !isSuccess || !treeData) {
    return <NavTreeSkeleton className="pt-3" data-testid={TESTID.navSkeleton} />;
  }

  // initialise the tree to have all parents of the active node as "open"
  const openNodes = getParents(treeData, docUuid).map(({ id }) => id);
  openNodes.push(docUuid);

  return (
    <Nav className={styles["tree-container"]}>
      <Tree
        canDrag={() => false}
        canDrop={() => false}
        onDrop={() => {}}
        tree={treeData}
        rootId="0"
        render={(node, { depth, isOpen, onToggle, hasChild }) => {
          if (isNodeOrParentInvisible(node, treeData)) {
            return <></>;
          }

          return (
            <NavTreeItem
              active={node.id === docUuid}
              draggable={false}
              node={node}
              hasChild={hasChild}
              depth={depth}
              isOpen={isOpen}
              onToggle={onToggle}
            />
          );
        }}
        classes={{
          root: styles["treeRoot"],
          draggingSource: styles["draggingSource"],
          placeholder: styles["placeholderContainer"],
        }}
        listComponent="div"
        listItemComponent="div"
        sort={false}
        initialOpen={openNodes}
      />
    </Nav>
  );
}
