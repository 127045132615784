import { Edit24, FileAdd24, Kebab24, ReorderRow24, Trash24 } from "@bphxd/ds-core-react/lib/icons";
import { DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { KHubModalAction, useModalActions } from "../../hooks/khub/use-modal-store";
import { Link } from "../Link";
import { trackEvent } from "../../utils/event-tracker";
import { useDocument } from "../../hooks/khub/use-page-store";
import { ExtractDocInfoV2 } from "../../views/dac/helpers";

type KebabMenuProps = {
  active: boolean;
  path: string;
  uuid: string;
  isOpen: boolean;
};

export const KebabMenu = ({ active, path, uuid, isOpen }: KebabMenuProps) => {
  const { setIsKHubPageModalOpen, setKHubModalAction, setKHubModalPath, setKHubModalUuid } = useModalActions();
  const { uuid: page_uuid, editPageHref } = ExtractDocInfoV2();
  const page_path = useDocument();
  const toggleClasses = "btn-square rounded-circle justify-content-end kebab-toggle";

  const eventTrackingData = {
    name: "button_click",
    view: "khub",
    feature: "kebab_menu",
    page_path,
    page_uuid,
    item_path: path,
    item_uuid: uuid,
  };

  const isTreeRoot = path.split("/").length === 1;

  const editPageHrefLink = uuid !== page_uuid ? editPageHref.replace(page_uuid, uuid) : editPageHref;

  function onClickHandler(component: string, modalAction: KHubModalAction) {
    return () => {
      trackEvent({ ...eventTrackingData, component });
      setIsKHubPageModalOpen(true);
      setKHubModalAction(modalAction);
      setKHubModalPath(path);
      setKHubModalUuid(uuid);
    };
  }

  return (
    <>
      <DropdownToggle
        split
        color="quarternary"
        className={toggleClasses}
        onClick={() => {
          trackEvent({ ...eventTrackingData, component: "toggle" });
        }}
      >
        <Kebab24 className={"kebab-icon " + (isOpen || active ? "kebab-icon-visible" : "kebab-icon-hidden")} />
      </DropdownToggle>
      <DropdownMenu className="py-0">
        {path && (
          <DropdownItem onClick={onClickHandler("create_subpage_button", KHubModalAction.Create)}>
            <FileAdd24 className="btn-icon-prefix" />
            Create subpage
          </DropdownItem>
        )}
        {editPageHrefLink && (
          <Link
            href={editPageHrefLink}
            hideExternalLinkIcon
            className="dropdown-item"
            role="menuitem"
            trackingEventProps={{
              ...eventTrackingData,
              name: "link_click",
              href: editPageHrefLink,
            }}
          >
            <Edit24 className="btn-icon-prefix" />
            Edit
          </Link>
        )}
        {!isTreeRoot && (
          <DropdownItem onClick={onClickHandler("reorder_button", KHubModalAction.Move)}>
            <ReorderRow24 className="btn-icon-prefix" />
            Move
          </DropdownItem>
        )}
        {path && (
          <DropdownItem onClick={onClickHandler("delete_page_button", KHubModalAction.Delete)}>
            <Trash24 className="btn-icon-prefix" />
            Delete
          </DropdownItem>
        )}
      </DropdownMenu>
    </>
  );
};
