import { Label as RSLabel, FormGroup as RSFormGroup, FormGroupProps } from "reactstrap";

export function FormGroup(props: FormGroupProps) {
  // extract regular from props
  const { regular, ...rest } = props;
  let className = "mb-0 mt-5 " + rest.className;

  if (className.includes("without-margin-top")) {
    className = className.replace("without-margin-top", "mt-0").replace(" mt-5", "");
  }

  // preserve the "default" behaviour of pre-pending .form-check-lg
  // HOWEVER build escape hatch if regular property is set on FormGroup.
  // Reactstrap adds .form-check to all FormGroup components that have a check property, so we don't need to double it.
  if (rest.check && !regular) {
    className = "form-check-lg " + className;
  }

  return (
    <RSFormGroup {...rest} className={className}>
      {rest.children}
    </RSFormGroup>
  );
}

export function Label(props: any) {
  const baseClasses = "lh-1-5 fw-normal fst-normal text-default ";
  return (
    <RSLabel {...props} className={baseClasses + props.className}>
      {props.children}
    </RSLabel>
  );
}
