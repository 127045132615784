import type { PropsWithChildren } from "react";
import { Link } from "./Link";

export function LinkIf({ href, children, ...rest }: PropsWithChildren<{ href?: string; [key: string]: any }>) {
  return href ? (
    <Link href={href} {...rest}>
      {children}
    </Link>
  ) : (
    <div {...rest}>{children}</div>
  );
}
