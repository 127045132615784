"use client";
import { useState, useEffect, forwardRef, Fragment, type PropsWithChildren } from "react";
import { InputGroup, InputGroupText, type InputProps, Input } from "reactstrap";

import styles from "./input.module.css";
import { trackEvent } from "../utils/event-tracker";
import { Remove24, Search24 } from "@bphxd/ds-core-react/lib/icons";

export const InputErrorMessage: React.FC<PropsWithChildren<{ className?: string }>> = (props) => (
  <div {...props} className={[styles["error-message"], props.className].join(" ")}>
    {props.children}
  </div>
);
type TrackProps = {
  type?: string;
  value: any;
  name: string;
};

function trackInput({ type, value, name }: TrackProps) {
  if (type === "radio") {
    trackEvent({ name: `input_${name}_selected`, value });
  } else {
    trackEvent({ name: `input_${name}_touched` });
  }
}

type ExtendedProps = {
  icon?: React.ReactNode;
  maxLength?: number;
  makeRoomForError?: boolean;
  error?: string;
  trackingName?: string;
};

const ExtendedInput = forwardRef(
  (
    { maxLength, makeRoomForError, onChange, error, trackingName, icon, ...props }: InputProps & ExtendedProps,
    ref: any,
  ) => {
    const [messageLength, setMessageLength] = useState(0);
    const [dirty, setDirty] = useState(false);
    useEffect(() => {
      if (dirty && trackingName) {
        const { type, value } = props;
        trackInput({ name: trackingName, type, value });
        if (type === "radio") {
          setDirty(false);
        }
      }
    }, [dirty]);

    const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (maxLength) {
        e.currentTarget.value = e.currentTarget.value.slice(0, maxLength);
        const valueSlice = e.currentTarget.value;
        setMessageLength(valueSlice.length);
      }
      if (onChange) {
        onChange(e);
      }
      setDirty(true);
    };

    return (
      <Fragment>
        {icon ? (
          <InputGroup className="input-group-merge" size="">
            <Input
              {...props}
              className={`${styles["input"]} form-control-prepended ${props.className}`}
              ref={ref}
              onChange={handleChangeInput}
            />
            <div className="input-group-prepend">
              <InputGroupText>{icon}</InputGroupText>
            </div>
          </InputGroup>
        ) : (
          <Fragment>
            <Input
              {...props}
              className={`${styles["input"]} ${props.className}`}
              ref={ref}
              onChange={handleChangeInput}
            />
          </Fragment>
        )}

        {maxLength && (
          <div className={styles["length-counter"]} data-testid="input-max-length-display">
            {messageLength} / {maxLength}
          </div>
        )}
        {(makeRoomForError || error) && (
          <InputErrorMessage data-testid="input-error-display">{error}</InputErrorMessage>
        )}
      </Fragment>
    );
  },
);

ExtendedInput.displayName = "ExtendedInput";

export default ExtendedInput;

type SearchInputProps = {
  value: string;
  onChange: (value: string) => void;
} & InputProps &
  ExtendedProps;

export function SearchInput(props: SearchInputProps) {
  const { onChange, value, className } = props;
  return (
    <InputGroup className={`input-group-merge me-4 bg-primary ${className}`} size="">
      <ExtendedInput
        type="text"
        {...props}
        className={`form-control-prepended ${value ? "form-control-appended" : ""}`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          e.preventDefault();
          onChange(e.target.value);
        }}
      />
      <div className="input-group-prepend">
        <InputGroupText>
          <Search24 />
        </InputGroupText>
      </div>
      {value && (
        <div className="input-group-append cursor-pointer" onClick={() => onChange("")}>
          <InputGroupText>
            <Remove24 />
          </InputGroupText>
        </div>
      )}
    </InputGroup>
  );
}
