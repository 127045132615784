import { AZURE_APP_REG_CLIENT_IDS } from "../auth/msal-config";
import urljoin from "url-join";
import { env } from "next-runtime-env";

const DEFAULT_API_SCOPE = `api://${AZURE_APP_REG_CLIENT_IDS.apollo}/Graphql.ReadWrite`;

const formatApiUrl = (url: string | undefined) => {
  if (!url) {
    return null;
  }

  // append trailing / if missing
  return urljoin(url, "/");
};

export const ACCELERATE_APIS = {
  microsoftADO: {
    url: formatApiUrl("https://dev.azure.com/"),
    scopes: ["499b84ac-1321-427f-aa17-267ca6975798/user_impersonation"],
  },
  microsoftGraph: {
    url: formatApiUrl("https://graph.microsoft.com/v1.0"),
    scopes: ["https://graph.microsoft.com/User.Read"],
  },
  notifications: {
    url: formatApiUrl(env("NEXT_PUBLIC_WS_URL")),
    scopes: [DEFAULT_API_SCOPE],
  },
  search: {
    url: formatApiUrl(env("NEXT_PUBLIC_SEARCH_API_URL")),
    scopes: [`api://${AZURE_APP_REG_CLIENT_IDS.search}/Search.ReadWrite`],
  },
  searchws: {
    url: formatApiUrl(env("NEXT_PUBLIC_SEARCH_WS_URL")),
    scopes: [`api://${AZURE_APP_REG_CLIENT_IDS.search}/Search.ReadWrite`],
  },
  apollo: {
    url: formatApiUrl(env("NEXT_PUBLIC_APOLLO_URL")),
    scopes: [DEFAULT_API_SCOPE],
  },
  knowledgehub: {
    url: formatApiUrl(env("NEXT_PUBLIC_KNOWLEDGEHUB_URL")),
    scopes: [`api://${AZURE_APP_REG_CLIENT_IDS.knowledgehub}/DocsAsCodeAPI.*`],
  },
  axel: {
    url: formatApiUrl(process.env.AXEL_INTERNAL_URL || "http://axel.accelerate.svc.cluster.local"),
    scopes: [DEFAULT_API_SCOPE],
  },
};

export function getApiScopes(uri: string) {
  return (
    Object.values(ACCELERATE_APIS).filter((api: { url: string | null; scopes: string[] }) =>
      uri.includes(api?.url ?? "-1"),
    )?.[0]?.scopes || ACCELERATE_APIS.apollo.scopes
  );
}
