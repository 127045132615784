import { Fragment, ReactNode } from "react";
import { LinkButton } from "./link-button";
import { Button } from "./button";
import { Avatar24 } from "@bphxd/ds-core-react/lib/icons";
import CategoryBadge from "./category-badge";

const ctaButtonStyle: {
  "data-testid": string;
  name: string;
  level: "primary";
  theme: "darker";
  rounded: "pill";
  size: "md";
} = {
  "data-testid": "hero-cta",
  name: "hero cta",
  level: "primary",
  theme: "darker",
  rounded: "pill",
  size: "md",
};

type HeroProps = {
  bgColor: string;
  heading: ReactNode;
  body: ReactNode;
  imageUrl: string;
  ctaOnClick: () => void;
  ctaText: string;
  ctaUrl: string;
  level: 1 | 2;
  subtitle: string;
  categories: { name: string }[];
  author: { name: string; photo_url?: string };
};

export function Hero({
  bgColor = "",
  heading = "",
  body = "",
  imageUrl = "",
  ctaOnClick = () => {},
  ctaText = "",
  ctaUrl = "",
  level = 1,
  subtitle = "",
  categories = [],
  author,
}: Partial<HeroProps>) {
  return (
    <div
      className={`d-flex flex-column w-100 pb-9 ${level === 1 ? "pt-5" : "pt-10"}`}
      style={{
        backgroundColor: bgColor,
        minHeight: level === 1 ? "197px" : "265px",
      }}
    >
      <div className={`d-flex position-relative ${level !== 1 ? " w-100" : ""}`}>
        <div className={`d-flex flex-column justify-content-start ${level !== 1 ? " w-100" : ""}`}>
          <div className={level === 1 ? "h3" : "h5"}>{heading}</div>

          <div className="h6">{subtitle.toLowerCase()}</div>

          {categories?.map(({ name }) => (
            <div className="mt-2" key={name}>
              <CategoryBadge category={name} />
            </div>
          ))}

          {body && (
            <div className={`col-xs-12 ${level === 1 ? "mt-3 p-0" : "mt-8 py-5 pe-0 ps-5"}`}>
              <div
                className="fs-6"
                style={{
                  width: level === 1 ? "100%" : "calc(100% - 360px)",
                }}
              >
                {body}
              </div>
            </div>
          )}

          <div>
            {ctaUrl ? (
              <LinkButton href={ctaUrl} {...ctaButtonStyle} onClick={ctaOnClick}>
                {ctaText}
              </LinkButton>
            ) : ctaText ? (
              <Button {...ctaButtonStyle} onClick={ctaOnClick}>
                {ctaText}
              </Button>
            ) : (
              <Fragment />
            )}
          </div>
        </div>
        {imageUrl && (
          <div
            className="bp-hero__right"
            style={{
              maxWidth: 360,
              position: level === 1 ? undefined : "absolute",
              right: level === 1 ? "" : "20px",
              top: level === 1 ? "" : subtitle ? "-10px" : "-40px",
            }}
          >
            <img
              src={imageUrl}
              alt="illustration"
              className="bp-hero__right__image-loading"
              onLoad={(ev) => {
                ev.currentTarget.className = "bp-hero__right__image-loaded";
              }}
              style={{
                objectFit: "contain",
                height: level === 1 ? "100%" : "185px",
              }}
            />
          </div>
        )}
      </div>

      {author?.name?.trim() && (
        <div className="d-flex justify-content-end align-items-center">
          {author?.photo_url ? (
            <img
              alt="profile"
              src={author.photo_url}
              style={{
                width: 32,
                height: "auto",
                borderRadius: "50%",
              }}
            />
          ) : (
            <Avatar24 />
          )}
          <div className="fs-6">{author.name}</div>
        </div>
      )}
    </div>
  );
}
