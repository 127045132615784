import { SVGProps } from "react";

export function AxelLogo24({ width = 24, height = 24 }: SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path stroke="#111" d="M4 12v5.004h4V21l4.995-3.996H20V6.018h-8" />
        <path
          stroke="#000"
          d="m6 3 1.082 2.304 2.419.37-1.75 1.794L8.164 10 6 8.805 3.837 10l.413-2.532L2.5 5.674l2.419-.37L5.7 3.64z"
        />
        <path
          d="m11.345 10.662 1.192.182-.804.824.33.768-1.055-.584-.572.584.196-1.197-.733-.46 1.446-.117z"
          stroke="#111"
        />
      </g>
    </svg>
  );
}

export function AxelLogo24Prefix() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" className="btn-icon-prefix" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path stroke="#111" d="M4 12v5.004h4V21l4.995-3.996H20V6.018h-8" />
        <path
          stroke="#000"
          d="m6 3 1.082 2.304 2.419.37-1.75 1.794L8.164 10 6 8.805 3.837 10l.413-2.532L2.5 5.674l2.419-.37L5.7 3.64z"
        />
        <path
          d="m11.345 10.662 1.192.182-.804.824.33.768-1.055-.584-.572.584.196-1.197-.733-.46 1.446-.117z"
          stroke="#111"
        />
      </g>
    </svg>
  );
}

export function AxelLogo32() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h32v32H0z" />
        <path stroke="#111" d="M16 8.6h10.667v14.41H16.6l-5.4 5.393V23.01H5.333V16" />
        <path
          d="M8.5 3.823 9.92 6.85l3.117.476-2.253 2.31.538 3.3-2.822-1.56-2.822 1.56.538-3.3-2.253-2.31 3.116-.476L8.5 3.823zM14.5 13.323l.648 1.381 1.39.213-1.004 1.028.243 1.49-1.277-.705-1.277.706.243-1.491-1.003-1.028 1.389-.213.648-1.38z"
          stroke="#111"
        />
      </g>
    </svg>
  );
}

export function AxelLogo64() {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h64v64H0z" />
        <path stroke="#111" d="M31.663 17.6H53.52v29.106l-20.571-.32L21.377 57.28V46.706H10.806V32" />
        <path
          d="m15.5 5.823 2.966 6.32 6.571 1.003-4.753 4.872 1.128 6.918-5.912-3.269-5.912 3.269 1.128-6.918-4.753-4.872 6.571-1.004L15.5 5.823zM28.822 23.823l1.73 3.686 3.807.581-2.753 2.822.656 4.024-3.44-1.902-3.44 1.902.656-4.024-2.753-2.822 3.807-.581 1.73-3.686z"
          stroke="#111"
        />
      </g>
    </svg>
  );
}
