import { Link } from "../Link";
import { Button } from "../button";

import { LeftSmall16, RightSmall16 } from "@bphxd/ds-core-react/lib/icons";
import { TESTID } from "../../views/khub/enums";
import { useDocument, useUuid } from "../../hooks/khub/use-page-store";

type NavButtonProps = {
  isNext?: boolean;
  href: string;
  lowerText: string;
};
export function NavButton({ isNext = true, href, lowerText }: NavButtonProps) {
  const page_uuid = useUuid();
  const page_path = useDocument();
  const text = isNext ? "Next" : "Previous";
  const dataTestId = isNext ? TESTID.nextBtn : TESTID.prevBtn;
  return (
    <Link
      dataTestid={dataTestId}
      name={dataTestId}
      href={href}
      hideExternalLinkIcon
      className="p-4 w-100"
      trackingEventProps={{
        name: "link_click",
        view: "khub",
        feature: "nav_button",
        href,
        page_uuid,
        page_path,
      }}
    >
      <Button
        name={dataTestId}
        className="py-3 px-0 fs-6 fw-lighter"
        trackingEventProps={{
          name: "button_click",
          view: "khub",
          feature: "nav_button",
          direction: text,
          page_uuid,
          page_path,
        }}
        Icon={isNext ? RightSmall16 : LeftSmall16}
        iconPosition={isNext ? "end" : "start"}
        level="quartenary"
        theme="standard"
        size="xs"
      >
        {text}
      </Button>

      <div className="d-flex text-start py-2 text-dark">{lowerText}</div>
    </Link>
  );
}
