"use client";
import { PropsWithChildren, useState } from "react";
import styles from "./modal.module.css";
import { Modal as RSModal, ModalBody } from "reactstrap";
import { Remove32 } from "@bphxd/ds-core-react/lib/icons";
import { Button } from "./button";
import { CancelDialog } from "./modals/cancel-dialog";

export const MODAL_HEADER_TEST_ID = "modal-header";

type ModalProps<T = unknown> = PropsWithChildren<
  T & {
    header?: string;
    showHeader?: boolean;
    hasCancelDialog?: boolean;
    sideImgUrl?: string;
    onClose?: () => void;
    cancelConfirmation?: string;
  }
>;

export function Modal<T extends object>({
  header = "",
  showHeader = true,
  hasCancelDialog = false,
  sideImgUrl = "",
  onClose = () => {},
  cancelConfirmation = "close-modal-dialog-confirm",
  children,
  ...props
}: ModalProps<T>) {
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  return (
    // @ts-expect-error props.className might be used
    <RSModal {...props} className={`${styles["modal"]} scroll-behavior-smooth ${props.className ?? ""}`} scrollable>
      <ModalBody>
        <div
          className={`flex-column justify-content-center h-100 w-100 z-3 p-0 m-n4`}
          style={{
            display: showCancelDialog ? "flex" : "none",
            position: showCancelDialog ? "absolute" : "inherit",
          }}
        >
          <CancelDialog
            cancelConfirmation={cancelConfirmation}
            onConfirm={() => {
              onClose();
              setShowCancelDialog(false);
            }}
            onDeny={() => {
              setShowCancelDialog(false);
            }}
          />
        </div>
        <div
          style={{
            filter: showCancelDialog ? "blur(12px) opacity(30%)" : "",
          }}
          data-testid="modal"
        >
          <div className="d-flex">
            {sideImgUrl && (
              <div>
                <img
                  alt="modal illustration"
                  src={sideImgUrl}
                  style={{
                    width: 190,
                    objectFit: "cover",
                    height: "calc(100% + 2rem)",
                    margin: "-1rem 0 -1rem -1rem",
                    borderRadius: "0.4rem 0 0 0.4rem",
                  }}
                />
              </div>
            )}
            <div className="px-4 flex-grow-1">
              <div className="d-flex justify-content-between  border-bottom">
                <div className="lead fw-light mb-0 align-content-center" data-testid={MODAL_HEADER_TEST_ID}>
                  {showHeader && header}
                </div>

                <Button
                  name="close-modal"
                  data-testid="close-modal"
                  className="px-0"
                  onClick={() => {
                    if (hasCancelDialog) {
                      setShowCancelDialog(true);
                    } else {
                      onClose();
                    }
                  }}
                  Icon={Remove32}
                  level="quartenary"
                  theme="darker"
                />
              </div>
              <div className="mt-4">{children}</div>
            </div>
          </div>
        </div>
      </ModalBody>
    </RSModal>
  );
}
