import { AlertBell24 } from "@bphxd/ds-core-react/lib/icons";
import { Button } from "./button";

type NotificationButtonProps = {
  onDisplayNotificationClick: () => void;
  disabled: boolean;
};
export function NotificationButton({ onDisplayNotificationClick, disabled }: NotificationButtonProps) {
  return (
    <Button
      aria-label="display-notification"
      name="showLeftSideNotification"
      className="mx-4 btn-square rounded-circle bg-quartenary"
      data-testid="leftSideNavigation-notification-button"
      onClick={onDisplayNotificationClick}
      disabled={disabled}
      Icon={AlertBell24}
      iconOnly
      level="tertiary"
      theme="standard"
      outline
    />
  );
}
