"use client";

import type { PropsWithChildren } from "react";
import { useUser } from "../hooks/use-user";
import { FeatureHub } from "featurehub-react-sdk";
import { env } from "next-runtime-env";

export const FeatureHubWrapper = ({ children }: PropsWithChildren) => {
  const user = useUser();
  return (
    <FeatureHub
      url={env("NEXT_PUBLIC_FEATUREHUB_EDGE_URL") || "https://edge.featurehub.bpglobal.com"}
      userKey={user?.email ?? ""}
      apiKey={env("NEXT_PUBLIC_FEATUREHUB_API_KEY") || ""}
      pollInterval={10000}
    >
      {children}
    </FeatureHub>
  );
};
