import styles from "./opaque-screen.module.css";

export function OpaqueScreen({ searchIsActive = false }) {
  return (
    <div
      className={`${styles["container"]} d-flex w-100 h-100 top-0 left-0 position-fixed border-0 z-1 ${
        styles[searchIsActive ? "fadeIn" : "fadeOut"]
      }`}
      data-testid={searchIsActive ? "opaque-screen" : ""}
    ></div>
  );
}
