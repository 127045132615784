"use client";
import { useContext, useEffect, useMemo, useState } from "react";
import { useGetUserByEmail } from "./use-get-user-by-email";
import { AuthContext } from "../providers/auth-provider";
import { GetUserByEmailQuery } from "../gql/graphql";

export type UserProfileData = GetUserByEmailQuery["getUserByEmail"] & {
  isModerator?: boolean;
  isContentAdmin?: boolean;
};

export function useUser() {
  const { isAuthenticated, isModerator, isContentAdmin, email } = useContext(AuthContext);

  const [userProfile, setUserProfile] = useState<UserProfileData>();

  const { data: getUserByEmailData } = useGetUserByEmail(email ?? "");

  useEffect(() => {
    if (isAuthenticated && getUserByEmailData) {
      setUserProfile({
        ...getUserByEmailData?.getUserByEmail,
        isModerator,
        isContentAdmin,
      });
    }
  }, [isAuthenticated, getUserByEmailData]);
  return useMemo(() => userProfile, [userProfile]);
}
