import type { PropsWithChildren } from "react";
import { Toast, ToastHeader } from "reactstrap";

export function SnackBar({ children }: PropsWithChildren) {
  return (
    <div data-testid="snack-bar" className="d-flex justify-content-evenly">
      <Toast
        className="shadow-sm position-fixed w-auto text-center rounded-2 z-3"
        style={{
          bottom: "40px",
        }}
      >
        <ToastHeader tag="span" data-testid="toast-message">
          {children}
        </ToastHeader>
      </Toast>
    </div>
  );
}
