import { LinkBadge } from "../link-badge";
import { TESTID } from "../../views/khub/enums";

export function TagBar({ contentTags }: { contentTags: string[] }) {
  return contentTags?.length > 0 ? (
    <div data-testid={TESTID.tagBar} className="d-flex gap-2 pt-4 pb-6 flex-wrap">
      {contentTags.map((tag) => (
        <LinkBadge key={tag} href={`/axel?s=${tag}`} name={`kh-tag-${tag}`}>
          {tag}
        </LinkBadge>
      ))}
    </div>
  ) : null;
}
