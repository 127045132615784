import { CUSTOM_ADMIN_NOTIFICATION } from "./notification-enums";
import formatTimestampToRelativeDate from "./formatTimestampToRelativeDate";

export const COMPONENT_APPROVED_EVENT_ENUM = "COMPONENT_APPROVED";
const USER_ADDED_TO_TEAM = "USER_ADDED_TO_TEAM";
const USER_REMOVED_FROM_TEAM = "USER_REMOVED_FROM_TEAM";
const KNOWLEDGEHUB_DOCUMENTS_ISSUE_CREATED = "KNOWLEDGEHUB_DOCUMENTS_ISSUE_CREATED";
const KNOWLEDGEHUB_DOCUMENTS_CONTENT_PUBLISHED = "KNOWLEDGEHUB_DOCUMENTS_CONTENT_PUBLISHED";
const KNOWLEDGEHUB_GAP_ISSUE_CREATED = "KNOWLEDGEHUB_GAP_ISSUE_CREATED";
const KNOWLEDGEHUB_GENERATED_DOCUMENTS_CREATED = "KNOWLEDGEHUB_GENERATED_DOCUMENTS_CREATED";
const SUCCESSFUL_SUPPORT_GROUP_ONBOARDING = "SUPPORT_GROUP_ONBOARDED_SUCCESSFULLY";
const PRODUCT_NOT_LINKED_TO_L3 = "PRODUCT_NOT_LINKED_TO_L3";
const PRODUCT_NOT_LINKED_TO_REPO = "PRODUCT_NOT_LINKED_TO_REPO";
const REPOS_HAVE_NO_PROD_STAGE = "REPOS_HAVE_NO_PROD_STAGE";
const ADDED_TO_PRODUCT = "ADDED_TO_PRODUCT";
const REMOVED_FROM_PRODUCT = "REMOVED_FROM_PRODUCT";

export type NotificationType = {
  id: string;
  type: string;
  data: any;
  timestamp: string;
  isRead: boolean;
} & Record<string, unknown>;

export function MapEventToNotification({ id, type, data, isRead, timestamp, ...rest }: NotificationType) {
  const relativeDate = formatTimestampToRelativeDate(timestamp);
  const defaultMappedNotification = {
    ...rest,
    relativeDate,
    title: type,
    id,
    isRead,
  };
  switch (type) {
    case COMPONENT_APPROVED_EVENT_ENUM:
      /*
      TODO: Change url once component type passed in notification
      TODO: Fix bug where components can be accessed via any (or the wrong) component type
        e.g. /marketplace/components/type/notarealcomponenttype/My Component
      */
      return {
        ...defaultMappedNotification,
        title: `"${data.component_name}" has been published to the code library`,
        url: `/marketplace/components/type/component/${encodeURIComponent(data.component_name)}`,
      };

    case KNOWLEDGEHUB_DOCUMENTS_ISSUE_CREATED:
      return {
        ...defaultMappedNotification,
        title: `New KnowledgeHub issue has been reported for "${data.repository}/${data.path}"`,
        url: data.ado_support_ticket_url,
      };

    case KNOWLEDGEHUB_GAP_ISSUE_CREATED:
      return {
        ...defaultMappedNotification,
        title: `Axel has identified a potential content gap in your content area: "${data.repository}"`,
        url: data.ado_support_ticket_url,
      };

    case KNOWLEDGEHUB_DOCUMENTS_CONTENT_PUBLISHED:
      return {
        ...defaultMappedNotification,
        title: data.message,
        url: data.url,
      };
    case KNOWLEDGEHUB_GENERATED_DOCUMENTS_CREATED:
      return {
        ...defaultMappedNotification,
        title: `Axel has generated documentation for "${data.application}/${data.repository}"`,
        url: data.url,
      };

    case USER_ADDED_TO_TEAM:
      return {
        ...defaultMappedNotification,
        title: `Great news! You've been added to the "${data.teamName}" team. Check it out in your profile.`,
        url: "/profile",
      };
    case USER_REMOVED_FROM_TEAM:
      return {
        ...defaultMappedNotification,
        title: `You have been removed from "${data.teamName}" team. If this is unexpected, reach out to the Team Owner or the Support Team.`,
        url: "/profile",
      };

    case SUCCESSFUL_SUPPORT_GROUP_ONBOARDING:
      return {
        ...defaultMappedNotification,
        title: `Support Group "${data.name}" has successfully been onboarded to Grafana. View the alerts for your Support Group.`,
        url: data.alertGroupsLink,
      };

    case PRODUCT_NOT_LINKED_TO_L3:
      return {
        ...defaultMappedNotification,
        title: `Your product "${data.productName}" is still not linked to Service Now. Click here to link it.`,
        url: `/profile/product/${data.productId}`,
      };

    case PRODUCT_NOT_LINKED_TO_REPO:
      return {
        ...defaultMappedNotification,
        title: `Your active repos are still not linked to the product "${data.productName}". Click here to link them.`,
        url: `/profile/product/${data.productId}`,
      };

    case REPOS_HAVE_NO_PROD_STAGE:
      return {
        ...defaultMappedNotification,
        title: `The following repositories that are linked to the product "${
          data.productName
        }" include CD pipelines that do not include a "Prod" stage. 
        This means that you will not see empirical Lead Time and Deployment data to help you improve development velocity. ${data.repositoryNames.join(
          ", ",
        )} Click here to learn how to fix this issue. `,
        url: "/knowledgehub/5mA8xV",
      };

    case CUSTOM_ADMIN_NOTIFICATION:
      return {
        ...defaultMappedNotification,
        title: data.text,
        url: data.url,
      };

    case ADDED_TO_PRODUCT:
      return {
        ...defaultMappedNotification,
        title: `You have been added to the ${data.productName} product team. You can now explore this Product from your 'My Products' page."`,
        url: "/my-products",
      };

    case REMOVED_FROM_PRODUCT:
      return {
        ...defaultMappedNotification,
        title: `${data.removingUser} has removed you from the ${data.productName} product team. If this is an error, please search for the Product in 'All Products' and join it again.`,
        url: "/all-products",
      };

    default:
      return defaultMappedNotification;
  }
}
