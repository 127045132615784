"use client";
import { env } from "next-runtime-env";

export const EMPLOYEE_GROUP_ID = env("NEXT_PUBLIC_EMPLOYEE_GROUP_ID") ?? "0aeadfd7-7f8c-43d7-8ccd-2c688311e69b";

export const FAQ_MODERATOR_GROUP_ID =
  env("NEXT_PUBLIC_FAQ_MODERATOR_GROUP_ID") ?? "be250bf3-efa2-4894-9baa-70884fdf3207";

export const MODERATOR_GROUP_ID = env("NEXT_PUBLIC_MODERATOR_GROUP_ID") ?? "2b0d4fcc-91ea-4116-b567-f2189526c64f";

export const ADMIN_GROUP_ID = env("NEXT_PUBLIC_ADMIN_GROUP_ID") ?? "4d452a2c-5532-4669-a264-4f1738d0f839";
