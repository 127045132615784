import type { PropsWithChildren, ReactHTMLElement } from "react";

export function IconWrapper(
  props: PropsWithChildren<ReactHTMLElement<HTMLDivElement> & { className?: string; isOpen: boolean }>,
) {
  return (
    <div
      {...props}
      className={"fs-0 h-24px w-24px " + props.className}
      style={{ transition: "transform linear 0.1s", transform: props.isOpen ? "rotate(90deg)" : "rotate(0deg)" }}
    >
      {props.children}
    </div>
  );
}
