import { MapEventToNotification } from "../utils/notification-mapper";

import { DropdownItem } from "reactstrap";
import { Notification } from "./notification";
import { LinkIf } from "./link-if";

export function NotificationList({
  notifications = [],
  handleNotificationClick,
}: {
  notifications: any[];
  handleNotificationClick: (id: string) => void;
}) {
  function handleClick(id: string) {
    return () => handleNotificationClick(id);
  }

  return notifications?.length ? (
    <>
      {notifications.map(MapEventToNotification).map((notification, index) => {
        const { id, url }: { id: string; url?: string } = notification;
        return (
          <DropdownItem key={id || index} className="p-0 mb-3 rounded-3">
            <LinkIf
              href={url}
              className="notification-message fs-base d-flex align-items-center justify-content-between w-100 pt-3 x5-px-2 border border-2 rounded-3 border-primary bg-white opacity-80 opacity-100-hover"
              style={{
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                },
              }}
              onClick={handleClick(id)}
            >
              <Notification {...notification} index={index} />
            </LinkIf>
          </DropdownItem>
        );
      })}
    </>
  ) : (
    <div
      data-testid="no-notification-message"
      className="no-messages d-flex justify-content-center m-auto position-relative fs-base pe-none"
      style={{ bottom: "2rem" }}
    >
      No notifications yet
    </div>
  );
}
