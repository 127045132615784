/* istanbul ignore file */
"use client";

import { useState, FC, MouseEvent } from "react";
import { NodeModel } from "@minoru/react-dnd-treeview";
import { RightSmall32 } from "@bphxd/ds-core-react/lib/icons";
import { Dropdown, NavItem, NavItemProps } from "reactstrap";
import { KebabMenu } from "../../components/buttons/kebab-menu";
import { DndTreeData } from "./dnd-tree-types";
import { TESTID } from "./enums";
import { uuidToHref } from "./page-footer";
import { Link } from "../../components/Link";
import { IconWrapper } from "./icon-wrapper";

type Props = {
  node: DndTreeData;
  depth: number;
  isOpen: boolean;
  active: boolean;
  onToggle: (id: NodeModel["id"]) => void;
  hasChild: boolean;
  draggable: boolean;
};

const calcRootStyle = ({ depth }: NavItemProps & Pick<Props, "depth" | "active">) => ({
  paddingLeft: depth * 15,
});

export const NavTreeItem: FC<Props> = (props) => {
  const { isOpen, active, depth, node, hasChild } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleToggle = (e: MouseEvent) => {
    e.stopPropagation();
    props.onToggle(props.node.id);
  };

  return (
    <NavItem
      className={`d-flex align-items-center text-dark khub-navtree${active ? " bg-tertiary" : ""}`}
      style={calcRootStyle(props)}
      tag="div"
      data-testid={active ? TESTID.activeNavItem : undefined}
      depth={depth}
      active={active}
    >
      {depth !== 0 && (
        <IconWrapper
          // @ts-ignore
          role="button"
          onClick={handleToggle}
          isOpen={isOpen}
          className={"d-flex align-items-center justify-content-center"}
        >
          <div>
            {hasChild ? <RightSmall32 className="text-secondary" /> : <div style={{ width: 32, height: 32 }}>{""}</div>}
          </div>
        </IconWrapper>
      )}
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        className="d-flex justify-content-between align-items-center"
        style={{ width: "inherit" }}
      >
        <Link
          className="w-100 nav-link"
          style={{
            paddingInlineStart: 8,
          }}
          href={uuidToHref(node.id as string)}
        >
          {node.text}
        </Link>
        <KebabMenu path={node.data?.path ?? ""} uuid={node.id as string} isOpen={dropdownOpen} active={active} />
      </Dropdown>
    </NavItem>
  );
};
