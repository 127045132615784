"use client";
import { useState, useEffect } from "react";

import { useUser } from "../hooks/use-user";
import { useWebsocket } from "../hooks/use-websocket";
import {
  NEW_NOTIFICATION_ENUM,
  NOTIFICATION_LIST_ENUM,
  REQUEST_NOTIFICATION_LIST_ENUM,
  UPDATE_NOTIFICATION_READ_STATUS_ENUM,
  UPDATE_NOTIFICATION_TRAY_READ_STATUS_ENUM,
} from "../utils/notification-enums";

import { Bell } from "react-feather";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { NotificationList } from "./notification-list";
import { trackEvent } from "../utils/event-tracker";

export function NotificationTray() {
  const user = useUser();
  const websocket = useWebsocket();
  const [trayIsOpen, setTrayIsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [newNotifications, setNewNotifications] = useState(false);

  function toggleOpen() {
    setTrayIsOpen((prev) => !prev);
  }

  function requestNotifications() {
    websocket?.emit(REQUEST_NOTIFICATION_LIST_ENUM);
  }

  function parseNotificationList({
    newNotifications,
    notifications,
  }: {
    newNotifications: boolean;
    notifications: [];
  }) {
    setNewNotifications(newNotifications);
    setNotifications(notifications);
  }

  function handleNotificationClick(notificationId: string) {
    websocket?.emit(UPDATE_NOTIFICATION_READ_STATUS_ENUM, notificationId);
    websocket?.emit(UPDATE_NOTIFICATION_TRAY_READ_STATUS_ENUM);
  }

  useEffect(() => {
    if (websocket && !!user?.email) {
      requestNotifications();
      websocket?.on(NEW_NOTIFICATION_ENUM, requestNotifications);
      websocket?.on(NOTIFICATION_LIST_ENUM, parseNotificationList);

      return () => {
        websocket?.off(NEW_NOTIFICATION_ENUM, requestNotifications);
        websocket?.off(NOTIFICATION_LIST_ENUM, parseNotificationList);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, websocket]);

  const trayClasses = [
    "d-flex p-0 align-items-center justify-content-center rounded-circle cursor-pointer bg-info-subtle text-default",
  ];
  if (trayIsOpen) {
    trayClasses.push("border-dark-gray-600 border");
  }
  if (!newNotifications) {
    trayClasses.push("observed-notifications bg-tertiary");
  } else {
    trayClasses.push(
      "pending-notifications position-relative " + (trayIsOpen ? "bg-light-gray-200" : "bg-info-subtle"),
    );
  }

  return (
    <div className="my-0 x5-mx-2 rounded-circle">
      <Dropdown tag="div" toggle={toggleOpen} isOpen={trayIsOpen} className="h-100">
        <DropdownToggle
          color="standard-quartenary"
          className={"notification-toggle p-0 opacity-100-hover h-100 " + (trayIsOpen ? "opacity-100" : "opacity-70")}
          aria-expanded={trayIsOpen}
          onClick={() => {
            trackEvent({
              name: `button_notification-toggle-navbar-top_click`,
            });
          }}
        >
          <div
            style={{
              width: 42,
              height: 42,
            }}
            className={trayClasses.join(" ")}
          >
            <Bell size={16} />
            {!trayIsOpen && newNotifications && (
              <div
                className="unread-count position-absolute w-100 h-100 border border-2 border-white rounded-circle bg-info text-white p-1 fw-bold float-end"
                style={{
                  right: 2,
                  bottom: 0,
                  maxHeight: 10,
                  maxWidth: 10,
                  fontSize: 9,
                }}
              />
            )}
          </div>
        </DropdownToggle>
        <DropdownMenu
          end
          className="mb-0 mt-2 p-0 rounded-3 border-bottom border-start border-end bg-light-gray-200 border-op-light-gray-50"
        >
          <div className="notification-tray px-4 py-5" style={{ width: 350, height: 360 }}>
            <div className="notification-tray__heading lh-1-75 fw-normal fst-normal text-default fs-base">
              Notifications
            </div>
            <div className="notification-tray__list d-flex flex-column overflow-scroll mt-3" style={{ height: 295 }}>
              <NotificationList notifications={notifications} handleNotificationClick={handleNotificationClick} />
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}
