"use client";
import { useState, useEffect } from "react";
import { useUser } from "../hooks/use-user";
import { useWebsocket } from "../hooks/use-websocket";
import {
  NEW_NOTIFICATION_ENUM,
  NOTIFICATION_LIST_ENUM,
  REQUEST_NOTIFICATION_LIST_ENUM,
  UPDATE_NOTIFICATION_READ_STATUS_ENUM,
  UPDATE_NOTIFICATION_TRAY_READ_STATUS_ENUM,
} from "../utils/notification-enums";
import { SideNotificationList } from "./side-notification-list";

export function SideNotificationTray() {
  const user = useUser();
  const websocket = useWebsocket();
  const [notifications, setNotifications] = useState([]);

  function requestNotifications() {
    websocket?.emit(REQUEST_NOTIFICATION_LIST_ENUM);
  }

  function parseNotificationList({ notifications }: { notifications: [] }) {
    setNotifications(notifications);
  }

  function handleNotificationClick(notificationId: string) {
    websocket?.emit(UPDATE_NOTIFICATION_READ_STATUS_ENUM, notificationId);
    websocket?.emit(UPDATE_NOTIFICATION_TRAY_READ_STATUS_ENUM);
  }

  useEffect(() => {
    if (websocket && !!user?.email) {
      requestNotifications();
      websocket?.on(NEW_NOTIFICATION_ENUM, requestNotifications);
      websocket?.on(NOTIFICATION_LIST_ENUM, parseNotificationList);

      return () => {
        websocket?.off(NEW_NOTIFICATION_ENUM, requestNotifications);
        websocket?.off(NOTIFICATION_LIST_ENUM, parseNotificationList);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, websocket]);

  return (
    <div className="py-0 x5-mx-2">
      <div className="notification-tray py-5 px-4 w-100 h-100">
        <div className="notification-tray__list mt-3 d-flex flex-column h-100 overflow-scroll">
          <SideNotificationList notifications={notifications} handleNotificationClick={handleNotificationClick} />
        </div>
      </div>
    </div>
  );
}
