import { Hero } from "../../components/hero";
import Page from "../../components/page-template";

export default function ExceptionPage({
  setIsHelpAndSupportModalOpen,
  title = "Welcome to bp Accelerate!",
  heading = "We've noticed you have encountered an error...",
  body = "Please contact the bp Accelerate team if this problem persists.",
  ctaText = "Get help",
}: {
  setIsHelpAndSupportModalOpen: (val: boolean) => void;
  title?: string;
  heading?: string;
  body?: string;
  ctaText?: string;
}) {
  return (
    <Page name="error" title="Error">
      <div className="pb-5 border-bottom border-light">
        <h1
          className="pt-4 mb-5 fw-light fst-normal lh-1-5 text-default fs-3 ls-0-3"
          data-testid="dashboard-greeting"
          style={{ height: 39 }}
        >
          {title}
        </h1>
      </div>
      <Hero
        // @ts-ignore: Hero needs typing
        level={1}
        heading={heading}
        body={body}
        imageUrl="/images/welcome_alt.png"
        ctaText={ctaText}
        ctaOnClick={() => setIsHelpAndSupportModalOpen(true)}
      />
    </Page>
  );
}

ExceptionPage.defaultProps = {
  setIsHelpAndSupportModalOpen: () => {},
};
