import type { PropsWithChildren } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { MsalAuthProvider } from "./msal-auth-provider";
import { FeatureHubWrapper } from "./feature-hub-wrapper";
import { ToastProvider } from "./toast-provider";
import { DndProvider } from "react-dnd-multi-backend";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import { msalInstance } from "../utils/helpers/msal-helper";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const AppWrapper: React.FC<PropsWithChildren> = (props) => (
  <QueryClientProvider client={queryClient} contextSharing={true}>
    <MsalAuthProvider instance={msalInstance}>
      <DndProvider options={HTML5toTouch}>
        <FeatureHubWrapper>
          <ToastProvider>{props.children}</ToastProvider>
        </FeatureHubWrapper>
      </DndProvider>
    </MsalAuthProvider>
  </QueryClientProvider>
);
