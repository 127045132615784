/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Date with time (isoformat) */
  DateTime: { input: any; output: any };
  QuizCategory: { input: any; output: any };
  QuizSlug: { input: any; output: any };
  QuizType: { input: any; output: any };
  Score: { input: any; output: any };
  YallaVersion: { input: any; output: any };
  join__DirectiveArguments: { input: any; output: any };
  join__FieldSet: { input: any; output: any };
  join__FieldValue: { input: any; output: any };
  link__Import: { input: any; output: any };
  policy__Policy: { input: any; output: any };
};

/** New answer data */
export type AddAnswerInput = {
  body: Scalars["String"]["input"];
  questionId: Scalars["String"]["input"];
};

/** New comment data */
export type AddCommentInput = {
  body: Scalars["String"]["input"];
  questionId: Scalars["String"]["input"];
};

/** New question data */
export type AddQuestionInput = {
  body: Scalars["String"]["input"];
  tags: Array<Scalars["String"]["input"]>;
  title: Scalars["String"]["input"];
};

export type Alert = {
  __typename?: "Alert";
  alertStatus: AlertStatusType;
  dateRaised: Scalars["Float"]["output"];
  dateResolved?: Maybe<Scalars["Float"]["output"]>;
  dateUpdated: Scalars["Float"]["output"];
  description: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  raisedBy?: Maybe<User>;
  resolutionNote?: Maybe<Scalars["String"]["output"]>;
  resolvedBy?: Maybe<User>;
  supportGroup?: Maybe<SupportGroup>;
};

export type AlertCreateArgs = {
  /** Description of the Alert */
  description: Scalars["String"]["input"];
  /** Id of the Ops Group */
  opsGroupId: Scalars["String"]["input"];
};

/** Status Type of the Alert, must be one of { PENDING, INPROGRESS, RESOLVED } */
export enum AlertStatusType {
  Inprogress = "INPROGRESS",
  Pending = "PENDING",
  Resolved = "RESOLVED",
}

export type AllTagTypesResponseType = {
  __typename?: "AllTagTypesResponseType";
  id: Scalars["String"]["output"];
  tagType: Scalars["String"]["output"];
  updatedDate: Scalars["String"]["output"];
};

export type Answer = {
  __typename?: "Answer";
  option?: Maybe<Option>;
  option_id: Scalars["Float"]["output"];
  question?: Maybe<Question>;
  question_id: Scalars["Float"]["output"];
};

export type AnswerInput = {
  option_id: Scalars["Float"]["input"];
  question_id: Scalars["Float"]["input"];
};

export type AnswerWithQuestionId = {
  __typename?: "AnswerWithQuestionId";
  author: Scalars["String"]["output"];
  body: Scalars["String"]["output"];
  createdAt: Scalars["String"]["output"];
  deletedAt?: Maybe<Scalars["String"]["output"]>;
  helpfulVotes: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  question?: Maybe<Scalars["String"]["output"]>;
  unhelpfulVotes: Scalars["Int"]["output"];
  updatedAt?: Maybe<Scalars["String"]["output"]>;
  updatedBy?: Maybe<Scalars["String"]["output"]>;
  userVotedHelpful: Scalars["Boolean"]["output"];
  userVotedUnhelpful: Scalars["Boolean"]["output"];
};

/** Approves a component to be of public usage */
export type ApproveComponent = {
  component_id: Scalars["Float"]["input"];
};

export type ApproveRevokeSurveyInput = {
  approved: Scalars["Boolean"]["input"];
  surveyId: Scalars["String"]["input"];
};

export type AzureDevOpsPipeline = {
  __typename?: "AzureDevOpsPipeline";
  /** The ID of the pipeline. */
  id: Scalars["String"]["output"];
  /** The name of the pipeline. */
  name: Scalars["String"]["output"];
  /** The name of the organization under which the pipeline sits. */
  organizationName: Scalars["String"]["output"];
  /** The name of the project under which the project sits. */
  projectName: Scalars["String"]["output"];
};

export type AzureDevOpsRepository = {
  __typename?: "AzureDevOpsRepository";
  /** The ID of the repository. */
  id: Scalars["String"]["output"];
  /** The name of the repository. */
  name: Scalars["String"]["output"];
  /** The name of the organization under which the project sits. */
  organizationName: Scalars["String"]["output"];
  /** The name of the project under which the repository sits. */
  projectName: Scalars["String"]["output"];
};

export enum CacheControlScope {
  Private = "PRIVATE",
  Public = "PUBLIC",
}

export type CapabilityResult = {
  __typename?: "CapabilityResult";
  groupedByCategory: Array<CategoryResult>;
  lastUpdated?: Maybe<Scalars["Float"]["output"]>;
  productId: Scalars["String"]["output"];
  quizResults: Array<QuizResult>;
  score?: Maybe<Scalars["Float"]["output"]>;
};

export type CategoryResult = {
  __typename?: "CategoryResult";
  categoryName: Scalars["String"]["output"];
  lastUpdated?: Maybe<Scalars["Float"]["output"]>;
  quizResults: Array<QuizResult>;
  score?: Maybe<Scalars["Float"]["output"]>;
};

export type Chat = {
  __typename?: "Chat";
  chatId: Scalars["String"]["output"];
  lastUpdate?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
};

export type ChatHistory = {
  __typename?: "ChatHistory";
  chatId: Scalars["String"]["output"];
  history: Array<ChatResponse>;
};

export type ChatMessage = {
  chatId?: InputMaybe<Scalars["String"]["input"]>;
  filters?: InputMaybe<Array<KendraFilter>>;
  includeRetiredContent?: Scalars["Boolean"]["input"];
  replace?: Scalars["Boolean"]["input"];
  request: Scalars["String"]["input"];
};

export type ChatResponse = {
  __typename?: "ChatResponse";
  answer?: Maybe<Scalars["String"]["output"]>;
  auditId?: Maybe<Scalars["String"]["output"]>;
  blockedResponse: Scalars["Boolean"]["output"];
  chatId?: Maybe<Scalars["String"]["output"]>;
  chatName?: Maybe<Scalars["String"]["output"]>;
  contextRelevance?: Maybe<Scalars["Float"]["output"]>;
  kendraSearch?: Maybe<Scalars["String"]["output"]>;
  keywordSearchData?: Maybe<KeywordSearch>;
  probablePublicData: Scalars["Boolean"]["output"];
  request?: Maybe<Scalars["String"]["output"]>;
  sourceDocs: Array<SourceDoc>;
  tokenBasedAnswerRelevance?: Maybe<Scalars["Float"]["output"]>;
  tokenBasedGroundedness?: Maybe<Scalars["Float"]["output"]>;
};

export type CloudSpending = {
  __typename?: "CloudSpending";
  /** The name as shown in Cloudability */
  cloudabilityName: Scalars["String"]["output"];
  /** The Cloudability ViewId */
  cloudabilityViewId: Scalars["Float"]["output"];
  /** How much the product has spent in the current month */
  currentMonth?: Maybe<Scalars["Float"]["output"]>;
  /** How much the product is estimated to spend in the current month */
  currentMonthEstimate?: Maybe<Scalars["Float"]["output"]>;
  /** How much the product spent in the previous month */
  previousMonth?: Maybe<Scalars["Float"]["output"]>;
};

export type CloudabilityProduct = {
  __typename?: "CloudabilityProduct";
  /** The name as seen by Cloudability */
  name: Scalars["String"]["output"];
  /** The Cloudability ViewId */
  viewId: Scalars["Float"]["output"];
};

export type CommentWithQuestionId = {
  __typename?: "CommentWithQuestionId";
  author: Scalars["String"]["output"];
  body: Scalars["String"]["output"];
  createdAt: Scalars["String"]["output"];
  deletedAt?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  question?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["String"]["output"]>;
  updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type CompleteSurveyInput = {
  hasCompletedSurvey: Scalars["Boolean"]["input"];
  surveyId: Scalars["String"]["input"];
};

export type ComponentResponse = {
  __typename?: "ComponentResponse";
  authorEmail?: Maybe<Scalars["String"]["output"]>;
  componentType: Scalars["String"]["output"];
  createdAt?: Maybe<Scalars["String"]["output"]>;
  description: Scalars["String"]["output"];
  id: Scalars["Float"]["output"];
  name: Scalars["String"]["output"];
  readmeUrl?: Maybe<Scalars["String"]["output"]>;
  repoUrl: Scalars["String"]["output"];
  status?: Maybe<Scalars["String"]["output"]>;
  tags?: Maybe<Array<Scalars["String"]["output"]>>;
  updatedAt?: Maybe<Scalars["String"]["output"]>;
  userParameters?: Maybe<Array<SsaArguments>>;
};

export type ContactMethod = {
  __typename?: "ContactMethod";
  type: ContactMethodType;
  /** The value of the contact method. (e.g. phone number, email address, URL). */
  value: Scalars["String"]["output"];
};

export type ContactMethodArgs = {
  type: ContactMethodType;
  value: Scalars["String"]["input"];
};

/** The type of contact method. */
export enum ContactMethodType {
  Dm = "DM",
  Email = "EMAIL",
  Slack = "SLACK",
  Teams = "TEAMS",
}

/** Create or Update a Self Service Automation */
export type CsCommand = {
  description: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  readmeUrl?: InputMaybe<Scalars["String"]["input"]>;
  repoUrl: Scalars["String"]["input"];
  tags: Array<Scalars["String"]["input"]>;
};

export type CsResponse = {
  __typename?: "CsResponse";
  authorEmail?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["String"]["output"]>;
  description: Scalars["String"]["output"];
  id: Scalars["Float"]["output"];
  name: Scalars["String"]["output"];
  readmeUrl?: Maybe<Scalars["String"]["output"]>;
  repoUrl: Scalars["String"]["output"];
  status?: Maybe<Scalars["String"]["output"]>;
  tags?: Maybe<Array<Scalars["String"]["output"]>>;
  updatedAt?: Maybe<Scalars["String"]["output"]>;
};

export type CyberScore = {
  __typename?: "CyberScore";
  /** Last week CyberScore */
  lastWeekScore: Scalars["Float"]["output"];
  /** Rank by CyberScore */
  rank: Scalars["Float"]["output"];
  /** CyberScore */
  score: Scalars["Float"]["output"];
  /** Trend of the CyberScore */
  trend: Scalars["String"]["output"];
};

export type DoraMetrics = {
  __typename?: "DORAMetrics";
  /** The deployment frequency metrics for the repositories. */
  deploymentFrequency?: Maybe<DeploymentFrequencyModel>;
  /** The historic month-by-month deployment frequency metrics component. */
  historicDeploymentFrequencies?: Maybe<Array<DeploymentFrequencyModel>>;
  /** The historic month-by-month lead time metrics component. */
  historicLeadTimes?: Maybe<Array<LeadTime>>;
  /** The lead time metrics for the repositories. */
  leadTime?: Maybe<LeadTime>;
  /** The IDs of the repositories for which the metrics are calculated. */
  repositoryIds: Array<Scalars["String"]["output"]>;
};

export type DeleteAlert = {
  __typename?: "DeleteAlert";
  isDeleted: Scalars["Boolean"]["output"];
};

/** Deletes a component */
export type DeleteComponent = {
  component_id: Scalars["Float"]["input"];
};

export type DeleteLinkedResourceResponse = {
  __typename?: "DeleteLinkedResourceResponse";
  id: Scalars["String"]["output"];
  isDeleted: Scalars["Boolean"]["output"];
};

export type DeleteProductResponse = {
  __typename?: "DeleteProductResponse";
  isDeleted?: Maybe<Scalars["Boolean"]["output"]>;
};

export type DeletionResult = {
  __typename?: "DeletionResult";
  isDeleted?: Maybe<Scalars["Boolean"]["output"]>;
};

export type DeploymentFrequencyModel = {
  __typename?: "DeploymentFrequencyModel";
  /** The date the metrics were updated. */
  lastUpdatedDate: Scalars["Float"]["output"];
  /** The per-pipeline breakdown of the metrics. */
  perPipeline?: Maybe<Array<PipelineDeploymentFrequency>>;
  /** The per-repository breakdown of the metrics. */
  perRepository?: Maybe<Array<RepositoryDeployFrequency>>;
  /** The total number of deployments */
  periodDeploymentCount: Scalars["Float"]["output"];
  /** The end date of the period for which the metrics are calculated. */
  periodEndDate: Scalars["Float"]["output"];
  /** The start date of the period for which the metrics are calculated. */
  periodStartDate: Scalars["Float"]["output"];
  /** The IDs of the repositories for which the metrics are calculated. */
  repositoryIds: Array<Scalars["String"]["output"]>;
  /** Target value for metric at this given level. (Default is bp's target of 5 per week). */
  targetValue?: Maybe<Scalars["Float"]["output"]>;
  /** The total number of deployments */
  trendingPeriodDeploymentCount: Scalars["Float"]["output"];
  /** The start date of the trending (shorter) period for which the metrics are calculated. */
  trendingPeriodStartDate: Scalars["Float"]["output"];
};

export type EditProductRepositoriesInput = {
  /** ID of Repository */
  id: Scalars["String"]["input"];
  /** Name of the organisation that the repository belongs to */
  organizationName: Scalars["String"]["input"];
  /** The name of the Project that the repository belongs to */
  projectName: Scalars["String"]["input"];
};

export type GetAdoProjectResponse = {
  __typename?: "GetADOProjectResponse";
  description: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  lastUpdateTime: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  revision: Scalars["Float"]["output"];
  state: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
  visibility: Scalars["String"]["output"];
};

export type GetAdoRepoResponse = {
  __typename?: "GetADORepoResponse";
  defaultBranch?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  isDisabled: Scalars["Boolean"]["output"];
  isInMaintenance: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  project: GetAdoProjectResponse;
  remoteUrl: Scalars["String"]["output"];
  size: Scalars["Float"]["output"];
  sshUrl: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
  webUrl: Scalars["String"]["output"];
};

export type GetRepoInfoResponse = {
  __typename?: "GetRepoInfoResponse";
  /** Name of the Organisation the Repository belongs to */
  organisationName: Scalars["String"]["output"];
  /** ID of the Project the Repository belongs to */
  projectId: Scalars["String"]["output"];
  /** Name of the Project the Repository belongs to */
  projectName: Scalars["String"]["output"];
  /** ID of the Repository */
  repositoryId: Scalars["String"]["output"];
  /** Name of the Repository */
  repositoryName: Scalars["String"]["output"];
};

export type HasSurveyResponse = {
  __typename?: "HasSurveyResponse";
  createdDate: Scalars["DateTime"]["output"];
  estimatedTime: Scalars["Int"]["output"];
  linkToSurvey: Scalars["String"]["output"];
  surveyId: Scalars["String"]["output"];
  surveyName: Scalars["String"]["output"];
  updatedDate: Scalars["DateTime"]["output"];
};

export type ImprovementGoal = {
  __typename?: "ImprovementGoal";
  completionDate?: Maybe<Scalars["Float"]["output"]>;
  productId: Scalars["String"]["output"];
  quizSlug: Scalars["String"]["output"];
  targetScore?: Maybe<Scalars["Float"]["output"]>;
  updatedAt?: Maybe<Scalars["Float"]["output"]>;
  updatedBy: Scalars["String"]["output"];
};

export type InfoLink = {
  __typename?: "InfoLink";
  placeholder_text?: Maybe<Scalars["String"]["output"]>;
  url: Scalars["String"]["output"];
};

export type KendraFilter = {
  invert?: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

export type KeywordSearch = {
  __typename?: "KeywordSearch";
  isKeywordSearch: Scalars["Boolean"]["output"];
  recommendations: Array<Scalars["String"]["output"]>;
};

export type L3EntityPermissions = {
  __typename?: "L3EntityPermissions";
  linkage: Scalars["Boolean"]["output"];
};

export type L3EntityResponse = {
  __typename?: "L3EntityResponse";
  delegates: Array<User>;
  description: Scalars["String"]["output"];
  designLead?: Maybe<User>;
  designLeadId?: Maybe<Scalars["String"]["output"]>;
  lastUpdated: Scalars["Float"]["output"];
  linkedTeams?: Maybe<Array<Team>>;
  name: Scalars["String"]["output"];
  /** Ops/CI groups that reside beneath the L3 entity. */
  opsGroups: Array<OpsGroup>;
  owner?: Maybe<User>;
  ownerId?: Maybe<Scalars["String"]["output"]>;
  permissions: L3EntityPermissions;
  serviceNowId: Scalars["ID"]["output"];
  techLead?: Maybe<User>;
  techLeadId?: Maybe<Scalars["String"]["output"]>;
  tier: L3TierResponse;
};

export type L3TierResponse = {
  __typename?: "L3TierResponse";
  name: Scalars["String"]["output"];
  parentName: Scalars["String"]["output"];
};

export type LeadTime = {
  __typename?: "LeadTime";
  /** The average time it takes for a commit to be merged into the main branch. */
  commitToMergeHours?: Maybe<Scalars["Float"]["output"]>;
  /** Target value for commit-to-merge duration metric at this given level. (Default is bp's target of 228 hours). */
  commitToMergeTargetValue?: Maybe<Scalars["Float"]["output"]>;
  /** The date the metrics were updated. */
  lastUpdatedDate: Scalars["Float"]["output"];
  /** The average time it takes for a commit to be merged into the main branch and deployed to production. */
  leadTimeHours?: Maybe<Scalars["Float"]["output"]>;
  /** The largest lead time value in the underlying data. */
  maxLeadTimeHours?: Maybe<Scalars["Float"]["output"]>;
  /** The largest lead time value in the underlying data in the trending (shorter) period. */
  maxTrendingLeadTimeHours?: Maybe<Scalars["Float"]["output"]>;
  /** The average time it takes for a merged commit to be deployed to production. */
  mergeToProdHours?: Maybe<Scalars["Float"]["output"]>;
  /** Target value for merge-to-prod duration metric at this given level. (Default is bp's target of 12 hours). */
  mergeToProdTargetValue?: Maybe<Scalars["Float"]["output"]>;
  /** The smallest lead time value in the underlying data. */
  minLeadTimeHours?: Maybe<Scalars["Float"]["output"]>;
  /** The smallest lead time value in the underlying data in the trending (shorter) period. */
  minTrendingLeadTimeHours?: Maybe<Scalars["Float"]["output"]>;
  /** The per-pipeline breakdown of the metrics. */
  perPipeline?: Maybe<Array<PipelineLeadTime>>;
  /** The per-repository breakdown of the metrics. */
  perRepository?: Maybe<Array<RepositoryLeadTime>>;
  /** The end date of the period for which the metrics are calculated. */
  periodEndDate: Scalars["Float"]["output"];
  /** The start date of the period for which the metrics are calculated. */
  periodStartDate: Scalars["Float"]["output"];
  /** The IDs of the repositories for which the metrics are calculated. */
  repositoryIds: Array<Scalars["String"]["output"]>;
  /** Target value for metric at this given level. (Default is bp's target of 12 hours). */
  targetValue?: Maybe<Scalars["Float"]["output"]>;
  /** The average time it takes for a commit to be merged into the main branch in the trending (shorter) period. */
  trendingCommitToMergeHours?: Maybe<Scalars["Float"]["output"]>;
  /** The average time it takes for a commit to be merged into the main branch and deployed to production in the trending (shorter) period. */
  trendingLeadTimeHours?: Maybe<Scalars["Float"]["output"]>;
  /** The average time it takes for a merged commit to be deployed to production in the trending (shorter) period. */
  trendingMergeToProdHours?: Maybe<Scalars["Float"]["output"]>;
  /** The start date of the trending (shorter) period for which the metrics are calculated. */
  trendingPeriodStartDate: Scalars["Float"]["output"];
};

export type LinkedRepository = {
  __typename?: "LinkedRepository";
  id: Scalars["String"]["output"];
  link: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type LinkedResource = {
  __typename?: "LinkedResource";
  createdDate: Scalars["Float"]["output"];
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  lastUpdated: Scalars["Float"]["output"];
  product: ProductResponse;
  team: Team;
  url: Scalars["String"]["output"];
};

export type MaturityProgress = {
  __typename?: "MaturityProgress";
  productsWithCompletedAssessmentCount: Scalars["Float"]["output"];
  productsWithImprovementGoalCount: Scalars["Float"]["output"];
  totalProductsCount: Scalars["Float"]["output"];
};

export type MetadataInfoField = {
  __typename?: "MetadataInfoField";
  name: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type MetadataInfoInputField = {
  name: Scalars["String"]["input"];
  type: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

export type MetadataQueryResponseType = {
  __typename?: "MetadataQueryResponseType";
  resources: Array<MetadataResponseType>;
};

export type MetadataResponseType = {
  __typename?: "MetadataResponseType";
  metadataInfo: Array<MetadataInfoField>;
  resourceName: Scalars["String"]["output"];
  resourceType: Scalars["String"]["output"];
  sourceId: Scalars["String"]["output"];
  tags?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type MetadataSubmissionType = {
  metadataInfo: Array<MetadataInfoInputField>;
  resourceName: Scalars["String"]["input"];
  resourceType: Scalars["String"]["input"];
  sourceId: Scalars["String"]["input"];
  tags?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type ModerationResult = {
  __typename?: "ModerationResult";
  pii: Scalars["Boolean"]["output"];
  piiEntities: Array<PiiItem>;
  text: Scalars["String"]["output"];
  toxic: Scalars["Boolean"]["output"];
  toxicAnnotations: Array<ToxicAnnotation>;
};

export type Mutation = {
  __typename?: "Mutation";
  addAnswer: AnswerWithQuestionId;
  addComment: CommentWithQuestionId;
  addGlobalTag: TagResponseType;
  /** Adds members to a product if the calling user is a member of the product. */
  addProductMembers: ProductResponse;
  addQuestion: QuestionWithAnswerAndCommentId;
  addTag: Tag;
  addTagType: TagTypeResponseType;
  approveComponent: ComponentResponse;
  approveQuestion?: Maybe<QuestionWithAnswerAndCommentId>;
  approveRevokeSurvey: SurveyApprovalResponse;
  /** Completes deletion of a support group. */
  completeSupportGroupDeletion: Scalars["Boolean"]["output"];
  completeSurvey: UsersWithSurvey;
  /** Creates an Alert Entity for a given OpsGroup */
  createAlert: Alert;
  /** Creates a product, optionally linking it to a list of teams. */
  createProduct: ProductResponse;
  createProductLinkedResource: LinkedResource;
  /** Creates and attaches a support group to a product. */
  createSupportGroup: SupportGroup;
  createSupportTicket: SupportTicket;
  createTeamLinkedResource: LinkedResource;
  createYallaDeployment: YallaResponse;
  createYallaRollback: YallaResponse;
  deleteAlert: DeleteAlert;
  deleteAnswer?: Maybe<AnswerWithQuestionId>;
  deleteChat: Chat;
  deleteComment?: Maybe<CommentWithQuestionId>;
  deleteComponent: ComponentResponse;
  deleteGlobalTag: TagResponseType;
  deleteImprovementGoal: DeletionResult;
  deleteMetadata: MetadataResponseType;
  /** Deletes a product, provided that the calling user owns or is a delegate of the product. */
  deleteProduct: DeleteProductResponse;
  /** Delete a product-linked resource. */
  deleteProductLinkedResource: DeleteLinkedResourceResponse;
  deleteQuestion?: Maybe<QuestionWithAnswerAndCommentId>;
  /** Deletes a support group. */
  deleteSupportGroup: Scalars["Boolean"]["output"];
  deleteTag?: Maybe<Tag>;
  deleteUnusedTagType: TagTypeResponseType;
  /** Edits a product, provided the calling user is either an owner or a delegate of the product. */
  editProduct: ProductResponse;
  executeSsa: SsaExecuteResponse;
  /** Allows a user to join a product */
  joinProduct: ProductResponse;
  /** Adds the calling user to the members of the specified team. */
  joinTeam: User;
  /** Allows a user to leave a product */
  leaveProduct: ProductResponse;
  /** Removes the calling user from the members of the specified team. */
  leaveTeam: User;
  /** Link a product to Cloudability */
  linkProductToCloudability: ProductResponse;
  /** Links a support group to an OnCall integration. */
  linkSupportGroupToIntegration: SupportGroup;
  markAnswerHelpful?: Maybe<AnswerWithQuestionId>;
  markAnswerUnhelpful?: Maybe<AnswerWithQuestionId>;
  moderateText: ModerationResult;
  moderateTextParameterised: ModerationResult;
  newChat: Chat;
  persistMetadata: MetadataResponseType;
  persistSurvey: SurveyResponse;
  refreshStalestUser: User;
  rejectComponent: ComponentResponse;
  rejectQuestion?: Maybe<QuestionWithAnswerAndCommentId>;
  /** Removes members from a product if the calling user is a member of the product. */
  removeProductMembers: ProductResponse;
  removeQuestionUpvote: QuestionWithAnswerAndCommentId;
  removeSurvey: RemoveSurveyResponse;
  renameChat: Chat;
  resetQuestionStatus?: Maybe<QuestionWithAnswerAndCommentId>;
  /** Emits a platform updated event, with the optional service name paramter to specify a service. */
  sendYallaPlatformUpdatedEvent: Scalars["Boolean"]["output"];
  submitAxelIssue: SubmitIssueResult;
  submitCs: CsResponse;
  submitProductQuizResult?: Maybe<QuizResult>;
  submitSsa: SsaResponse;
  tagResource: TagResourceResponseType;
  /** Unlink a product from Cloudability */
  unlinkProductFromCloudability: ProductResponse;
  unmarkAnswerHelpful?: Maybe<AnswerWithQuestionId>;
  unmarkAnswerUnhelpful?: Maybe<AnswerWithQuestionId>;
  untagResource: TagResourceResponseType;
  updateAnswer?: Maybe<AnswerWithQuestionId>;
  updateComment: CommentWithQuestionId;
  updateComponent: ComponentResponse;
  updateProductLinkedResource: LinkedResource;
  updateQuestion: QuestionWithAnswerAndCommentId;
  /** Updates a support group. */
  updateSupportGroup: SupportGroup;
  updateSurvey: SurveyResponse;
  updateTag: Tag;
  upsertImprovementGoal: ImprovementGoal;
  upvoteQuestion: QuestionWithAnswerAndCommentId;
  validateSsa: SsaValidationResponse;
};

export type MutationAddAnswerArgs = {
  data: AddAnswerInput;
};

export type MutationAddCommentArgs = {
  data: AddCommentInput;
};

export type MutationAddGlobalTagArgs = {
  command: TagSubmissionType;
};

export type MutationAddProductMembersArgs = {
  memberEmails: Array<Scalars["String"]["input"]>;
  productId: Scalars["String"]["input"];
};

export type MutationAddQuestionArgs = {
  data: AddQuestionInput;
};

export type MutationAddTagArgs = {
  name: Scalars["String"]["input"];
};

export type MutationAddTagTypeArgs = {
  tagType: TagTypeSubmissionType;
};

export type MutationApproveComponentArgs = {
  data: ApproveComponent;
};

export type MutationApproveQuestionArgs = {
  id: Scalars["String"]["input"];
};

export type MutationApproveRevokeSurveyArgs = {
  surveyStatus: ApproveRevokeSurveyInput;
};

export type MutationCompleteSupportGroupDeletionArgs = {
  opsGroupId: Scalars["String"]["input"];
};

export type MutationCompleteSurveyArgs = {
  completeSurvey: CompleteSurveyInput;
};

export type MutationCreateAlertArgs = {
  data: AlertCreateArgs;
};

export type MutationCreateProductArgs = {
  bpV1AssessmentTeamId?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  l3Uid: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  numberOfWorkers?: InputMaybe<Scalars["Float"]["input"]>;
  teamIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type MutationCreateProductLinkedResourceArgs = {
  description: Scalars["String"]["input"];
  productId: Scalars["String"]["input"];
  url: Scalars["String"]["input"];
};

export type MutationCreateSupportGroupArgs = {
  data: SupportGroupCreateArgs;
};

export type MutationCreateSupportTicketArgs = {
  description: Scalars["String"]["input"];
  incidentId?: InputMaybe<Scalars["String"]["input"]>;
  incidentUrl?: InputMaybe<Scalars["String"]["input"]>;
  serviceName?: InputMaybe<ServiceName>;
  supportType?: InputMaybe<SupportType>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  urgency?: InputMaybe<Urgency>;
};

export type MutationCreateTeamLinkedResourceArgs = {
  description: Scalars["String"]["input"];
  teamId: Scalars["String"]["input"];
  url: Scalars["String"]["input"];
};

export type MutationCreateYallaDeploymentArgs = {
  platformName: Scalars["String"]["input"];
  productName: Scalars["String"]["input"];
  serviceName: Scalars["String"]["input"];
  sourceCommitMessage: Scalars["String"]["input"];
  sourceCommitUri: Scalars["String"]["input"];
  valuesArtifactKey?: InputMaybe<Scalars["String"]["input"]>;
  version: Scalars["String"]["input"];
  workItemUris?: Array<Scalars["String"]["input"]>;
};

export type MutationCreateYallaRollbackArgs = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  platformName: Scalars["String"]["input"];
  productName: Scalars["String"]["input"];
  serviceName: Scalars["String"]["input"];
};

export type MutationDeleteAlertArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteAnswerArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteChatArgs = {
  chatId: Scalars["String"]["input"];
};

export type MutationDeleteCommentArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteComponentArgs = {
  data: DeleteComponent;
};

export type MutationDeleteGlobalTagArgs = {
  tagName: Scalars["String"]["input"];
};

export type MutationDeleteImprovementGoalArgs = {
  productId: Scalars["String"]["input"];
  quizSlug: Scalars["String"]["input"];
};

export type MutationDeleteMetadataArgs = {
  resourceType: Scalars["String"]["input"];
  sourceId: Scalars["String"]["input"];
};

export type MutationDeleteProductArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteProductLinkedResourceArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteQuestionArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteSupportGroupArgs = {
  data: SupportGroupDeleteArgs;
};

export type MutationDeleteTagArgs = {
  name: Scalars["String"]["input"];
};

export type MutationDeleteUnusedTagTypeArgs = {
  tagType: Scalars["String"]["input"];
};

export type MutationEditProductArgs = {
  bpV1AssessmentTeamId?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  l3Uid?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  numberOfWorkers?: InputMaybe<Scalars["Float"]["input"]>;
  repositories?: InputMaybe<Array<EditProductRepositoriesInput>>;
  repositoryIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  teamIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type MutationExecuteSsaArgs = {
  data: SsaExecute;
};

export type MutationJoinProductArgs = {
  productId: Scalars["String"]["input"];
};

export type MutationJoinTeamArgs = {
  id: Scalars["String"]["input"];
};

export type MutationLeaveProductArgs = {
  productId: Scalars["String"]["input"];
};

export type MutationLeaveTeamArgs = {
  id: Scalars["String"]["input"];
};

export type MutationLinkProductToCloudabilityArgs = {
  cloudabilityViewId: Scalars["Float"]["input"];
  productId: Scalars["String"]["input"];
};

export type MutationLinkSupportGroupToIntegrationArgs = {
  integrationId: Scalars["String"]["input"];
  opsGroupId: Scalars["String"]["input"];
};

export type MutationMarkAnswerHelpfulArgs = {
  id: Scalars["String"]["input"];
};

export type MutationMarkAnswerUnhelpfulArgs = {
  id: Scalars["String"]["input"];
};

export type MutationModerateTextArgs = {
  text: Scalars["String"]["input"];
};

export type MutationModerateTextParameterisedArgs = {
  anonymize?: Scalars["Boolean"]["input"];
  pii?: Scalars["Boolean"]["input"];
  text: Scalars["String"]["input"];
  toxicity?: Scalars["Boolean"]["input"];
};

export type MutationPersistMetadataArgs = {
  command: MetadataSubmissionType;
};

export type MutationPersistSurveyArgs = {
  survey: SurveyInput;
};

export type MutationRejectComponentArgs = {
  data: RejectComponent;
};

export type MutationRejectQuestionArgs = {
  id: Scalars["String"]["input"];
};

export type MutationRemoveProductMembersArgs = {
  memberEmails: Array<Scalars["String"]["input"]>;
  productId: Scalars["String"]["input"];
};

export type MutationRemoveQuestionUpvoteArgs = {
  id: Scalars["String"]["input"];
};

export type MutationRemoveSurveyArgs = {
  surveyId: Scalars["String"]["input"];
};

export type MutationRenameChatArgs = {
  renameMessage: RenameMessage;
};

export type MutationResetQuestionStatusArgs = {
  id: Scalars["String"]["input"];
};

export type MutationSendYallaPlatformUpdatedEventArgs = {
  platformName: Scalars["String"]["input"];
  serviceName?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationSubmitAxelIssueArgs = {
  submitIssue: SubmitIssue;
};

export type MutationSubmitCsArgs = {
  data: CsCommand;
};

export type MutationSubmitProductQuizResultArgs = {
  data: ProductSubmissionInput;
};

export type MutationSubmitSsaArgs = {
  data: SsaCommand;
};

export type MutationTagResourceArgs = {
  command: TagResourceSubmissionType;
};

export type MutationUnlinkProductFromCloudabilityArgs = {
  productId: Scalars["String"]["input"];
};

export type MutationUnmarkAnswerHelpfulArgs = {
  id: Scalars["String"]["input"];
};

export type MutationUnmarkAnswerUnhelpfulArgs = {
  id: Scalars["String"]["input"];
};

export type MutationUntagResourceArgs = {
  command: TagResourceSubmissionType;
};

export type MutationUpdateAnswerArgs = {
  data: UpdateAnswerInput;
};

export type MutationUpdateCommentArgs = {
  data: UpdateCommentInput;
};

export type MutationUpdateComponentArgs = {
  data: UpdateComponent;
};

export type MutationUpdateProductLinkedResourceArgs = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateQuestionArgs = {
  data: UpdateQuestionInput;
};

export type MutationUpdateSupportGroupArgs = {
  data: SupportGroupUpdateArgs;
};

export type MutationUpdateSurveyArgs = {
  survey: SurveyInput;
};

export type MutationUpdateTagArgs = {
  data: UpdateTagInput;
};

export type MutationUpsertImprovementGoalArgs = {
  completionDate?: InputMaybe<Scalars["Float"]["input"]>;
  productId: Scalars["String"]["input"];
  quizSlug: Scalars["String"]["input"];
  targetScore?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationUpvoteQuestionArgs = {
  id: Scalars["String"]["input"];
};

export type MutationValidateSsaArgs = {
  data: SsaValidate;
};

export type OpsGroup = {
  __typename?: "OpsGroup";
  applicationPackageName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  supportGroup?: Maybe<SupportGroup>;
  technicalServiceName: Scalars["String"]["output"];
  users: Array<User>;
};

export type Option = {
  __typename?: "Option";
  id: Scalars["Float"]["output"];
  label: Scalars["String"]["output"];
  value: Scalars["Float"]["output"];
};

export type PaginatedSupportGroup = {
  __typename?: "PaginatedSupportGroup";
  items: Array<SupportGroup>;
  totalCount: Scalars["Float"]["output"];
};

export type Permissions = {
  __typename?: "Permissions";
  isAdmin: Scalars["Boolean"]["output"];
  isModerator: Scalars["Boolean"]["output"];
  someRandomRole: Scalars["Boolean"]["output"];
};

export type PiiItem = {
  __typename?: "PiiItem";
  end: Scalars["Int"]["output"];
  entity: Scalars["String"]["output"];
  score: Scalars["Float"]["output"];
  start: Scalars["Int"]["output"];
};

export type PipelineDeploymentFrequency = {
  __typename?: "PipelineDeploymentFrequency";
  /** The date the metrics were updated. */
  lastUpdatedDate: Scalars["Float"]["output"];
  /** The total number of deployments */
  periodDeploymentCount: Scalars["Float"]["output"];
  /** The end date of the period for which the metrics are calculated. */
  periodEndDate: Scalars["Float"]["output"];
  /** The start date of the period for which the metrics are calculated. */
  periodStartDate: Scalars["Float"]["output"];
  /** The pipeline for which the metrics are calculated. */
  pipeline: AzureDevOpsPipeline;
  /** The ID of the pipeline for which the metrics are calculated. */
  pipelineId: Scalars["String"]["output"];
  /** The total number of deployments */
  trendingPeriodDeploymentCount: Scalars["Float"]["output"];
  /** The start date of the trending (shorter) period for which the metrics are calculated. */
  trendingPeriodStartDate: Scalars["Float"]["output"];
};

export type PipelineLeadTime = {
  __typename?: "PipelineLeadTime";
  /** The average time it takes for a commit to be merged into the main branch. */
  commitToMergeHours?: Maybe<Scalars["Float"]["output"]>;
  /** Target value for commit-to-merge duration metric at this given level. (Default is bp's target of 228 hours). */
  commitToMergeTargetValue?: Maybe<Scalars["Float"]["output"]>;
  /** The date the metrics were updated. */
  lastUpdatedDate: Scalars["Float"]["output"];
  /** The average time it takes for a commit to be merged into the main branch and deployed to production. */
  leadTimeHours?: Maybe<Scalars["Float"]["output"]>;
  /** The largest lead time value in the underlying data. */
  maxLeadTimeHours?: Maybe<Scalars["Float"]["output"]>;
  /** The largest lead time value in the underlying data in the trending (shorter) period. */
  maxTrendingLeadTimeHours?: Maybe<Scalars["Float"]["output"]>;
  /** The average time it takes for a merged commit to be deployed to production. */
  mergeToProdHours?: Maybe<Scalars["Float"]["output"]>;
  /** Target value for merge-to-prod duration metric at this given level. (Default is bp's target of 12 hours). */
  mergeToProdTargetValue?: Maybe<Scalars["Float"]["output"]>;
  /** The smallest lead time value in the underlying data. */
  minLeadTimeHours?: Maybe<Scalars["Float"]["output"]>;
  /** The smallest lead time value in the underlying data in the trending (shorter) period. */
  minTrendingLeadTimeHours?: Maybe<Scalars["Float"]["output"]>;
  /** The end date of the period for which the metrics are calculated. */
  periodEndDate: Scalars["Float"]["output"];
  /** The start date of the period for which the metrics are calculated. */
  periodStartDate: Scalars["Float"]["output"];
  /** The pipeline for which the metrics are calculated. */
  pipeline: AzureDevOpsPipeline;
  /** The ID of the pipeline for which the metrics are calculated. */
  pipelineId: Scalars["String"]["output"];
  /** Target value for metric at this given level. (Default is bp's target of 12 hours). */
  targetValue?: Maybe<Scalars["Float"]["output"]>;
  /** The average time it takes for a commit to be merged into the main branch in the trending (shorter) period. */
  trendingCommitToMergeHours?: Maybe<Scalars["Float"]["output"]>;
  /** The average time it takes for a commit to be merged into the main branch and deployed to production in the trending (shorter) period. */
  trendingLeadTimeHours?: Maybe<Scalars["Float"]["output"]>;
  /** The average time it takes for a merged commit to be deployed to production in the trending (shorter) period. */
  trendingMergeToProdHours?: Maybe<Scalars["Float"]["output"]>;
  /** The start date of the trending (shorter) period for which the metrics are calculated. */
  trendingPeriodStartDate: Scalars["Float"]["output"];
};

export type ProductPerceivedMetricsResult = {
  __typename?: "ProductPerceivedMetricsResult";
  changeFailPercentage?: Maybe<Scalars["String"]["output"]>;
  deployFrequency?: Maybe<Scalars["String"]["output"]>;
  entityId: Scalars["String"]["output"];
  leadTime?: Maybe<Scalars["String"]["output"]>;
  timeToRestore?: Maybe<Scalars["String"]["output"]>;
  updatedDate?: Maybe<Scalars["Float"]["output"]>;
};

export type ProductPermissions = {
  __typename?: "ProductPermissions";
  delete: Scalars["Boolean"]["output"];
  edit: Scalars["Boolean"]["output"];
  linkage: Scalars["Boolean"]["output"];
};

export type ProductRepositoryInfo = {
  __typename?: "ProductRepositoryInfo";
  organisationName?: Maybe<Scalars["String"]["output"]>;
  projectName?: Maybe<Scalars["String"]["output"]>;
  repositories: Array<LinkedRepository>;
};

export type ProductResponse = {
  __typename?: "ProductResponse";
  bpV1AssessmentTeam?: Maybe<Team>;
  /** Temporary: Field used to designate the team against which bp v1 product assessments are made. */
  bpV1AssessmentTeamId?: Maybe<Scalars["String"]["output"]>;
  cloudSpending?: Maybe<CloudSpending>;
  createdById?: Maybe<Scalars["String"]["output"]>;
  createdDate?: Maybe<Scalars["Float"]["output"]>;
  cyberScore?: Maybe<CyberScore>;
  delegates?: Maybe<Array<User>>;
  description?: Maybe<Scalars["String"]["output"]>;
  designLead?: Maybe<User>;
  designLeadId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  l3Entity?: Maybe<L3EntityResponse>;
  l3EntityUid?: Maybe<Scalars["String"]["output"]>;
  lastUpdated: Scalars["Float"]["output"];
  lastUpdatedById?: Maybe<Scalars["String"]["output"]>;
  linkedResources?: Maybe<Array<LinkedResource>>;
  linkedTeams?: Maybe<Array<Team>>;
  memberCount: Scalars["Float"]["output"];
  memberIds: Array<Scalars["String"]["output"]>;
  members: Array<User>;
  metrics: DoraMetrics;
  name: Scalars["String"]["output"];
  newCapabilityResult?: Maybe<CapabilityResult>;
  numberOfWorkers: Scalars["Float"]["output"];
  owner?: Maybe<User>;
  ownerId?: Maybe<Scalars["String"]["output"]>;
  perceivedMetrics?: Maybe<ProductPerceivedMetricsResult>;
  permissions: ProductPermissions;
  repositoryIds: Array<Scalars["String"]["output"]>;
  repositoryInfo: ProductRepositoryInfo;
  /** Support groups associated with the product that are onboarded to Grafana OnCall. */
  supportGroups: Array<SupportGroup>;
  techLead?: Maybe<User>;
  techLeadId?: Maybe<Scalars["String"]["output"]>;
  /** The name of the Yalla platform the product has been onboarded to. NOTE: Currently this is hard-coded to "accelerate" while Yalla is still in development. */
  yallaPlatform?: Maybe<YallaPlatform>;
};

export type ProductSubmissionInput = {
  answers: Array<AnswerInput>;
  productId: Scalars["String"]["input"];
  quizSlug: Scalars["String"]["input"];
  submittedBy?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProductsPaginated = {
  __typename?: "ProductsPaginated";
  items: Array<ProductResponse>;
  total: Scalars["Float"]["output"];
};

export type Query = {
  __typename?: "Query";
  allExistingComponents: Array<ComponentResponse>;
  allRelatedComponents: Array<ComponentResponse>;
  allTags: TagsResponseType;
  allUserComponents: Array<ComponentResponse>;
  answer?: Maybe<AnswerWithQuestionId>;
  answers: Array<AnswerWithQuestionId>;
  approvedQuestions: Array<QuestionWithAnswerAndCommentId>;
  chatRequest: ChatResponse;
  cloudabilityProducts: Array<CloudabilityProduct>;
  comment?: Maybe<CommentWithQuestionId>;
  comments: Array<CommentWithQuestionId>;
  /** Fetches the the calling user. */
  currentUserProfile: User;
  /** Get DORA metrics for the specified repositories, or all repositories/pipelines if repositoryIds left unspecified. */
  doraMetrics: DoraMetrics;
  /** Proxy for ADO - Get all Active Repos given an OrganisationName, optionally take a ProjectName */
  getAllActiveADORepos: Array<GetAdoRepoResponse>;
  getAllCs: Array<CsResponse>;
  getAllSSAs: Array<SsaResponse>;
  getAllTagDetails: TagsDetailsResponseType;
  getAllTagTypes: Array<AllTagTypesResponseType>;
  getHighlightedByTagType: TagsDetailsNodeResponseType;
  getHighlightedTags: TagsDetailsResponseType;
  getMetadata: MetadataResponseType;
  getMetadataByResourceType: MetadataQueryResponseType;
  getProductRepositoryInfo: ProductRepositoryInfo;
  getRelatedTagDetails: TagsDetailsNodeResponseType;
  /**
   * Get the Organisation and Project names the repo belongs to, if it exists within BP
   * @deprecated Use the repositoryInfo Sub-field on the products query
   */
  getRepoInfo: GetRepoInfoResponse;
  getResourcesForTag: ResourcesResponseType;
  getSuggestions: SuggestedQuestions;
  getSurveyById: SurveyResponse;
  getTagDetails: TagsDetailsResponseType;
  getTagTypeDetails: TagTypeResponseType;
  getTagTypes: TagsTypeResponseType;
  getTagsForResource: TagsResponseType;
  getUserByEmail: User;
  getUserByUserId: User;
  getUserCs: Array<CsResponse>;
  getUserSSAs: Array<SsaResponse>;
  /** Get an environment belonging to a Yalla platform. */
  getYallaEnvironment: YallaEnvironment;
  /** Get a platform released to by Yalla. */
  getYallaPlatform: YallaPlatform;
  /** Get a Yalla product by name. */
  getYallaProduct: YallaProduct;
  /** Get a service released onto a Yalla platform. */
  getYallaService: YallaService;
  /** Get a service instance by name, platform name, and environment. */
  getYallaServiceInstance: YallaServiceInstance;
  hasSurvey: Array<HasSurveyResponse>;
  improvementGoal?: Maybe<ImprovementGoal>;
  improvementGoals: Array<ImprovementGoal>;
  /** Lists the ServiceNow L3 entities for which the user is an owner or a delegate. */
  l3Entities: Array<L3EntityResponse>;
  /** Retrieves a ServiceNow L3 entity for a product. */
  l3Entity: L3EntityResponse;
  listChats: Array<Chat>;
  listSurveys: Array<SurveyResponse>;
  /** List all deployments for a given platform. */
  listYallaDeployments: Array<YallaDeployment>;
  /** List all platforms released to by Yalla. */
  listYallaPlatforms: Array<YallaPlatform>;
  /** List all service instances for a given platform. */
  listYallaServiceInstances: Array<YallaServiceInstance>;
  /** List all services released to a given platform by Yalla. */
  listYallaServices: Array<YallaService>;
  maturityProgress: MaturityProgress;
  openChat: ChatHistory;
  perceivedMetrics: ProductPerceivedMetricsResult;
  permissions: Permissions;
  /** Fetches a product by its ID. */
  product: ProductResponse;
  productCapabilityResult: CapabilityResult;
  productQuizResult?: Maybe<QuizResult>;
  /** List all products, filter by name */
  products: Array<ProductResponse>;
  /** List products with skip, take, and filter */
  productsPaginated: ProductsPaginated;
  question?: Maybe<QuestionWithFullAnswerAndComments>;
  questions: Array<QuestionWithAnswerAndCommentId>;
  quiz?: Maybe<Quiz>;
  quizzes: Array<Quiz>;
  /** Exports raw pipeline metrics to CSV string. */
  report: Report;
  retrieveComponent: ComponentResponse;
  retrieveCs: CsResponse;
  retrieveSsa: SsaDetails;
  retrieveSsaOptions: SsaOptionsApiResponse;
  retrieveSuggestedTags: SuggestedTags;
  /** Lists the ServiceNow L3 entities for which the user is an owner or a delegate. */
  searchL3Entities: Array<L3EntityResponse>;
  /** List support groups with skip, take, and filter. */
  supportGroupsPaginated: PaginatedSupportGroup;
  tag?: Maybe<TagWithFullQuestion>;
  tags: Array<TagWithFullQuestion>;
  /**
   * Get a team by its id
   * @deprecated Will be removed soon.
   */
  team: Team;
  /** Lists the ServiceNow L3 entities for which the user is an owner or a delegate. */
  userL3Entities: Array<L3EntityResponse>;
  userQuestions: Array<QuestionWithAnswerAndCommentId>;
};

export type QueryAllRelatedComponentsArgs = {
  data: RelatedTags;
};

export type QueryAnswerArgs = {
  id: Scalars["String"]["input"];
};

export type QueryChatRequestArgs = {
  chatMessage: ChatMessage;
};

export type QueryCommentArgs = {
  id: Scalars["String"]["input"];
};

export type QueryDoraMetricsArgs = {
  historicalDataEndYYYYMM?: InputMaybe<Scalars["Float"]["input"]>;
  historicalDataStartYYYYMM?: InputMaybe<Scalars["Float"]["input"]>;
  repositoryIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type QueryGetAllActiveAdoReposArgs = {
  organisationName: Scalars["String"]["input"];
  projectName: Scalars["String"]["input"];
};

export type QueryGetHighlightedByTagTypeArgs = {
  tagType: Scalars["String"]["input"];
};

export type QueryGetMetadataArgs = {
  resourceType: Scalars["String"]["input"];
  sourceId: Scalars["String"]["input"];
};

export type QueryGetMetadataByResourceTypeArgs = {
  resourceType: Scalars["String"]["input"];
};

export type QueryGetProductRepositoryInfoArgs = {
  repositoryIds: Array<Scalars["String"]["input"]>;
};

export type QueryGetRelatedTagDetailsArgs = {
  tagDetails: TagDetailsInput;
};

export type QueryGetRepoInfoArgs = {
  repositoryId: Scalars["String"]["input"];
};

export type QueryGetResourcesForTagArgs = {
  tagName: Scalars["String"]["input"];
};

export type QueryGetSuggestionsArgs = {
  requestSuggestions: RequestSuggestions;
};

export type QueryGetSurveyByIdArgs = {
  surveyId: Scalars["String"]["input"];
};

export type QueryGetTagDetailsArgs = {
  tagDetails: TagDetailsInput;
};

export type QueryGetTagTypeDetailsArgs = {
  tagType: Scalars["String"]["input"];
};

export type QueryGetTagsForResourceArgs = {
  resourceType: Scalars["String"]["input"];
  sourceId: Scalars["String"]["input"];
};

export type QueryGetUserByEmailArgs = {
  email: Scalars["String"]["input"];
};

export type QueryGetUserByUserIdArgs = {
  userId: Scalars["String"]["input"];
};

export type QueryGetYallaEnvironmentArgs = {
  name: Scalars["String"]["input"];
  platformName: Scalars["String"]["input"];
};

export type QueryGetYallaPlatformArgs = {
  name: Scalars["String"]["input"];
};

export type QueryGetYallaProductArgs = {
  name: Scalars["String"]["input"];
  platformName: Scalars["String"]["input"];
};

export type QueryGetYallaServiceArgs = {
  name: Scalars["String"]["input"];
  platformName: Scalars["String"]["input"];
};

export type QueryGetYallaServiceInstanceArgs = {
  environment: Scalars["String"]["input"];
  platformName: Scalars["String"]["input"];
  serviceName: Scalars["String"]["input"];
};

export type QueryImprovementGoalArgs = {
  productId: Scalars["String"]["input"];
  quizSlug: Scalars["String"]["input"];
};

export type QueryImprovementGoalsArgs = {
  productId: Scalars["String"]["input"];
};

export type QueryL3EntityArgs = {
  id: Scalars["String"]["input"];
};

export type QueryListYallaDeploymentsArgs = {
  platformName: Scalars["String"]["input"];
  productName?: InputMaybe<Scalars["String"]["input"]>;
  serviceName?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryListYallaServiceInstancesArgs = {
  environment?: InputMaybe<Scalars["String"]["input"]>;
  platformName: Scalars["String"]["input"];
  productName?: InputMaybe<Scalars["String"]["input"]>;
  serviceName?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryListYallaServicesArgs = {
  platformName: Scalars["String"]["input"];
};

export type QueryMaturityProgressArgs = {
  quizTypes: Array<Scalars["QuizType"]["input"]>;
};

export type QueryOpenChatArgs = {
  chatId: Scalars["String"]["input"];
};

export type QueryPerceivedMetricsArgs = {
  entityId: Scalars["String"]["input"];
};

export type QueryProductArgs = {
  id: Scalars["String"]["input"];
};

export type QueryProductCapabilityResultArgs = {
  productId: Scalars["String"]["input"];
};

export type QueryProductQuizResultArgs = {
  entityId: Scalars["String"]["input"];
  quizSlug: Scalars["QuizSlug"]["input"];
};

export type QueryProductsArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryProductsPaginatedArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryQuestionArgs = {
  id: Scalars["String"]["input"];
};

export type QueryQuestionsArgs = {
  hideApproved?: Scalars["Boolean"]["input"];
  hideDeleted?: Scalars["Boolean"]["input"];
  hideInReview?: Scalars["Boolean"]["input"];
  hideRejected?: Scalars["Boolean"]["input"];
};

export type QueryQuizArgs = {
  slug: Scalars["String"]["input"];
};

export type QueryQuizzesArgs = {
  quizTypes?: InputMaybe<Array<Scalars["QuizType"]["input"]>>;
  teamId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryRetrieveComponentArgs = {
  name: Scalars["String"]["input"];
};

export type QueryRetrieveCsArgs = {
  name: Scalars["String"]["input"];
};

export type QueryRetrieveSsaArgs = {
  name: Scalars["String"]["input"];
};

export type QueryRetrieveSsaOptionsArgs = {
  data: SsaOptionsApiRequest;
};

export type QueryRetrieveSuggestedTagsArgs = {
  context: Scalars["String"]["input"];
};

export type QuerySearchL3EntitiesArgs = {
  page?: Scalars["Int"]["input"];
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  search: Scalars["String"]["input"];
};

export type QuerySupportGroupsPaginatedArgs = {
  limit?: InputMaybe<Scalars["Float"]["input"]>;
  productId?: InputMaybe<Scalars["String"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  skip?: InputMaybe<Scalars["Float"]["input"]>;
};

export type QueryTagArgs = {
  hideApprovedQuestions?: Scalars["Boolean"]["input"];
  hideInReviewQuestions?: Scalars["Boolean"]["input"];
  hideRejectedQuestions?: Scalars["Boolean"]["input"];
  name: Scalars["String"]["input"];
};

export type QueryTagsArgs = {
  hideApprovedQuestions?: Scalars["Boolean"]["input"];
  hideInReviewQuestions?: Scalars["Boolean"]["input"];
  hideRejectedQuestions?: Scalars["Boolean"]["input"];
};

export type QueryTeamArgs = {
  id: Scalars["String"]["input"];
};

export type QueryUserQuestionsArgs = {
  hideApproved?: Scalars["Boolean"]["input"];
  hideInReview?: Scalars["Boolean"]["input"];
  hideRejected?: Scalars["Boolean"]["input"];
  user: Scalars["String"]["input"];
};

export type Question = {
  __typename?: "Question";
  back: Scalars["Boolean"]["output"];
  id: Scalars["Float"]["output"];
  isRecentlyUpdated: Scalars["Boolean"]["output"];
  metric?: Maybe<Scalars["String"]["output"]>;
  options: Array<Option>;
  question: Scalars["String"]["output"];
};

export type QuestionWithAnswerAndCommentId = {
  __typename?: "QuestionWithAnswerAndCommentId";
  answers?: Maybe<Array<Scalars["String"]["output"]>>;
  author: Scalars["String"]["output"];
  body: Scalars["String"]["output"];
  comments?: Maybe<Array<Scalars["String"]["output"]>>;
  createdAt: Scalars["String"]["output"];
  deletedAt?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  status: Scalars["Float"]["output"];
  tags?: Maybe<Array<Scalars["String"]["output"]>>;
  title: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["String"]["output"]>;
  updatedBy?: Maybe<Scalars["String"]["output"]>;
  upvotes: Scalars["Int"]["output"];
  userHasUpvoted: Scalars["Boolean"]["output"];
};

export type QuestionWithFullAnswerAndComments = {
  __typename?: "QuestionWithFullAnswerAndComments";
  answers?: Maybe<Array<AnswerWithQuestionId>>;
  author: Scalars["String"]["output"];
  body: Scalars["String"]["output"];
  comments?: Maybe<Array<CommentWithQuestionId>>;
  createdAt: Scalars["String"]["output"];
  deletedAt?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  status: Scalars["Float"]["output"];
  tags?: Maybe<Array<Scalars["String"]["output"]>>;
  title: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["String"]["output"]>;
  updatedBy?: Maybe<Scalars["String"]["output"]>;
  upvotes: Scalars["Int"]["output"];
  userHasUpvoted?: Maybe<Scalars["Boolean"]["output"]>;
};

export type Quiz = {
  __typename?: "Quiz";
  category?: Maybe<Scalars["QuizCategory"]["output"]>;
  description: Scalars["String"]["output"];
  /** href to learn more page for the quiz. This will either be a full URL for external links, or a relative URL that will need to be mapped by the consumer. */
  href?: Maybe<Scalars["String"]["output"]>;
  isRecentlyUpdated: Scalars["Boolean"]["output"];
  maxScore: Scalars["Float"]["output"];
  name: Scalars["String"]["output"];
  questions: Array<Question>;
  quizType: Scalars["QuizType"]["output"];
  slug: Scalars["ID"]["output"];
};

export type QuizResult = {
  __typename?: "QuizResult";
  answers: Array<Answer>;
  entityId: Scalars["String"]["output"];
  lastUpdated: Scalars["Float"]["output"];
  /** @deprecated Succeeded by lastUpdated. */
  lastUpdatedDate: Scalars["Float"]["output"];
  maxScore?: Maybe<Scalars["Float"]["output"]>;
  quiz: Quiz;
  quizType?: Maybe<Scalars["QuizType"]["output"]>;
  score?: Maybe<Scalars["Score"]["output"]>;
};

/** Rejects a component */
export type RejectComponent = {
  component_id: Scalars["Float"]["input"];
};

/** Deletes a component */
export type RelatedTags = {
  tags: Array<Scalars["String"]["input"]>;
};

export type RemoveSurveyResponse = {
  __typename?: "RemoveSurveyResponse";
  message: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
};

export type RenameMessage = {
  chatId: Scalars["String"]["input"];
  newName: Scalars["String"]["input"];
};

export type Report = {
  __typename?: "Report";
  /** Monthly pipeline metrics table exported as CSV */
  monthlyPipelineMetrics: Scalars["String"]["output"];
  /** Pipeline metrics table exported as CSV */
  pipelineMetrics: Scalars["String"]["output"];
};

export type RepositoryDeployFrequency = {
  __typename?: "RepositoryDeployFrequency";
  /** The date the metrics were updated. */
  lastUpdatedDate: Scalars["Float"]["output"];
  /** The per-pipeline breakdown of the repository's metrics. */
  perPipeline?: Maybe<Array<PipelineDeploymentFrequency>>;
  /** The total number of deployments */
  periodDeploymentCount: Scalars["Float"]["output"];
  /** The end date of the period for which the metrics are calculated. */
  periodEndDate: Scalars["Float"]["output"];
  /** The start date of the period for which the metrics are calculated. */
  periodStartDate: Scalars["Float"]["output"];
  /** The repository for which the metrics are calculated. */
  repository: AzureDevOpsRepository;
  /** The ID of the repository for which the metrics are calculated. */
  repositoryId: Scalars["String"]["output"];
  /** The total number of deployments */
  trendingPeriodDeploymentCount: Scalars["Float"]["output"];
  /** The start date of the trending (shorter) period for which the metrics are calculated. */
  trendingPeriodStartDate: Scalars["Float"]["output"];
};

export type RepositoryLeadTime = {
  __typename?: "RepositoryLeadTime";
  /** The average time it takes for a commit to be merged into the main branch. */
  commitToMergeHours?: Maybe<Scalars["Float"]["output"]>;
  /** Target value for commit-to-merge duration metric at this given level. (Default is bp's target of 228 hours). */
  commitToMergeTargetValue?: Maybe<Scalars["Float"]["output"]>;
  /** The date the metrics were updated. */
  lastUpdatedDate: Scalars["Float"]["output"];
  /** The average time it takes for a commit to be merged into the main branch and deployed to production. */
  leadTimeHours?: Maybe<Scalars["Float"]["output"]>;
  /** The largest lead time value in the underlying data. */
  maxLeadTimeHours?: Maybe<Scalars["Float"]["output"]>;
  /** The largest lead time value in the underlying data in the trending (shorter) period. */
  maxTrendingLeadTimeHours?: Maybe<Scalars["Float"]["output"]>;
  /** The average time it takes for a merged commit to be deployed to production. */
  mergeToProdHours?: Maybe<Scalars["Float"]["output"]>;
  /** Target value for merge-to-prod duration metric at this given level. (Default is bp's target of 12 hours). */
  mergeToProdTargetValue?: Maybe<Scalars["Float"]["output"]>;
  /** The smallest lead time value in the underlying data. */
  minLeadTimeHours?: Maybe<Scalars["Float"]["output"]>;
  /** The smallest lead time value in the underlying data in the trending (shorter) period. */
  minTrendingLeadTimeHours?: Maybe<Scalars["Float"]["output"]>;
  /** The per-pipeline breakdown of the repository's metrics. */
  perPipeline?: Maybe<Array<PipelineLeadTime>>;
  /** The end date of the period for which the metrics are calculated. */
  periodEndDate: Scalars["Float"]["output"];
  /** The start date of the period for which the metrics are calculated. */
  periodStartDate: Scalars["Float"]["output"];
  /** The repository for which the metrics are calculated. */
  repository: AzureDevOpsRepository;
  /** The ID of the repository for which the metrics are calculated. */
  repositoryId: Scalars["String"]["output"];
  /** Target value for metric at this given level. (Default is bp's target of 12 hours). */
  targetValue?: Maybe<Scalars["Float"]["output"]>;
  /** The average time it takes for a commit to be merged into the main branch in the trending (shorter) period. */
  trendingCommitToMergeHours?: Maybe<Scalars["Float"]["output"]>;
  /** The average time it takes for a commit to be merged into the main branch and deployed to production in the trending (shorter) period. */
  trendingLeadTimeHours?: Maybe<Scalars["Float"]["output"]>;
  /** The average time it takes for a merged commit to be deployed to production in the trending (shorter) period. */
  trendingMergeToProdHours?: Maybe<Scalars["Float"]["output"]>;
  /** The start date of the trending (shorter) period for which the metrics are calculated. */
  trendingPeriodStartDate: Scalars["Float"]["output"];
};

export type RequestSuggestions = {
  chatId: Scalars["String"]["input"];
  question: Scalars["String"]["input"];
};

export type ResourceInputType = {
  resourceName?: InputMaybe<Scalars["String"]["input"]>;
  resourceType: Scalars["String"]["input"];
  sourceId: Scalars["String"]["input"];
};

export type ResourceType = {
  __typename?: "ResourceType";
  resourceName?: Maybe<Scalars["String"]["output"]>;
  resourceType: Scalars["String"]["output"];
  sourceId: Scalars["String"]["output"];
  tags?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type ResourcesResponseType = {
  __typename?: "ResourcesResponseType";
  relatedTags?: Maybe<Array<TagDetailType>>;
  resources: Array<ResourceType>;
};

export enum ServiceName {
  Community = "COMMUNITY",
  Core = "CORE",
  DevopsIdentity = "DEVOPS_IDENTITY",
  FeatureRequest = "FEATURE_REQUEST",
  GoldenPaths = "GOLDEN_PATHS",
  Incident = "INCIDENT",
  KnowledgeHub = "KNOWLEDGE_HUB",
  Search = "SEARCH",
  Tooling = "TOOLING",
  ToolingAnsible = "TOOLING_ANSIBLE",
  ToolingAppcenter = "TOOLING_APPCENTER",
  ToolingArtifactory = "TOOLING_ARTIFACTORY",
  ToolingAzureDevops = "TOOLING_AZURE_DEVOPS",
  ToolingJenkins = "TOOLING_JENKINS",
  ToolingSonarqube = "TOOLING_SONARQUBE",
  ToolingSplunk = "TOOLING_SPLUNK",
  Unknown = "UNKNOWN",
}

export type SourceDoc = {
  __typename?: "SourceDoc";
  excerpt?: Maybe<Scalars["String"]["output"]>;
  score?: Maybe<Scalars["Float"]["output"]>;
  title: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

export type SsaArguments = {
  __typename?: "SsaArguments";
  arg_type: Scalars["String"]["output"];
  default?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  hidden?: Maybe<Scalars["Boolean"]["output"]>;
  http_parameter_type: Scalars["String"]["output"];
  info_link?: Maybe<InfoLink>;
  max_length?: Maybe<Scalars["Float"]["output"]>;
  max_value?: Maybe<Scalars["String"]["output"]>;
  min_length?: Maybe<Scalars["Float"]["output"]>;
  min_value?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  options?: Maybe<Scalars["String"]["output"]>;
  options_api?: Maybe<Scalars["String"]["output"]>;
  regex?: Maybe<Scalars["String"]["output"]>;
  regex_hint?: Maybe<Scalars["String"]["output"]>;
  whats_this_link?: Maybe<Scalars["String"]["output"]>;
};

/** Create or Update a Self Service Automation */
export type SsaCommand = {
  authenticationCredential: Scalars["String"]["input"];
  authenticationType: Scalars["String"]["input"];
  authorizationHeaderName?: InputMaybe<Scalars["String"]["input"]>;
  description: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  specFileUrl: Scalars["String"]["input"];
  tags: Array<Scalars["String"]["input"]>;
};

export type SsaDetails = {
  __typename?: "SsaDetails";
  authenticationCredential?: Maybe<Scalars["String"]["output"]>;
  authenticationType?: Maybe<Scalars["String"]["output"]>;
  authorEmail?: Maybe<Scalars["String"]["output"]>;
  authorizationHeaderName?: Maybe<Scalars["String"]["output"]>;
  description: Scalars["String"]["output"];
  id: Scalars["Float"]["output"];
  name: Scalars["String"]["output"];
  specFileUrl: Scalars["String"]["output"];
  status?: Maybe<Scalars["String"]["output"]>;
  tags?: Maybe<Array<Scalars["String"]["output"]>>;
  userParameters?: Maybe<Array<SsaArguments>>;
};

export type SsaExecute = {
  id: Scalars["Float"]["input"];
  parameters: Array<SsaParameters>;
};

export type SsaExecuteResponse = {
  __typename?: "SsaExecuteResponse";
  code: Scalars["String"]["output"];
};

/** Validate Self Service Automation */
export type SsaOptionsApiMandatoryField = {
  name: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

/** Validate Self Service Automation */
export type SsaOptionsApiRequest = {
  id: Scalars["Float"]["input"];
  mandatoryFields?: InputMaybe<Array<SsaOptionsApiMandatoryField>>;
  parameterName: Scalars["String"]["input"];
};

export type SsaOptionsApiResponse = {
  __typename?: "SsaOptionsApiResponse";
  options: Array<Scalars["String"]["output"]>;
  parameterName: Scalars["String"]["output"];
};

export type SsaParameters = {
  http_parameter_type: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

export type SsaResponse = {
  __typename?: "SsaResponse";
  authenticationCredential?: Maybe<Scalars["String"]["output"]>;
  authenticationType?: Maybe<Scalars["String"]["output"]>;
  authorEmail?: Maybe<Scalars["String"]["output"]>;
  authorizationHeaderName?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["String"]["output"]>;
  description: Scalars["String"]["output"];
  id: Scalars["Float"]["output"];
  name: Scalars["String"]["output"];
  specFileUrl: Scalars["String"]["output"];
  status?: Maybe<Scalars["String"]["output"]>;
  tags?: Maybe<Array<Scalars["String"]["output"]>>;
  updatedAt?: Maybe<Scalars["String"]["output"]>;
};

/** Validate Self Service Automation */
export type SsaValidate = {
  specFileUrl: Scalars["String"]["input"];
};

export type SsaValidationResponse = {
  __typename?: "SsaValidationResponse";
  code: Scalars["Float"]["output"];
  error?: Maybe<Scalars["String"]["output"]>;
  errors?: Maybe<Array<Scalars["String"]["output"]>>;
  message: Scalars["String"]["output"];
  preview: Scalars["String"]["output"];
};

export type SubmitIssue = {
  auditId: Scalars["String"]["input"];
  comments?: InputMaybe<Scalars["String"]["input"]>;
  issueType: Scalars["String"]["input"];
};

export type SubmitIssueResult = {
  __typename?: "SubmitIssueResult";
  auditId: Scalars["String"]["output"];
  comments: Scalars["String"]["output"];
  issueType: Scalars["String"]["output"];
  issuedBy: Scalars["String"]["output"];
};

export type SuggestedQuestions = {
  __typename?: "SuggestedQuestions";
  questions: Array<Scalars["String"]["output"]>;
};

export type SuggestedTags = {
  __typename?: "SuggestedTags";
  tags: Array<Scalars["String"]["output"]>;
};

/** DEPRECATED: Teams will be removed from Accelerate soon */
export type SuggestedTeam = {
  __typename?: "SuggestedTeam";
  _id?: Maybe<Scalars["ID"]["output"]>;
  areaPath: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type SupportGroup = {
  __typename?: "SupportGroup";
  alerts: Array<Alert>;
  /** Whether members of the support group who are on call are public. */
  areOnCallMembersPublic?: Maybe<Scalars["Boolean"]["output"]>;
  /** Contact method for the support group. This is likely to change in the future */
  contactMethod: ContactMethod;
  description?: Maybe<Scalars["String"]["output"]>;
  integrationId?: Maybe<Scalars["String"]["output"]>;
  /** Whether the support group has been fully configured with schedule & escalation policies. */
  isConfigured: Scalars["Boolean"]["output"];
  /** True if the support group is currently being deleted. */
  isDeleting: Scalars["Boolean"]["output"];
  /** True if the support group is in the onboarding process. */
  isOnboarding: Scalars["Boolean"]["output"];
  /** href links to Grafana pages */
  links?: Maybe<SupportGroupLinks>;
  name: Scalars["String"]["output"];
  /** @deprecated Superseded by integrationId. */
  onCallTeamId?: Maybe<Scalars["String"]["output"]>;
  opsGroup?: Maybe<OpsGroup>;
  opsGroupId: Scalars["String"]["output"];
  productId?: Maybe<Scalars["String"]["output"]>;
  /** Returns the user final shifts for a given date. */
  userShifts?: Maybe<Array<SupportGroupUserShift>>;
  users?: Maybe<Array<User>>;
};

export type SupportGroupUserShiftsArgs = {
  date: Scalars["Float"]["input"];
};

export type SupportGroupCreateArgs = {
  areOnCallMembersPublic?: InputMaybe<Scalars["Boolean"]["input"]>;
  contactMethod: ContactMethodArgs;
  description: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  opsGroupId: Scalars["String"]["input"];
  productId: Scalars["String"]["input"];
};

export type SupportGroupDeleteArgs = {
  opsGroupId: Scalars["String"]["input"];
};

export type SupportGroupLinks = {
  __typename?: "SupportGroupLinks";
  alertGroupsLink: Scalars["String"]["output"];
  manageEscalationPoliciesLink: Scalars["String"]["output"];
  manageScheduleLink: Scalars["String"]["output"];
};

export type SupportGroupUpdateArgs = {
  areOnCallMembersPublic?: InputMaybe<Scalars["Boolean"]["input"]>;
  contactMethod?: InputMaybe<ContactMethodArgs>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  opsGroupId: Scalars["String"]["input"];
  productId?: InputMaybe<Scalars["String"]["input"]>;
};

export type SupportGroupUserShift = {
  __typename?: "SupportGroupUserShift";
  /** The date on which the schedule falls. */
  date: Scalars["Float"]["output"];
  /** The end date of the support hours. */
  end?: Maybe<Scalars["Float"]["output"]>;
  /** Whether the user is on call now (i.e. the current time is within the shift). */
  isOnCall: Scalars["Boolean"]["output"];
  /** The name of the schedule the shift belongs to. */
  scheduleName: Scalars["String"]["output"];
  /** The start date of the support hours. */
  start?: Maybe<Scalars["Float"]["output"]>;
  /** The users on call for the support hours. */
  user: User;
};

export type SupportTicket = {
  __typename?: "SupportTicket";
  description: Scalars["String"]["output"];
  incidentId?: Maybe<Scalars["String"]["output"]>;
  incidentUrl?: Maybe<Scalars["String"]["output"]>;
  serviceName?: Maybe<ServiceName>;
  supportType?: Maybe<SupportType>;
  title?: Maybe<Scalars["String"]["output"]>;
  urgency?: Maybe<Urgency>;
};

export enum SupportType {
  FeatureRequest = "FEATURE_REQUEST",
  Incident = "INCIDENT",
}

export type SurveyApprovalResponse = {
  __typename?: "SurveyApprovalResponse";
  approved: Scalars["Boolean"]["output"];
  surveyId: Scalars["String"]["output"];
  surveyName: Scalars["String"]["output"];
};

export type SurveyInput = {
  approved?: InputMaybe<Scalars["Boolean"]["input"]>;
  description: Scalars["String"]["input"];
  endDate: Scalars["DateTime"]["input"];
  estimatedTime: Scalars["Int"]["input"];
  linkToSurvey: Scalars["String"]["input"];
  startDate: Scalars["DateTime"]["input"];
  surveyId?: InputMaybe<Scalars["String"]["input"]>;
  surveyName: Scalars["String"]["input"];
};

export type SurveyResponse = {
  __typename?: "SurveyResponse";
  approved: Scalars["Boolean"]["output"];
  author: Scalars["String"]["output"];
  createdDate: Scalars["DateTime"]["output"];
  description: Scalars["String"]["output"];
  endDate: Scalars["DateTime"]["output"];
  estimatedTime: Scalars["Int"]["output"];
  lastUpdatedBy: Scalars["String"]["output"];
  linkToSurvey: Scalars["String"]["output"];
  startDate: Scalars["DateTime"]["output"];
  surveyId: Scalars["String"]["output"];
  surveyName: Scalars["String"]["output"];
  updatedDate: Scalars["DateTime"]["output"];
};

export type Tag = {
  __typename?: "Tag";
  createdAt: Scalars["String"]["output"];
  deletedAt?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  questionCount: Scalars["Int"]["output"];
  questions?: Maybe<Array<Scalars["String"]["output"]>>;
  updatedAt?: Maybe<Scalars["String"]["output"]>;
  updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type TagDetailNodeType = {
  __typename?: "TagDetailNodeType";
  description?: Maybe<Scalars["String"]["output"]>;
  highlighted?: Maybe<Scalars["Boolean"]["output"]>;
  icon?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  kHubOverviewPage?: Maybe<Scalars["String"]["output"]>;
  referenceId?: Maybe<Scalars["String"]["output"]>;
  relatedTags?: Maybe<Array<TagDetailType>>;
  tagName: Scalars["String"]["output"];
  tagType?: Maybe<Scalars["String"]["output"]>;
};

export type TagDetailType = {
  __typename?: "TagDetailType";
  description?: Maybe<Scalars["String"]["output"]>;
  highlighted?: Maybe<Scalars["Boolean"]["output"]>;
  icon?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  kHubOverviewPage?: Maybe<Scalars["String"]["output"]>;
  referenceId?: Maybe<Scalars["String"]["output"]>;
  tagName: Scalars["String"]["output"];
  tagType?: Maybe<Scalars["String"]["output"]>;
};

export type TagDetailsInput = {
  tags: Array<Scalars["String"]["input"]>;
};

export type TagResourceResponseType = {
  __typename?: "TagResourceResponseType";
  resourceType: Scalars["String"]["output"];
  sourceId: Scalars["String"]["output"];
  tagName: Scalars["String"]["output"];
};

export type TagResourceSubmissionType = {
  resourceType: Scalars["String"]["input"];
  sourceId: Scalars["String"]["input"];
  tagName: Scalars["String"]["input"];
};

export type TagResponseType = {
  __typename?: "TagResponseType";
  description?: Maybe<Scalars["String"]["output"]>;
  highlighted?: Maybe<Scalars["Boolean"]["output"]>;
  icon?: Maybe<Scalars["String"]["output"]>;
  kHubOverviewPage?: Maybe<Scalars["String"]["output"]>;
  referenceId?: Maybe<Scalars["String"]["output"]>;
  resources: Array<ResourceType>;
  tagName: Scalars["String"]["output"];
  tagType?: Maybe<Scalars["String"]["output"]>;
};

export type TagSubmissionType = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  highlighted?: InputMaybe<Scalars["Boolean"]["input"]>;
  icon?: InputMaybe<Scalars["String"]["input"]>;
  kHubOverviewPage?: InputMaybe<Scalars["String"]["input"]>;
  referenceId?: InputMaybe<Scalars["String"]["input"]>;
  resources?: InputMaybe<Array<ResourceInputType>>;
  tagName: Scalars["String"]["input"];
  tagType?: InputMaybe<Scalars["String"]["input"]>;
};

export type TagTypeResponseType = {
  __typename?: "TagTypeResponseType";
  id: Scalars["String"]["output"];
  tagType: Scalars["String"]["output"];
  updatedDate: Scalars["String"]["output"];
};

export type TagTypeSubmissionType = {
  tagType: Scalars["String"]["input"];
};

export type TagWithFullQuestion = {
  __typename?: "TagWithFullQuestion";
  createdAt: Scalars["String"]["output"];
  deletedAt?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  questionCount: Scalars["Float"]["output"];
  questions?: Maybe<Array<QuestionWithAnswerAndCommentId>>;
  updatedAt?: Maybe<Scalars["String"]["output"]>;
  updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type TagsDetailsNodeResponseType = {
  __typename?: "TagsDetailsNodeResponseType";
  tagDetails: Array<TagDetailNodeType>;
};

export type TagsDetailsResponseType = {
  __typename?: "TagsDetailsResponseType";
  tagDetails: Array<TagDetailType>;
};

export type TagsResponseType = {
  __typename?: "TagsResponseType";
  tags: Array<Scalars["String"]["output"]>;
};

export type TagsTypeResponseType = {
  __typename?: "TagsTypeResponseType";
  tagTypes: Array<Scalars["String"]["output"]>;
};

export type Team = {
  __typename?: "Team";
  _id: Scalars["ID"]["output"];
  areaPath?: Maybe<Scalars["String"]["output"]>;
  createdDate?: Maybe<Scalars["Float"]["output"]>;
  creator?: Maybe<User>;
  creatorId: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  isDeleted: Scalars["Boolean"]["output"];
  lastUpdatedById?: Maybe<Scalars["String"]["output"]>;
  lastUpdatedDate?: Maybe<Scalars["Float"]["output"]>;
  linkedResources?: Maybe<Array<LinkedResource>>;
  memberCount: Scalars["Float"]["output"];
  members?: Maybe<Array<User>>;
  name: Scalars["String"]["output"];
  permissions?: Maybe<TeamPermissions>;
  product?: Maybe<ProductResponse>;
};

export type TeamPermissions = {
  __typename?: "TeamPermissions";
  delete: Scalars["Boolean"]["output"];
  edit: Scalars["Boolean"]["output"];
};

export type TeamQuizResult = {
  __typename?: "TeamQuizResult";
  answers: Array<Answer>;
  entityId: Scalars["String"]["output"];
  lastUpdated: Scalars["Float"]["output"];
  /** @deprecated Succeeded by lastUpdated. */
  lastUpdatedDate: Scalars["Float"]["output"];
  maxScore?: Maybe<Scalars["Float"]["output"]>;
  quiz: Quiz;
  quizType?: Maybe<Scalars["QuizType"]["output"]>;
  score?: Maybe<Scalars["Score"]["output"]>;
  team?: Maybe<Team>;
  teamId: Scalars["String"]["output"];
};

export type ToxicAnnotation = {
  __typename?: "ToxicAnnotation";
  severity: Scalars["String"]["output"];
  toxicType: Scalars["String"]["output"];
};

/** Update answer */
export type UpdateAnswerInput = {
  body: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
};

/** Update comment */
export type UpdateCommentInput = {
  body: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
};

/** Updates the common fields of a component */
export type UpdateComponent = {
  component_id: Scalars["Float"]["input"];
  description: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  tags: Array<Scalars["String"]["input"]>;
};

/** Update question data */
export type UpdateQuestionInput = {
  body?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  tags?: InputMaybe<Array<Scalars["String"]["input"]>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

/** Update tag data */
export type UpdateTagInput = {
  name: Scalars["String"]["input"];
  newName: Scalars["String"]["input"];
};

export enum Urgency {
  High = "HIGH",
  Low = "LOW",
}

export type User = {
  __typename?: "User";
  _id: Scalars["String"]["output"];
  contact?: Maybe<UserContactDetails>;
  email?: Maybe<Scalars["String"]["output"]>;
  enabled?: Maybe<Scalars["Boolean"]["output"]>;
  givenName?: Maybe<Scalars["String"]["output"]>;
  isDelegate: Scalars["Boolean"]["output"];
  isDesignLead: Scalars["Boolean"]["output"];
  isOwner: Scalars["Boolean"]["output"];
  /** @deprecated Succeeded by the `permissions` field on team, product, and L3 entity. */
  isOwnerOrDelegate?: Maybe<Scalars["Boolean"]["output"]>;
  isTechLead: Scalars["Boolean"]["output"];
  jobTitle?: Maybe<Scalars["String"]["output"]>;
  l3Entities?: Maybe<Array<L3EntityResponse>>;
  photo?: Maybe<Scalars["String"]["output"]>;
  products?: Maybe<Array<ProductResponse>>;
  roles: Array<Scalars["String"]["output"]>;
  suggestedTeamCount: Scalars["Float"]["output"];
  suggestedTeams?: Maybe<Array<SuggestedTeam>>;
  surname?: Maybe<Scalars["String"]["output"]>;
  teamCount: Scalars["Float"]["output"];
  teams?: Maybe<Array<Team>>;
  updatedAt?: Maybe<Scalars["String"]["output"]>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars["String"]["output"]>;
  username: Scalars["String"]["output"];
};

export type UserContactDetails = {
  __typename?: "UserContactDetails";
  email: Scalars["String"]["output"];
  slack?: Maybe<Scalars["String"]["output"]>;
  teams?: Maybe<Scalars["String"]["output"]>;
};

export type UsersWithSurvey = {
  __typename?: "UsersWithSurvey";
  hasCompletedSurvey: Scalars["Boolean"]["output"];
  surveyId: Scalars["String"]["output"];
  surveyName: Scalars["String"]["output"];
  surveyStatus: Scalars["Boolean"]["output"];
  userEmail: Scalars["String"]["output"];
  userId: Scalars["String"]["output"];
};

/** Represents the deployment of a version of a service against a platform, made on a specific date. */
export type YallaDeployment = {
  __typename?: "YallaDeployment";
  /** URI of the deployed artifact. */
  artifactUri: Scalars["String"]["output"];
  /** Creation time of the deployment. */
  createdAt: Scalars["DateTime"]["output"];
  /** A view of the release flow of the deployment and the statuses of each step. */
  deploymentSteps: Array<YallaDeploymentStep>;
  id: Scalars["ID"]["output"];
  /** Type of deployment. One of ['RELEASE', 'ROLLBACK']. */
  kind: YallaDeploymentType;
  /** Name of the platform against which the deployment is made. */
  platformName: Scalars["String"]["output"];
  /** Name of the service being deployed. */
  serviceName: Scalars["String"]["output"];
  /** Commit message of the source commit that triggered the deployment. e.g. "Merged PR 123456: Add new feature." */
  sourceCommitMessage: Scalars["String"]["output"];
  /** URL of the source commit that triggered the deployment. e.g. "https://dev.azure.com/bp-digital/DevOps-SRE/_git/accelerate-monorepo/commit/cefe15a9c35d832b1c18ecc25155a84c9226ff53" */
  sourceCommitUri: Scalars["String"]["output"];
  /** Status of deployment. One of ['NOTSTARTED', 'INPROGRESS', 'SUCCEEDED', 'FAILED', 'SUPERSEDED']. */
  status: YallaDeploymentStatus;
  /** Last update time of the deployment. */
  updatedAt: Scalars["DateTime"]["output"];
  /** Truncated version of the service deployed to the instance, comprising only the 'sha256:' prefix and the first 8 characters of the image SHA. */
  version: Scalars["YallaVersion"]["output"];
};

/** Status of either a deployment or an individual deployment step. One of ['NOTSTARTED', 'INPROGRESS', 'SUCCEEDED', 'FAILED', 'SUPERSEDED']. */
export enum YallaDeploymentStatus {
  Failed = "FAILED",
  Inprogress = "INPROGRESS",
  Notstarted = "NOTSTARTED",
  Succeeded = "SUCCEEDED",
  Superseded = "SUPERSEDED",
}

/** Represents a step within the deployment process, reflecting its release flow. */
export type YallaDeploymentStep = {
  __typename?: "YallaDeploymentStep";
  /** Environment of the deployment step. */
  environment: Scalars["String"]["output"];
  /** Status of deployment step. One of ['NOTSTARTED', 'INPROGRESS', 'SUCCEEDED', 'FAILED', 'SUPERSEDED']. */
  status: YallaDeploymentStatus;
};

/** Type of deployment made. One of ['RELEASE', 'ROLLBACK']. */
export enum YallaDeploymentType {
  Release = "RELEASE",
  Rollback = "ROLLBACK",
}

/** Represents an environment */
export type YallaEnvironment = {
  __typename?: "YallaEnvironment";
  /** List of instances in the environment */
  instances: Array<YallaServiceInstance>;
  name: Scalars["String"]["output"];
  platformName: Scalars["String"]["output"];
};

/** What the instance change corresponds to. One of ['DEPLOYMENT', 'CONFIGURATION_CHANGE']. */
export enum YallaInstanceChangeKind {
  ConfigurationChange = "CONFIGURATION_CHANGE",
  Deployment = "DEPLOYMENT",
}

/** Health status of instance. One of ['HEALTHY', 'PROGRESSING', 'DEGRADED', 'SUSPENDED', 'UNKNOWN', 'MISSING']. */
export enum YallaInstanceHealthStatus {
  Degraded = "DEGRADED",
  Healthy = "HEALTHY",
  Missing = "MISSING",
  Progressing = "PROGRESSING",
  Suspended = "SUSPENDED",
  Unknown = "UNKNOWN",
}

/** Sync status of instance. One of ['SYNCED', 'OUT_OF_SYNC', 'SYNCING', 'UNKNOWN']. */
export enum YallaInstanceSyncStatus {
  OutOfSync = "OUT_OF_SYNC",
  Synced = "SYNCED",
  Syncing = "SYNCING",
  Unknown = "UNKNOWN",
}

export type YallaIntegrationLinks = {
  __typename?: "YallaIntegrationLinks";
  /** Link to the service instance's Argo CD application. */
  argoCd?: Maybe<Scalars["String"]["output"]>;
  /** Link to the relevant source code repositories for the entity. */
  repositories: Array<Scalars["String"]["output"]>;
};

/** Yalla platform onto which services are deployed. */
export type YallaPlatform = {
  __typename?: "YallaPlatform";
  /** Deployments made on this platform. */
  deployments: Array<YallaDeployment>;
  /** List of environments available on this platform */
  environments: Array<YallaEnvironment>;
  /** Links to external Yalla service integrations. */
  integrationLinks?: Maybe<YallaIntegrationLinks>;
  /** Name of the platform. */
  name: Scalars["ID"]["output"];
  /** List of products (groupings of services) available on this platform. */
  products: Array<YallaProduct>;
};

/** Represents a product, a grouping of services deployed to a singular platform. */
export type YallaProduct = {
  __typename?: "YallaProduct";
  /** Deployments made to services associated with this product. */
  deployments: Array<YallaDeployment>;
  id: Scalars["ID"]["output"];
  /** Name of the product. */
  name: Scalars["String"]["output"];
  /** Name of the platform on which the product's services are deployed. */
  platformName: Scalars["String"]["output"];
  /** List of services the product group. */
  services: Array<YallaService>;
};

export type YallaResponse = {
  __typename?: "YallaResponse";
  msg: Scalars["String"]["output"];
  uid?: Maybe<Scalars["String"]["output"]>;
};

export type YallaService = {
  __typename?: "YallaService";
  /** Deployments made to this service. */
  deployments: Array<YallaDeployment>;
  id: Scalars["ID"]["output"];
  /** List of instances of the service. */
  instances: Array<YallaServiceInstance>;
  /** Links to external Yalla service integrations. */
  integrationLinks?: Maybe<YallaIntegrationLinks>;
  /** Name of the service. */
  name: Scalars["String"]["output"];
  /** Name of the platform on which the service is deployed. */
  platformName: Scalars["String"]["output"];
  /** Date of the most recent update to the service across all instances. */
  updatedAt?: Maybe<Scalars["String"]["output"]>;
};

export type YallaServiceInstance = {
  __typename?: "YallaServiceInstance";
  /** URI of the artifact deployed to the instance, as defined in the service's deployment. */
  artifactUri: Scalars["String"]["output"];
  /** The N most recent changes made to the service instance, which can be both deployments andother updates to the repository. */
  changes: Array<YallaServiceInstanceChange>;
  /** Most recent deployment made to the instance. */
  deployment?: Maybe<YallaDeployment>;
  /** Environment in which the service instance is deployed. Potential environments aredefined in the platform's release flow. */
  environment: Scalars["String"]["output"];
  /** Health status of the service instance. Currently this could be anything,but this will become an enum once we have a better understanding of the possible values. */
  health: YallaInstanceHealthStatus;
  /** The Yalla repository commit SHAs corresponding to each historicchange to the service instance, in reverse chronological order. */
  historyCommitShas: Array<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  /** Links to external Yalla service integrations. */
  integrationLinks?: Maybe<YallaIntegrationLinks>;
  /** The last update made to the service instance. This is not necessarily adeployment, but rather the reason behind Argo's last sync of the service instance. */
  mostRecentUpdate?: Maybe<YallaServiceInstanceRevision>;
  /** Name label of the service instance. */
  name: Scalars["String"]["output"];
  /** Name of the platform to which the instance is deployed. */
  platformName: Scalars["String"]["output"];
  /** The commit SHA of the yalla repo that is currently deployed by Argo CD */
  revisionCommitSha?: Maybe<Scalars["String"]["output"]>;
  /** Name of the service of which this is an instance. */
  serviceName: Scalars["String"]["output"];
  /** Sync status of the service instance. Currently this could be anything,but this will become an enum once we have a better understanding of the possible values. */
  syncStatus: YallaInstanceSyncStatus;
  /** Timestamp of the most recent deployment to the instance. */
  updatedAt?: Maybe<Scalars["String"]["output"]>;
  /** Truncated version of the service deployed to the instance, comprising only the 'sha256:' prefix and the first 8 characters of the image SHA. */
  version: Scalars["YallaVersion"]["output"];
};

export type YallaServiceInstanceChangesArgs = {
  skip?: Scalars["Int"]["input"];
  take?: Scalars["Int"]["input"];
};

export type YallaServiceInstanceChange = {
  __typename?: "YallaServiceInstanceChange";
  /** The author of the change. */
  author?: Maybe<User>;
  /** The commit's hash. */
  commit: Scalars["String"]["output"];
  /** The email of the author of the commit if the author is a user else null in the case of a commit made by the Yalla service. */
  commitAuthorEmail?: Maybe<Scalars["String"]["output"]>;
  /** URL to the commit in Git. */
  commitUrl?: Maybe<Scalars["String"]["output"]>;
  /** Date the change was committed. */
  date: Scalars["DateTime"]["output"];
  /** Commit message associated with the change. */
  description?: Maybe<Scalars["String"]["output"]>;
  /** The environment the change was made in. */
  environment: Scalars["String"]["output"];
  /** Classification of the change. One of ['DEPLOYMENT', 'CONFIGURATION_CHANGE']. */
  kind: YallaInstanceChangeKind;
  /** The platform the change was made on. */
  platformName: Scalars["String"]["output"];
  /** The service the change was made on. */
  serviceName: Scalars["String"]["output"];
};

export type YallaServiceInstanceRevision = {
  __typename?: "YallaServiceInstanceRevision";
  /** Date on which the sync event for the revision occurred. */
  date: Scalars["String"]["output"];
  /** Commit message associated with the change to the revision's source repository. */
  message: Scalars["String"]["output"];
};

export type Join__ContextArgument = {
  context: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  selection: Scalars["join__FieldValue"]["input"];
  type: Scalars["String"]["input"];
};

export enum Join__Graph {
  Axel = "AXEL",
  Codehub = "CODEHUB",
  Faq = "FAQ",
  Metadata = "METADATA",
  Metrics = "METRICS",
  Moderation = "MODERATION",
  Profiles = "PROFILES",
  Quizzes = "QUIZZES",
  Support = "SUPPORT",
  Users = "USERS",
  Yalla = "YALLA",
}

export enum Link__Purpose {
  /** `EXECUTION` features provide metadata necessary for operation execution. */
  Execution = "EXECUTION",
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  Security = "SECURITY",
}

export type ListChatsQueryVariables = Exact<{ [key: string]: never }>;

export type ListChatsQuery = {
  __typename?: "Query";
  listChats: Array<{ __typename?: "Chat"; name: string; chatId: string; lastUpdate?: string | null }>;
};

export type OpenChatQueryVariables = Exact<{
  chatId: Scalars["String"]["input"];
}>;

export type OpenChatQuery = {
  __typename?: "Query";
  openChat: {
    __typename?: "ChatHistory";
    chatId: string;
    history: Array<{
      __typename?: "ChatResponse";
      auditId?: string | null;
      request?: string | null;
      answer?: string | null;
      kendraSearch?: string | null;
      probablePublicData: boolean;
      sourceDocs: Array<{ __typename?: "SourceDoc"; title: string; url: string; score?: number | null }>;
    }>;
  };
};

export type ChatRequestQueryVariables = Exact<{
  chatMessage: ChatMessage;
}>;

export type ChatRequestQuery = {
  __typename?: "Query";
  chatRequest: {
    __typename?: "ChatResponse";
    auditId?: string | null;
    request?: string | null;
    answer?: string | null;
    chatId?: string | null;
    chatName?: string | null;
    kendraSearch?: string | null;
    probablePublicData: boolean;
    keywordSearchData?: {
      __typename?: "KeywordSearch";
      isKeywordSearch: boolean;
      recommendations: Array<string>;
    } | null;
    sourceDocs: Array<{ __typename?: "SourceDoc"; title: string; url: string; score?: number | null }>;
  };
};

export type GetSuggestionsQueryVariables = Exact<{
  requestSuggestions: RequestSuggestions;
}>;

export type GetSuggestionsQuery = {
  __typename?: "Query";
  getSuggestions: { __typename?: "SuggestedQuestions"; questions: Array<string> };
};

export type RetrieveSuggestedTagsQueryVariables = Exact<{
  context: Scalars["String"]["input"];
}>;

export type RetrieveSuggestedTagsQuery = {
  __typename?: "Query";
  retrieveSuggestedTags: { __typename?: "SuggestedTags"; tags: Array<string> };
};

export type RenameChatMutationVariables = Exact<{
  renameMessage: RenameMessage;
}>;

export type RenameChatMutation = {
  __typename?: "Mutation";
  renameChat: { __typename?: "Chat"; name: string; chatId: string; lastUpdate?: string | null };
};

export type DeleteChatMutationVariables = Exact<{
  chatId: Scalars["String"]["input"];
}>;

export type DeleteChatMutation = {
  __typename?: "Mutation";
  deleteChat: { __typename?: "Chat"; name: string; chatId: string };
};

export type NewChatMutationVariables = Exact<{ [key: string]: never }>;

export type NewChatMutation = { __typename?: "Mutation"; newChat: { __typename?: "Chat"; chatId: string } };

export type SubmitAxelIssueMutationVariables = Exact<{
  submitIssue: SubmitIssue;
}>;

export type SubmitAxelIssueMutation = {
  __typename?: "Mutation";
  submitAxelIssue: { __typename?: "SubmitIssueResult"; auditId: string };
};

export type AllComponentsAndTagsQueryVariables = Exact<{ [key: string]: never }>;

export type AllComponentsAndTagsQuery = {
  __typename?: "Query";
  getAllCs: Array<{
    __typename?: "CsResponse";
    id: number;
    name: string;
    description: string;
    repoUrl: string;
    readmeUrl?: string | null;
    authorEmail?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    tags?: Array<string> | null;
  }>;
  getAllSSAs: Array<{
    __typename?: "SsaResponse";
    id: number;
    name: string;
    description: string;
    specFileUrl: string;
    authorEmail?: string | null;
    authenticationType?: string | null;
    authenticationCredential?: string | null;
    authorizationHeaderName?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    tags?: Array<string> | null;
  }>;
  getAllTagDetails: {
    __typename?: "TagsDetailsResponseType";
    tagDetails: Array<{
      __typename?: "TagDetailType";
      id: string;
      tagName: string;
      tagType?: string | null;
      kHubOverviewPage?: string | null;
      icon?: string | null;
      highlighted?: boolean | null;
      description?: string | null;
    }>;
  };
};

export type AllUserComponentsQueryVariables = Exact<{ [key: string]: never }>;

export type AllUserComponentsQuery = {
  __typename?: "Query";
  allUserComponents: Array<{
    __typename?: "ComponentResponse";
    id: number;
    name: string;
    description: string;
    authorEmail?: string | null;
    status?: string | null;
    componentType: string;
    createdAt?: string | null;
    updatedAt?: string | null;
    tags?: Array<string> | null;
  }>;
  getAllTagDetails: {
    __typename?: "TagsDetailsResponseType";
    tagDetails: Array<{
      __typename?: "TagDetailType";
      id: string;
      tagName: string;
      tagType?: string | null;
      kHubOverviewPage?: string | null;
      icon?: string | null;
      highlighted?: boolean | null;
      description?: string | null;
    }>;
  };
};

export type DeleteComponentMutationVariables = Exact<{
  data: DeleteComponent;
}>;

export type DeleteComponentMutation = {
  __typename?: "Mutation";
  deleteComponent: {
    __typename?: "ComponentResponse";
    id: number;
    name: string;
    description: string;
    authorEmail?: string | null;
    status?: string | null;
    componentType: string;
    tags?: Array<string> | null;
  };
};

export type UpdateComponentMutationVariables = Exact<{
  data: UpdateComponent;
}>;

export type UpdateComponentMutation = {
  __typename?: "Mutation";
  updateComponent: {
    __typename?: "ComponentResponse";
    id: number;
    name: string;
    description: string;
    authorEmail?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    componentType: string;
    tags?: Array<string> | null;
  };
};

export type ExecuteSsaMutationVariables = Exact<{
  data: SsaExecute;
}>;

export type ExecuteSsaMutation = {
  __typename?: "Mutation";
  executeSsa: { __typename?: "SsaExecuteResponse"; code: string };
};

export type SubmitCsMutationVariables = Exact<{
  data: CsCommand;
}>;

export type SubmitCsMutation = {
  __typename?: "Mutation";
  submitCs: {
    __typename?: "CsResponse";
    id: number;
    name: string;
    description: string;
    repoUrl: string;
    readmeUrl?: string | null;
    authorEmail?: string | null;
    tags?: Array<string> | null;
  };
};

export type SubmitSsaMutationVariables = Exact<{
  data: SsaCommand;
}>;

export type SubmitSsaMutation = {
  __typename?: "Mutation";
  submitSsa: {
    __typename?: "SsaResponse";
    id: number;
    name: string;
    description: string;
    authenticationType?: string | null;
    authenticationCredential?: string | null;
    authorizationHeaderName?: string | null;
    tags?: Array<string> | null;
  };
};

export type ValidateSsaMutationVariables = Exact<{
  data: SsaValidate;
}>;

export type ValidateSsaMutation = {
  __typename?: "Mutation";
  validateSsa: {
    __typename?: "SsaValidationResponse";
    code: number;
    preview: string;
    message: string;
    errors?: Array<string> | null;
  };
};

export type RetrieveComponentQueryVariables = Exact<{
  name: Scalars["String"]["input"];
}>;

export type RetrieveComponentQuery = {
  __typename?: "Query";
  retrieveComponent: {
    __typename?: "ComponentResponse";
    id: number;
    name: string;
    description: string;
    authorEmail?: string | null;
    status?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    componentType: string;
    tags?: Array<string> | null;
    repoUrl: string;
    readmeUrl?: string | null;
    userParameters?: Array<{
      __typename?: "SsaArguments";
      name: string;
      arg_type: string;
      description?: string | null;
      http_parameter_type: string;
      default?: string | null;
      options?: string | null;
      max_length?: number | null;
      max_value?: string | null;
      min_length?: number | null;
      min_value?: string | null;
      hidden?: boolean | null;
      regex?: string | null;
      regex_hint?: string | null;
      whats_this_link?: string | null;
      options_api?: string | null;
      info_link?: { __typename?: "InfoLink"; url: string; placeholder_text?: string | null } | null;
    }> | null;
  };
};

export type RetrieveSsaOptionsQueryVariables = Exact<{
  data: SsaOptionsApiRequest;
}>;

export type RetrieveSsaOptionsQuery = {
  __typename?: "Query";
  retrieveSsaOptions: { __typename?: "SsaOptionsApiResponse"; options: Array<string>; parameterName: string };
};

export type AllRelatedComponentsQueryVariables = Exact<{
  data: RelatedTags;
}>;

export type AllRelatedComponentsQuery = {
  __typename?: "Query";
  allRelatedComponents: Array<{
    __typename?: "ComponentResponse";
    id: number;
    name: string;
    description: string;
    authorEmail?: string | null;
    status?: string | null;
    componentType: string;
    tags?: Array<string> | null;
  }>;
};

export type AllUserComponentsProfilesQueryVariables = Exact<{ [key: string]: never }>;

export type AllUserComponentsProfilesQuery = {
  __typename?: "Query";
  allUserComponents: Array<{
    __typename?: "ComponentResponse";
    id: number;
    name: string;
    description: string;
    authorEmail?: string | null;
    status?: string | null;
    componentType: string;
    createdAt?: string | null;
    updatedAt?: string | null;
    tags?: Array<string> | null;
  }>;
  allTags: { __typename?: "TagsResponseType"; tags: Array<string> };
};

export type AllExistingComponentsQueryVariables = Exact<{ [key: string]: never }>;

export type AllExistingComponentsQuery = {
  __typename?: "Query";
  allExistingComponents: Array<{
    __typename?: "ComponentResponse";
    id: number;
    name: string;
    description: string;
    authorEmail?: string | null;
    status?: string | null;
    componentType: string;
    tags?: Array<string> | null;
  }>;
};

export type ApproveComponentMutationVariables = Exact<{
  data: ApproveComponent;
}>;

export type ApproveComponentMutation = {
  __typename?: "Mutation";
  approveComponent: {
    __typename?: "ComponentResponse";
    id: number;
    name: string;
    description: string;
    authorEmail?: string | null;
    status?: string | null;
    componentType: string;
    tags?: Array<string> | null;
  };
};

export type RejectComponentMutationVariables = Exact<{
  data: RejectComponent;
}>;

export type RejectComponentMutation = {
  __typename?: "Mutation";
  rejectComponent: {
    __typename?: "ComponentResponse";
    id: number;
    name: string;
    description: string;
    authorEmail?: string | null;
    status?: string | null;
    componentType: string;
    tags?: Array<string> | null;
  };
};

export type GetApprovedQuestionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetApprovedQuestionsQuery = {
  __typename?: "Query";
  approvedQuestions: Array<{
    __typename?: "QuestionWithAnswerAndCommentId";
    body: string;
    author: string;
    title: string;
    tags?: Array<string> | null;
    answers?: Array<string> | null;
    comments?: Array<string> | null;
    createdAt: string;
    deletedAt?: string | null;
    id: string;
    status: number;
    updatedAt?: string | null;
    updatedBy?: string | null;
    upvotes: number;
  }>;
};

export type AllUserQuestionsQueryVariables = Exact<{
  hideInReview?: InputMaybe<Scalars["Boolean"]["input"]>;
  hideApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  hideRejected?: InputMaybe<Scalars["Boolean"]["input"]>;
  user: Scalars["String"]["input"];
}>;

export type AllUserQuestionsQuery = {
  __typename?: "Query";
  userQuestions: Array<{
    __typename?: "QuestionWithAnswerAndCommentId";
    body: string;
    author: string;
    title: string;
    tags?: Array<string> | null;
    answers?: Array<string> | null;
    comments?: Array<string> | null;
    createdAt: string;
    deletedAt?: string | null;
    id: string;
    status: number;
    updatedAt?: string | null;
    updatedBy?: string | null;
    upvotes: number;
  }>;
  approvedQuestions: Array<{
    __typename?: "QuestionWithAnswerAndCommentId";
    body: string;
    author: string;
    title: string;
    tags?: Array<string> | null;
    answers?: Array<string> | null;
    comments?: Array<string> | null;
    createdAt: string;
    deletedAt?: string | null;
    id: string;
    status: number;
    updatedAt?: string | null;
    updatedBy?: string | null;
    upvotes: number;
  }>;
};

export type QuestionsQueryVariables = Exact<{
  hideInReview?: InputMaybe<Scalars["Boolean"]["input"]>;
  hideApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  hideRejected?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type QuestionsQuery = {
  __typename?: "Query";
  questions: Array<{
    __typename?: "QuestionWithAnswerAndCommentId";
    body: string;
    author: string;
    title: string;
    tags?: Array<string> | null;
    answers?: Array<string> | null;
    comments?: Array<string> | null;
    createdAt: string;
    deletedAt?: string | null;
    id: string;
    status: number;
    updatedAt?: string | null;
    updatedBy?: string | null;
    upvotes: number;
  }>;
};

export type GetTagsQueryVariables = Exact<{
  hideInReviewQuestions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hideApprovedQuestions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hideRejectedQuestions?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GetTagsQuery = {
  __typename?: "Query";
  tags: Array<{ __typename?: "TagWithFullQuestion"; name: string; questionCount: number }>;
};

export type AllGlobalTagsQueryVariables = Exact<{ [key: string]: never }>;

export type AllGlobalTagsQuery = {
  __typename?: "Query";
  allTags: { __typename?: "TagsResponseType"; tags: Array<string> };
};

export type GetTagQueryVariables = Exact<{
  name: Scalars["String"]["input"];
  hideInReviewQuestions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hideApprovedQuestions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hideRejectedQuestions?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GetTagQuery = {
  __typename?: "Query";
  tag?: {
    __typename?: "TagWithFullQuestion";
    name: string;
    questionCount: number;
    questions?: Array<{
      __typename?: "QuestionWithAnswerAndCommentId";
      answers?: Array<string> | null;
      author: string;
      body: string;
      comments?: Array<string> | null;
      createdAt: string;
      deletedAt?: string | null;
      id: string;
      status: number;
      tags?: Array<string> | null;
      title: string;
      updatedAt?: string | null;
      updatedBy?: string | null;
      upvotes: number;
    }> | null;
  } | null;
};

export type AddTagMutationVariables = Exact<{
  name: Scalars["String"]["input"];
}>;

export type AddTagMutation = {
  __typename?: "Mutation";
  addTag: {
    __typename?: "Tag";
    createdAt: string;
    deletedAt?: string | null;
    name: string;
    questionCount: number;
    questions?: Array<string> | null;
    updatedAt?: string | null;
    updatedBy?: string | null;
  };
};

export type AddQuestionMutationVariables = Exact<{
  data: AddQuestionInput;
}>;

export type AddQuestionMutation = {
  __typename?: "Mutation";
  addQuestion: {
    __typename?: "QuestionWithAnswerAndCommentId";
    answers?: Array<string> | null;
    author: string;
    body: string;
    comments?: Array<string> | null;
    createdAt: string;
    deletedAt?: string | null;
    id: string;
    tags?: Array<string> | null;
    status: number;
    title: string;
    updatedAt?: string | null;
    updatedBy?: string | null;
    upvotes: number;
  };
};

export type QuestionQueryVariables = Exact<{
  questionId: Scalars["String"]["input"];
}>;

export type QuestionQuery = {
  __typename?: "Query";
  question?: {
    __typename?: "QuestionWithFullAnswerAndComments";
    id: string;
    createdAt: string;
    updatedBy?: string | null;
    updatedAt?: string | null;
    author: string;
    title: string;
    body: string;
    tags?: Array<string> | null;
    userHasUpvoted?: boolean | null;
    upvotes: number;
    answers?: Array<{
      __typename?: "AnswerWithQuestionId";
      id: string;
      createdAt: string;
      updatedAt?: string | null;
      updatedBy?: string | null;
      deletedAt?: string | null;
      author: string;
      body: string;
      helpfulVotes: number;
      unhelpfulVotes: number;
      question?: string | null;
      userVotedHelpful: boolean;
      userVotedUnhelpful: boolean;
    }> | null;
    comments?: Array<{
      __typename?: "CommentWithQuestionId";
      id: string;
      createdAt: string;
      updatedAt?: string | null;
      updatedBy?: string | null;
      deletedAt?: string | null;
      author: string;
      body: string;
      question?: string | null;
    }> | null;
  } | null;
};

export type UpdateQuestionMutationVariables = Exact<{
  data: UpdateQuestionInput;
}>;

export type UpdateQuestionMutation = {
  __typename?: "Mutation";
  updateQuestion: {
    __typename?: "QuestionWithAnswerAndCommentId";
    body: string;
    tags?: Array<string> | null;
    title: string;
    id: string;
  };
};

export type ApproveQuestionMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type ApproveQuestionMutation = {
  __typename?: "Mutation";
  approveQuestion?: { __typename?: "QuestionWithAnswerAndCommentId"; id: string; status: number } | null;
};

export type RejectQuestionMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type RejectQuestionMutation = {
  __typename?: "Mutation";
  rejectQuestion?: { __typename?: "QuestionWithAnswerAndCommentId"; id: string; status: number } | null;
};

export type ResetQuestionStatusMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type ResetQuestionStatusMutation = {
  __typename?: "Mutation";
  resetQuestionStatus?: { __typename?: "QuestionWithAnswerAndCommentId"; id: string; status: number } | null;
};

export type DeleteQuestionMutationVariables = Exact<{
  deleteQuestionId: Scalars["String"]["input"];
}>;

export type DeleteQuestionMutation = {
  __typename?: "Mutation";
  deleteQuestion?: { __typename?: "QuestionWithAnswerAndCommentId"; id: string; deletedAt?: string | null } | null;
};

export type AddAnswerMutationVariables = Exact<{
  data: AddAnswerInput;
}>;

export type AddAnswerMutation = {
  __typename?: "Mutation";
  addAnswer: {
    __typename?: "AnswerWithQuestionId";
    author: string;
    body: string;
    createdAt: string;
    deletedAt?: string | null;
    helpfulVotes: number;
    id: string;
    question?: string | null;
    unhelpfulVotes: number;
    updatedAt?: string | null;
    updatedBy?: string | null;
    userVotedHelpful: boolean;
    userVotedUnhelpful: boolean;
  };
};

export type UpdateAnswerMutationVariables = Exact<{
  data: UpdateAnswerInput;
}>;

export type UpdateAnswerMutation = {
  __typename?: "Mutation";
  updateAnswer?: {
    __typename?: "AnswerWithQuestionId";
    author: string;
    body: string;
    createdAt: string;
    deletedAt?: string | null;
    id: string;
    helpfulVotes: number;
    question?: string | null;
    unhelpfulVotes: number;
    updatedAt?: string | null;
    updatedBy?: string | null;
    userVotedHelpful: boolean;
    userVotedUnhelpful: boolean;
  } | null;
};

export type DeleteAnswerMutationVariables = Exact<{
  deleteAnswerId: Scalars["String"]["input"];
}>;

export type DeleteAnswerMutation = {
  __typename?: "Mutation";
  deleteAnswer?: { __typename?: "AnswerWithQuestionId"; body: string; deletedAt?: string | null } | null;
};

export type AddCommentMutationVariables = Exact<{
  data: AddCommentInput;
}>;

export type AddCommentMutation = {
  __typename?: "Mutation";
  addComment: { __typename?: "CommentWithQuestionId"; id: string };
};

export type DeleteCommentMutationVariables = Exact<{
  deleteCommentId: Scalars["String"]["input"];
}>;

export type DeleteCommentMutation = {
  __typename?: "Mutation";
  deleteComment?: { __typename?: "CommentWithQuestionId"; id: string } | null;
};

export type UpdateCommentMutationVariables = Exact<{
  data: UpdateCommentInput;
}>;

export type UpdateCommentMutation = {
  __typename?: "Mutation";
  updateComment: { __typename?: "CommentWithQuestionId"; id: string };
};

export type UpvoteQuestionMutationVariables = Exact<{
  questionId: Scalars["String"]["input"];
}>;

export type UpvoteQuestionMutation = {
  __typename?: "Mutation";
  upvoteQuestion: { __typename?: "QuestionWithAnswerAndCommentId"; upvotes: number; userHasUpvoted: boolean };
};

export type RemoveQuestionUpvoteMutationVariables = Exact<{
  questionId: Scalars["String"]["input"];
}>;

export type RemoveQuestionUpvoteMutation = {
  __typename?: "Mutation";
  removeQuestionUpvote: { __typename?: "QuestionWithAnswerAndCommentId"; upvotes: number; userHasUpvoted: boolean };
};

export type MarkAnswerHelpfulMutationVariables = Exact<{
  markAnswerHelpfulId: Scalars["String"]["input"];
}>;

export type MarkAnswerHelpfulMutation = {
  __typename?: "Mutation";
  markAnswerHelpful?: {
    __typename?: "AnswerWithQuestionId";
    id: string;
    helpfulVotes: number;
    unhelpfulVotes: number;
    userVotedHelpful: boolean;
    userVotedUnhelpful: boolean;
  } | null;
};

export type MarkAnswerUnhelpfulMutationVariables = Exact<{
  markAnswerUnhelpfulId: Scalars["String"]["input"];
}>;

export type MarkAnswerUnhelpfulMutation = {
  __typename?: "Mutation";
  markAnswerUnhelpful?: {
    __typename?: "AnswerWithQuestionId";
    id: string;
    helpfulVotes: number;
    unhelpfulVotes: number;
    userVotedHelpful: boolean;
    userVotedUnhelpful: boolean;
  } | null;
};

export type UnmarkAnswerHelpfulMutationVariables = Exact<{
  unmarkAnswerHelpfulId: Scalars["String"]["input"];
}>;

export type UnmarkAnswerHelpfulMutation = {
  __typename?: "Mutation";
  unmarkAnswerHelpful?: {
    __typename?: "AnswerWithQuestionId";
    id: string;
    helpfulVotes: number;
    unhelpfulVotes: number;
    userVotedHelpful: boolean;
    userVotedUnhelpful: boolean;
  } | null;
};

export type UnmarkAnswerUnhelpfulMutationVariables = Exact<{
  unmarkAnswerUnhelpfulId: Scalars["String"]["input"];
}>;

export type UnmarkAnswerUnhelpfulMutation = {
  __typename?: "Mutation";
  unmarkAnswerUnhelpful?: {
    __typename?: "AnswerWithQuestionId";
    unhelpfulVotes: number;
    userVotedHelpful: boolean;
    userVotedUnhelpful: boolean;
    id: string;
    helpfulVotes: number;
  } | null;
};

export type GetTagDetailsQueryVariables = Exact<{
  tags: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type GetTagDetailsQuery = {
  __typename?: "Query";
  getTagDetails: {
    __typename?: "TagsDetailsResponseType";
    tagDetails: Array<{
      __typename?: "TagDetailType";
      id: string;
      tagName: string;
      tagType?: string | null;
      kHubOverviewPage?: string | null;
      icon?: string | null;
      highlighted?: boolean | null;
      description?: string | null;
    }>;
  };
};

export type GetResourcesForTagQueryVariables = Exact<{
  tag: Scalars["String"]["input"];
}>;

export type GetResourcesForTagQuery = {
  __typename?: "Query";
  getResourcesForTag: {
    __typename?: "ResourcesResponseType";
    resources: Array<{
      __typename?: "ResourceType";
      sourceId: string;
      resourceType: string;
      resourceName?: string | null;
      tags?: Array<string> | null;
    }>;
    relatedTags?: Array<{
      __typename?: "TagDetailType";
      tagName: string;
      tagType?: string | null;
      description?: string | null;
      icon?: string | null;
    }> | null;
  };
};

export type GetTagDetailsAndRelatedTagsQueryVariables = Exact<{
  tags: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type GetTagDetailsAndRelatedTagsQuery = {
  __typename?: "Query";
  getRelatedTagDetails: {
    __typename?: "TagsDetailsNodeResponseType";
    tagDetails: Array<{
      __typename?: "TagDetailNodeType";
      id: string;
      tagName: string;
      tagType?: string | null;
      kHubOverviewPage?: string | null;
      highlighted?: boolean | null;
      description?: string | null;
      icon?: string | null;
      relatedTags?: Array<{
        __typename?: "TagDetailType";
        id: string;
        tagName: string;
        tagType?: string | null;
        kHubOverviewPage?: string | null;
        highlighted?: boolean | null;
        description?: string | null;
        icon?: string | null;
      }> | null;
    }>;
  };
};

export type GetAllTagDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllTagDetailsQuery = {
  __typename?: "Query";
  getAllTagDetails: {
    __typename?: "TagsDetailsResponseType";
    tagDetails: Array<{
      __typename?: "TagDetailType";
      id: string;
      tagName: string;
      tagType?: string | null;
      kHubOverviewPage?: string | null;
      icon?: string | null;
      highlighted?: boolean | null;
      description?: string | null;
    }>;
  };
};

export type DeleteGlobalTagMutationVariables = Exact<{
  tag: Scalars["String"]["input"];
}>;

export type DeleteGlobalTagMutation = {
  __typename?: "Mutation";
  deleteGlobalTag: { __typename?: "TagResponseType"; tagName: string; tagType?: string | null };
};

export type AddGlobalTagMutationVariables = Exact<{
  command: TagSubmissionType;
}>;

export type AddGlobalTagMutation = {
  __typename?: "Mutation";
  addGlobalTag: {
    __typename?: "TagResponseType";
    tagName: string;
    tagType?: string | null;
    description?: string | null;
    icon?: string | null;
    highlighted?: boolean | null;
    kHubOverviewPage?: string | null;
  };
};

export type AddTagTypeMutationVariables = Exact<{
  tagType: Scalars["String"]["input"];
}>;

export type AddTagTypeMutation = {
  __typename?: "Mutation";
  addTagType: { __typename?: "TagTypeResponseType"; tagType: string; id: string; updatedDate: string };
};

export type GetAllTagTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllTagTypesQuery = {
  __typename?: "Query";
  getAllTagTypes: Array<{ __typename?: "AllTagTypesResponseType"; tagType: string; id: string; updatedDate: string }>;
};

export type DeleteTagTypeMutationVariables = Exact<{
  tagType: Scalars["String"]["input"];
}>;

export type DeleteTagTypeMutation = {
  __typename?: "Mutation";
  deleteUnusedTagType: { __typename?: "TagTypeResponseType"; tagType: string; id: string; updatedDate: string };
};

export type GetBasicAccelerateProductQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetBasicAccelerateProductQuery = {
  __typename?: "Query";
  product: { __typename?: "ProductResponse" } & {
    " $fragmentRefs"?: { BasicProductFieldsFragment: BasicProductFieldsFragment };
  };
};

export type BasicProductFieldsFragment = {
  __typename?: "ProductResponse";
  id: string;
  name: string;
  description?: string | null;
  memberIds: Array<string>;
  ownerId?: string | null;
  designLeadId?: string | null;
  techLeadId?: string | null;
  l3EntityUid?: string | null;
  permissions: { __typename?: "ProductPermissions"; edit: boolean; delete: boolean; linkage: boolean };
  delegates?: Array<{ __typename?: "User"; email?: string | null }> | null;
  newCapabilityResult?: {
    __typename?: "CapabilityResult";
    score?: number | null;
    lastUpdated?: number | null;
    productId: string;
  } | null;
  yallaPlatform?: { __typename?: "YallaPlatform"; name: string } | null;
} & { " $fragmentName"?: "BasicProductFieldsFragment" };

export type DeleteProductMutationVariables = Exact<{
  deleteProductId: Scalars["String"]["input"];
}>;

export type DeleteProductMutation = {
  __typename?: "Mutation";
  deleteProduct: { __typename?: "DeleteProductResponse"; isDeleted?: boolean | null };
};

export type ProductQuizAssessmentsQueryQueryVariables = Exact<{ [key: string]: never }>;

export type ProductQuizAssessmentsQueryQuery = {
  __typename?: "Query";
  products: Array<{
    __typename?: "ProductResponse";
    id: string;
    name: string;
    numberOfWorkers: number;
    newCapabilityResult?: {
      __typename?: "CapabilityResult";
      productId: string;
      quizResults: Array<{
        __typename?: "QuizResult";
        lastUpdated: number;
        score?: any | null;
        maxScore?: number | null;
        quiz: { __typename?: "Quiz"; slug: string };
      }>;
    } | null;
  }>;
};

export type GetUserL3EntitiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserL3EntitiesQuery = {
  __typename?: "Query";
  userL3Entities: Array<{
    __typename?: "L3EntityResponse";
    serviceNowId: string;
    name: string;
    tier: { __typename?: "L3TierResponse"; name: string; parentName: string };
  }>;
};

export type SearchL3EntitiesQueryVariables = Exact<{
  search: Scalars["String"]["input"];
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  page: Scalars["Int"]["input"];
}>;

export type SearchL3EntitiesQuery = {
  __typename?: "Query";
  searchL3Entities: Array<{
    __typename?: "L3EntityResponse";
    serviceNowId: string;
    name: string;
    tier: { __typename?: "L3TierResponse"; name: string; parentName: string };
  }>;
};

export type UserFieldsFragment = {
  __typename?: "User";
  email?: string | null;
  givenName?: string | null;
  surname?: string | null;
  jobTitle?: string | null;
  userId?: string | null;
  photo?: string | null;
  contact?: { __typename?: "UserContactDetails"; email: string; slack?: string | null; teams?: string | null } | null;
} & { " $fragmentName"?: "UserFieldsFragment" };

export type AlertFieldsFragment = {
  __typename?: "Alert";
  id: string;
  dateRaised: number;
  dateUpdated: number;
  alertStatus: AlertStatusType;
  description: string;
  resolutionNote?: string | null;
} & (
  | {
      __typename?: "Alert";
      raisedBy?: ({ __typename?: "User" } & { " $fragmentRefs"?: { UserFieldsFragment: UserFieldsFragment } }) | null;
    }
  | { __typename?: "Alert"; raisedBy?: never }
) & { " $fragmentName"?: "AlertFieldsFragment" };

export type DeleteAlertMutationVariables = Exact<{
  deleteAlertId: Scalars["String"]["input"];
}>;

export type DeleteAlertMutation = {
  __typename?: "Mutation";
  deleteAlert: { __typename?: "DeleteAlert"; isDeleted: boolean };
};

export type GetAccelerateProductsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAccelerateProductsQuery = {
  __typename?: "Query";
  currentUserProfile: {
    __typename?: "User";
    products?: Array<
      { __typename?: "ProductResponse" } & {
        " $fragmentRefs"?: { BasicProductFieldsFragment: BasicProductFieldsFragment };
      }
    > | null;
  };
};

export type GetL3EntityQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetL3EntityQuery = {
  __typename?: "Query";
  l3Entity: {
    __typename?: "L3EntityResponse";
    serviceNowId: string;
    name: string;
    description: string;
    tier: { __typename?: "L3TierResponse"; name: string; parentName: string };
    opsGroups: Array<{
      __typename?: "OpsGroup";
      id: string;
      name: string;
      users: Array<{
        __typename?: "User";
        email?: string | null;
        givenName?: string | null;
        surname?: string | null;
        jobTitle?: string | null;
        updatedAt?: string | null;
        userId?: string | null;
      }>;
      supportGroup?: { __typename?: "SupportGroup"; name: string } | null;
    }>;
  };
};

export type GetProductImprovementGoalsQueryVariables = Exact<{
  productId: Scalars["String"]["input"];
}>;

export type GetProductImprovementGoalsQuery = {
  __typename?: "Query";
  improvementGoals: Array<{
    __typename?: "ImprovementGoal";
    productId: string;
    completionDate?: number | null;
    quizSlug: string;
    targetScore?: number | null;
  }>;
};

export type UpsertImprovementGoalMutationVariables = Exact<{
  quizSlug: Scalars["String"]["input"];
  productId: Scalars["String"]["input"];
  targetScore?: InputMaybe<Scalars["Float"]["input"]>;
  completionDate?: InputMaybe<Scalars["Float"]["input"]>;
}>;

export type UpsertImprovementGoalMutation = {
  __typename?: "Mutation";
  upsertImprovementGoal: {
    __typename?: "ImprovementGoal";
    completionDate?: number | null;
    productId: string;
    quizSlug: string;
    targetScore?: number | null;
  };
};

export type DeleteImprovementGoalMutationVariables = Exact<{
  quizSlug: Scalars["String"]["input"];
  productId: Scalars["String"]["input"];
}>;

export type DeleteImprovementGoalMutation = {
  __typename?: "Mutation";
  deleteImprovementGoal: { __typename?: "DeletionResult"; isDeleted?: boolean | null };
};

export type CreateAccelerateProductMutationVariables = Exact<{
  name: Scalars["String"]["input"];
  l3Uid: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
}>;

export type CreateAccelerateProductMutation = {
  __typename?: "Mutation";
  createProduct: {
    __typename?: "ProductResponse";
    id: string;
    name: string;
    description?: string | null;
    memberIds: Array<string>;
    ownerId?: string | null;
    techLeadId?: string | null;
    designLeadId?: string | null;
    l3Entity?: { __typename?: "L3EntityResponse"; serviceNowId: string; name: string } | null;
    delegates?: Array<{ __typename?: "User"; username: string }> | null;
  };
};

export type EditAccelerateProductMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  l3Uid?: InputMaybe<Scalars["String"]["input"]>;
  repositories?: InputMaybe<Array<EditProductRepositoriesInput> | EditProductRepositoriesInput>;
}>;

export type EditAccelerateProductMutation = {
  __typename?: "Mutation";
  editProduct: {
    __typename?: "ProductResponse";
    id: string;
    name: string;
    description?: string | null;
    memberIds: Array<string>;
    l3Entity?: { __typename?: "L3EntityResponse"; name: string } | null;
  };
};

export type CreateProductLinkedResourceMutationVariables = Exact<{
  productId: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
  url: Scalars["String"]["input"];
}>;

export type CreateProductLinkedResourceMutation = {
  __typename?: "Mutation";
  createProductLinkedResource: { __typename?: "LinkedResource"; description: string; url: string };
};

export type DeleteProductLinkedResourceMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteProductLinkedResourceMutation = {
  __typename?: "Mutation";
  deleteProductLinkedResource: { __typename?: "DeleteLinkedResourceResponse"; isDeleted: boolean };
};

export type UpdateProductLinkedResourceMutationVariables = Exact<{
  description: Scalars["String"]["input"];
  id: Scalars["String"]["input"];
  url: Scalars["String"]["input"];
}>;

export type UpdateProductLinkedResourceMutation = {
  __typename?: "Mutation";
  updateProductLinkedResource: { __typename?: "LinkedResource"; id: string; description: string; url: string };
};

export type MaturityProgressQueryVariables = Exact<{
  quizTypes: Array<Scalars["QuizType"]["input"]> | Scalars["QuizType"]["input"];
}>;

export type MaturityProgressQuery = {
  __typename?: "Query";
  maturityProgress: {
    __typename?: "MaturityProgress";
    productsWithCompletedAssessmentCount: number;
    productsWithImprovementGoalCount: number;
    totalProductsCount: number;
  };
};

export type SearchProductsQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type SearchProductsQuery = {
  __typename?: "Query";
  productsPaginated: {
    __typename?: "ProductsPaginated";
    total: number;
    items: Array<
      { __typename?: "ProductResponse" } & {
        " $fragmentRefs"?: { BasicProductFieldsFragment: BasicProductFieldsFragment };
      }
    >;
  };
};

export type SupportGroupMutationFieldsFragment = {
  __typename?: "SupportGroup";
  opsGroupId: string;
  productId?: string | null;
  name: string;
  description?: string | null;
  contactMethod: { __typename?: "ContactMethod"; type: ContactMethodType; value: string };
  users?: Array<{
    __typename?: "User";
    email?: string | null;
    givenName?: string | null;
    surname?: string | null;
    userId?: string | null;
  }> | null;
} & { " $fragmentName"?: "SupportGroupMutationFieldsFragment" };

export type CreateSupportGroupMutationVariables = Exact<{
  data: SupportGroupCreateArgs;
}>;

export type CreateSupportGroupMutation = {
  __typename?: "Mutation";
  createSupportGroup: { __typename?: "SupportGroup" } & {
    " $fragmentRefs"?: { SupportGroupMutationFieldsFragment: SupportGroupMutationFieldsFragment };
  };
};

export type UpdateSupportGroupMutationVariables = Exact<{
  data: SupportGroupUpdateArgs;
}>;

export type UpdateSupportGroupMutation = {
  __typename?: "Mutation";
  updateSupportGroup: { __typename?: "SupportGroup" } & {
    " $fragmentRefs"?: { SupportGroupMutationFieldsFragment: SupportGroupMutationFieldsFragment };
  };
};

export type DeleteSupportGroupMutationVariables = Exact<{
  data: SupportGroupDeleteArgs;
}>;

export type DeleteSupportGroupMutation = { __typename?: "Mutation"; deleteSupportGroup: boolean };

export type CompleteSupportGroupDeletionMutationVariables = Exact<{
  opsGroupId: Scalars["String"]["input"];
}>;

export type CompleteSupportGroupDeletionMutation = { __typename?: "Mutation"; completeSupportGroupDeletion: boolean };

export type CreateAlertMutationVariables = Exact<{
  data: AlertCreateArgs;
}>;

export type CreateAlertMutation = {
  __typename?: "Mutation";
  createAlert: {
    __typename?: "Alert";
    supportGroup?: { __typename?: "SupportGroup"; opsGroupId: string; name: string } | null;
  } & { " $fragmentRefs"?: { AlertFieldsFragment: AlertFieldsFragment } };
};

export type GetMetricsForBpQueryVariables = Exact<{ [key: string]: never }>;

export type GetMetricsForBpQuery = {
  __typename?: "Query";
  doraMetrics: {
    __typename?: "DORAMetrics";
    leadTime?: {
      __typename?: "LeadTime";
      commitToMergeHours?: number | null;
      mergeToProdHours?: number | null;
      leadTimeHours?: number | null;
      targetValue?: number | null;
      trendingCommitToMergeHours?: number | null;
      trendingMergeToProdHours?: number | null;
      trendingLeadTimeHours?: number | null;
    } | null;
  };
};

export type GetRepoInfoQueryVariables = Exact<{
  repositoryId: Scalars["String"]["input"];
}>;

export type GetRepoInfoQuery = {
  __typename?: "Query";
  getRepoInfo: {
    __typename?: "GetRepoInfoResponse";
    repositoryId: string;
    repositoryName: string;
    projectId: string;
    projectName: string;
    organisationName: string;
  };
};

export type GetAllActiveAdoReposQueryVariables = Exact<{
  organisationName: Scalars["String"]["input"];
  projectName: Scalars["String"]["input"];
}>;

export type GetAllActiveAdoReposQuery = {
  __typename?: "Query";
  getAllActiveADORepos: Array<{ __typename?: "GetADORepoResponse"; id: string; isDisabled: boolean; name: string }>;
};

export type AddProductMembersMutationVariables = Exact<{
  memberEmails: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
  productId: Scalars["String"]["input"];
}>;

export type AddProductMembersMutation = {
  __typename?: "Mutation";
  addProductMembers: { __typename?: "ProductResponse"; id: string; name: string };
};

export type RemoveProductMembersMutationVariables = Exact<{
  memberEmails: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
  productId: Scalars["String"]["input"];
}>;

export type RemoveProductMembersMutation = {
  __typename?: "Mutation";
  removeProductMembers: { __typename?: "ProductResponse"; id: string; name: string };
};

export type LeaveProductMutationVariables = Exact<{
  productId: Scalars["String"]["input"];
}>;

export type LeaveProductMutation = {
  __typename?: "Mutation";
  leaveProduct: { __typename?: "ProductResponse"; id: string; name: string; memberIds: Array<string> };
};

export type JoinProductMutationVariables = Exact<{
  productId: Scalars["String"]["input"];
}>;

export type JoinProductMutation = {
  __typename?: "Mutation";
  joinProduct: { __typename?: "ProductResponse"; id: string; name: string; memberIds: Array<string> };
};

export type UserRolesQueryVariables = Exact<{ [key: string]: never }>;

export type UserRolesQuery = {
  __typename?: "Query";
  currentUserProfile: {
    __typename?: "User";
    _id: string;
    username: string;
    isOwner: boolean;
    isDelegate: boolean;
    isTechLead: boolean;
    isDesignLead: boolean;
  };
};

export type CurrentUserProfileQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserProfileQuery = {
  __typename?: "Query";
  currentUserProfile: { __typename?: "User"; _id: string; username: string };
};

export type QuizzesQueryVariables = Exact<{
  quizTypes?: InputMaybe<Array<Scalars["QuizType"]["input"]> | Scalars["QuizType"]["input"]>;
}>;

export type QuizzesQuery = {
  __typename?: "Query";
  quizzes: Array<{
    __typename?: "Quiz";
    name: string;
    slug: string;
    category?: any | null;
    quizType: any;
    isRecentlyUpdated: boolean;
    questions: Array<{
      __typename?: "Question";
      id: number;
      metric?: string | null;
      question: string;
      isRecentlyUpdated: boolean;
      back: boolean;
      options: Array<{ __typename?: "Option"; id: number; label: string; value: number }>;
    }>;
  }>;
};

export type QuizPartsFragment = {
  __typename?: "Quiz";
  category?: any | null;
  description: string;
  href?: string | null;
  isRecentlyUpdated: boolean;
  maxScore: number;
  name: string;
  quizType: any;
  slug: string;
  questions: Array<{
    __typename?: "Question";
    back: boolean;
    id: number;
    isRecentlyUpdated: boolean;
    metric?: string | null;
    question: string;
    options: Array<{ __typename?: "Option"; id: number; label: string; value: number }>;
  }>;
} & { " $fragmentName"?: "QuizPartsFragment" };

export type QuizResultQueryVariables = Exact<{
  entityId: Scalars["String"]["input"];
  quizSlug: Scalars["QuizSlug"]["input"];
}>;

export type QuizResultQuery = {
  __typename?: "Query";
  productQuizResult?: {
    __typename?: "QuizResult";
    entityId: string;
    lastUpdated: number;
    maxScore?: number | null;
    quizType?: any | null;
    score?: any | null;
    answers: Array<{ __typename?: "Answer"; option_id: number; question_id: number }>;
    quiz: { __typename?: "Quiz" } & { " $fragmentRefs"?: { QuizPartsFragment: QuizPartsFragment } };
  } | null;
};

export type SubmitProductQuizResultMutationVariables = Exact<{
  data: ProductSubmissionInput;
}>;

export type SubmitProductQuizResultMutation = {
  __typename?: "Mutation";
  submitProductQuizResult?: {
    __typename?: "QuizResult";
    score?: any | null;
    quiz: { __typename?: "Quiz"; name: string; slug: string };
  } | null;
};

export type CreateSupportTicketMutationVariables = Exact<{
  description: Scalars["String"]["input"];
  supportType?: InputMaybe<SupportType>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  urgency?: InputMaybe<Urgency>;
  serviceName?: InputMaybe<ServiceName>;
  incidentUrl?: InputMaybe<Scalars["String"]["input"]>;
  incidentId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CreateSupportTicketMutation = {
  __typename?: "Mutation";
  createSupportTicket: {
    __typename?: "SupportTicket";
    description: string;
    supportType?: SupportType | null;
    serviceName?: ServiceName | null;
    incidentUrl?: string | null;
  };
};

export type GetUserByEmailQueryVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type GetUserByEmailQuery = {
  __typename?: "Query";
  getUserByEmail: {
    __typename?: "User";
    jobTitle?: string | null;
    photo?: string | null;
    givenName?: string | null;
    surname?: string | null;
    email?: string | null;
    userId?: string | null;
  };
};

export type ListSurveysQueryVariables = Exact<{ [key: string]: never }>;

export type ListSurveysQuery = {
  __typename?: "Query";
  listSurveys: Array<{
    __typename?: "SurveyResponse";
    surveyId: string;
    author: string;
    linkToSurvey: string;
    startDate: any;
    endDate: any;
    estimatedTime: number;
    approved: boolean;
    description: string;
    surveyName: string;
    lastUpdatedBy: string;
    createdDate: any;
    updatedDate: any;
  }>;
};

export type HasSurveyQueryVariables = Exact<{ [key: string]: never }>;

export type HasSurveyQuery = {
  __typename?: "Query";
  hasSurvey: Array<{
    __typename?: "HasSurveyResponse";
    surveyId: string;
    surveyName: string;
    linkToSurvey: string;
    estimatedTime: number;
    createdDate: any;
    updatedDate: any;
  }>;
};

export type PersistSurveyMutationVariables = Exact<{
  survey: SurveyInput;
}>;

export type PersistSurveyMutation = {
  __typename?: "Mutation";
  persistSurvey: {
    __typename?: "SurveyResponse";
    author: string;
    startDate: any;
    endDate: any;
    estimatedTime: number;
    description: string;
    linkToSurvey: string;
    surveyName: string;
    lastUpdatedBy: string;
  };
};

export type UpdateSurveyMutationVariables = Exact<{
  survey: SurveyInput;
}>;

export type UpdateSurveyMutation = {
  __typename?: "Mutation";
  updateSurvey: {
    __typename?: "SurveyResponse";
    surveyId: string;
    author: string;
    startDate: any;
    endDate: any;
    estimatedTime: number;
    approved: boolean;
    description: string;
    linkToSurvey: string;
    surveyName: string;
    lastUpdatedBy: string;
  };
};

export type RemoveSurveyMutationVariables = Exact<{
  surveyId: Scalars["String"]["input"];
}>;

export type RemoveSurveyMutation = {
  __typename?: "Mutation";
  removeSurvey: { __typename?: "RemoveSurveyResponse"; success: boolean; message: string };
};

export type CompleteSurveyMutationVariables = Exact<{
  completeSurvey: CompleteSurveyInput;
}>;

export type CompleteSurveyMutation = {
  __typename?: "Mutation";
  completeSurvey: {
    __typename?: "UsersWithSurvey";
    userId: string;
    surveyId: string;
    hasCompletedSurvey: boolean;
    surveyStatus: boolean;
    userEmail: string;
    surveyName: string;
  };
};

export type DeploymentStatus_YallaDeploymentFragment = {
  __typename?: "YallaDeployment";
  id: string;
  updatedAt: any;
  deploymentSteps: Array<{ __typename?: "YallaDeploymentStep"; environment: string; status: YallaDeploymentStatus }>;
} & { " $fragmentName"?: "DeploymentStatus_YallaDeploymentFragment" };

export type DeploymentsTab_YallaServiceFragment = {
  __typename?: "YallaService";
  deployments: Array<
    {
      __typename?: "YallaDeployment";
      id: string;
      serviceName: string;
      status: YallaDeploymentStatus;
      updatedAt: any;
      version: any;
      sourceCommitMessage: string;
      deploymentSteps: Array<{
        __typename?: "YallaDeploymentStep";
        environment: string;
        status: YallaDeploymentStatus;
      }>;
    } & { " $fragmentRefs"?: { DeploymentStatus_YallaDeploymentFragment: DeploymentStatus_YallaDeploymentFragment } }
  >;
} & { " $fragmentName"?: "DeploymentsTab_YallaServiceFragment" };

export type InstanceCard_YallaServiceInstanceFragment = {
  __typename?: "YallaServiceInstance";
  id: string;
  name: string;
  environment: string;
  health: YallaInstanceHealthStatus;
  syncStatus: YallaInstanceSyncStatus;
} & { " $fragmentName"?: "InstanceCard_YallaServiceInstanceFragment" };

export type InstanceCard_YallaDeploymentFragment = {
  __typename?: "YallaDeployment";
  updatedAt: any;
  version: any;
  sourceCommitMessage: string;
  status: YallaDeploymentStatus;
} & { " $fragmentName"?: "InstanceCard_YallaDeploymentFragment" };

export type InstanceTab_YallaServiceFragment = {
  __typename?: "YallaService";
  instances: Array<
    { __typename?: "YallaServiceInstance"; id: string; name: string; version: any } & {
      " $fragmentRefs"?: { InstanceCard_YallaServiceInstanceFragment: InstanceCard_YallaServiceInstanceFragment };
    }
  >;
  deployments: Array<
    { __typename?: "YallaDeployment" } & {
      " $fragmentRefs"?: { InstanceCard_YallaDeploymentFragment: InstanceCard_YallaDeploymentFragment };
    }
  >;
} & { " $fragmentName"?: "InstanceTab_YallaServiceFragment" };

export type DeploymentInfoItem_YallaServiceInstanceChangeFragment = {
  __typename?: "YallaServiceInstanceChange";
  commitUrl?: string | null;
  description?: string | null;
  date: any;
  kind: YallaInstanceChangeKind;
} & { " $fragmentName"?: "DeploymentInfoItem_YallaServiceInstanceChangeFragment" };

export type DeployInfoList_YallaServiceInstanceFragment = {
  __typename?: "YallaServiceInstance";
  changes: Array<
    { __typename?: "YallaServiceInstanceChange"; commit: string } & {
      " $fragmentRefs"?: {
        DeploymentInfoItem_YallaServiceInstanceChangeFragment: DeploymentInfoItem_YallaServiceInstanceChangeFragment;
      };
    }
  >;
} & { " $fragmentName"?: "DeployInfoList_YallaServiceInstanceFragment" };

export type InstanceLinks_YallaServiceInstanceFragment = {
  __typename?: "YallaServiceInstance";
  integrationLinks?: {
    __typename?: "YallaIntegrationLinks";
    argoCd?: string | null;
    repositories: Array<string>;
  } | null;
} & { " $fragmentName"?: "InstanceLinks_YallaServiceInstanceFragment" };

export type StatusCard_YallaServiceInstanceFragment = {
  __typename?: "YallaServiceInstance";
  syncStatus: YallaInstanceSyncStatus;
  health: YallaInstanceHealthStatus;
} & { " $fragmentName"?: "StatusCard_YallaServiceInstanceFragment" };

export type GetProductEnvironmentByIdQueryVariables = Exact<{
  productId: Scalars["String"]["input"];
}>;

export type GetProductEnvironmentByIdQuery = {
  __typename?: "Query";
  product: {
    __typename?: "ProductResponse";
    name: string;
    yallaPlatform?: {
      __typename?: "YallaPlatform";
      name: string;
      environments: Array<{
        __typename?: "YallaEnvironment";
        name: string;
        instances: Array<{
          __typename?: "YallaServiceInstance";
          id: string;
          health: YallaInstanceHealthStatus;
          name: string;
          syncStatus: YallaInstanceSyncStatus;
          platformName: string;
          serviceName: string;
          updatedAt?: string | null;
          deployment?: { __typename?: "YallaDeployment"; updatedAt: any; sourceCommitMessage: string } | null;
        }>;
      }>;
    } | null;
  };
};

export type GetYallaServiceInfoForServicePageQueryVariables = Exact<{
  serviceName: Scalars["String"]["input"];
  platformName: Scalars["String"]["input"];
}>;

export type GetYallaServiceInfoForServicePageQuery = {
  __typename?: "Query";
  getYallaService: { __typename?: "YallaService"; id: string; name: string } & {
    " $fragmentRefs"?: {
      InstanceTab_YallaServiceFragment: InstanceTab_YallaServiceFragment;
      DeploymentsTab_YallaServiceFragment: DeploymentsTab_YallaServiceFragment;
    };
  };
};

export type GetYallaPlatformNameFromProductQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetYallaPlatformNameFromProductQuery = {
  __typename?: "Query";
  product: {
    __typename?: "ProductResponse";
    id: string;
    name: string;
    yallaPlatform?: { __typename?: "YallaPlatform"; name: string } | null;
  };
};

export type GetYallaServiceInfoForInstancePageQueryVariables = Exact<{
  serviceName: Scalars["String"]["input"];
  platformName: Scalars["String"]["input"];
}>;

export type GetYallaServiceInfoForInstancePageQuery = {
  __typename?: "Query";
  getYallaService: {
    __typename?: "YallaService";
    id: string;
    instances: Array<
      { __typename?: "YallaServiceInstance"; id: string; name: string; version: any; environment: string } & {
        " $fragmentRefs"?: {
          DeployInfoList_YallaServiceInstanceFragment: DeployInfoList_YallaServiceInstanceFragment;
          StatusCard_YallaServiceInstanceFragment: StatusCard_YallaServiceInstanceFragment;
          InstanceLinks_YallaServiceInstanceFragment: InstanceLinks_YallaServiceInstanceFragment;
        };
      }
    >;
  };
};

export type GetAccelerateProductDetailsQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetAccelerateProductDetailsQuery = {
  __typename?: "Query";
  product: {
    __typename?: "ProductResponse";
    id: string;
    name: string;
    description?: string | null;
    memberIds: Array<string>;
    ownerId?: string | null;
    designLeadId?: string | null;
    techLeadId?: string | null;
    l3EntityUid?: string | null;
    createdDate?: number | null;
    lastUpdatedById?: string | null;
    createdById?: string | null;
    lastUpdated: number;
    delegates?: Array<{ __typename?: "User"; email?: string | null }> | null;
    yallaPlatform?: { __typename?: "YallaPlatform"; name: string } | null;
    cloudSpending?: {
      __typename?: "CloudSpending";
      cloudabilityName: string;
      cloudabilityViewId: number;
      previousMonth?: number | null;
      currentMonth?: number | null;
      currentMonthEstimate?: number | null;
    } | null;
    linkedResources?: Array<{ __typename?: "LinkedResource"; id: string; description: string; url: string }> | null;
  };
};

export type GetAccelerateProductDetailsThatAreVerySlowQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetAccelerateProductDetailsThatAreVerySlowQuery = {
  __typename?: "Query";
  product: {
    __typename?: "ProductResponse";
    id: string;
    l3Entity?: {
      __typename?: "L3EntityResponse";
      serviceNowId: string;
      name: string;
      description: string;
      tier: { __typename?: "L3TierResponse"; name: string; parentName: string };
    } | null;
    yallaPlatform?: {
      __typename?: "YallaPlatform";
      name: string;
      products: Array<{
        __typename?: "YallaProduct";
        id: string;
        name: string;
        platformName: string;
        services: Array<{
          __typename?: "YallaService";
          id: string;
          name: string;
          platformName: string;
          instances: Array<{
            __typename?: "YallaServiceInstance";
            id: string;
            name: string;
            syncStatus: YallaInstanceSyncStatus;
            version: any;
            health: YallaInstanceHealthStatus;
            environment: string;
          }>;
          deployments: Array<{
            __typename?: "YallaDeployment";
            id: string;
            serviceName: string;
            status: YallaDeploymentStatus;
            updatedAt: any;
            version: any;
            deploymentSteps: Array<{
              __typename?: "YallaDeploymentStep";
              environment: string;
              status: YallaDeploymentStatus;
            }>;
          }>;
        }>;
      }>;
    } | null;
    newCapabilityResult?: {
      __typename?: "CapabilityResult";
      productId: string;
      lastUpdated?: number | null;
      score?: number | null;
      groupedByCategory: Array<{
        __typename?: "CategoryResult";
        categoryName: string;
        lastUpdated?: number | null;
        score?: number | null;
        quizResults: Array<{
          __typename?: "QuizResult";
          entityId: string;
          lastUpdated: number;
          maxScore?: number | null;
          quizType?: any | null;
          score?: any | null;
          answers: Array<{
            __typename?: "Answer";
            option_id: number;
            question_id: number;
            option?: { __typename?: "Option"; id: number; label: string; value: number } | null;
            question?: {
              __typename?: "Question";
              back: boolean;
              id: number;
              isRecentlyUpdated: boolean;
              metric?: string | null;
              question: string;
              options: Array<{ __typename?: "Option"; id: number; label: string; value: number }>;
            } | null;
          }>;
          quiz: {
            __typename?: "Quiz";
            category?: any | null;
            description: string;
            href?: string | null;
            isRecentlyUpdated: boolean;
            maxScore: number;
            name: string;
            quizType: any;
            slug: string;
            questions: Array<{
              __typename?: "Question";
              back: boolean;
              id: number;
              isRecentlyUpdated: boolean;
              metric?: string | null;
              question: string;
            }>;
          };
        }>;
      }>;
      quizResults: Array<{
        __typename?: "QuizResult";
        entityId: string;
        lastUpdated: number;
        maxScore?: number | null;
        quizType?: any | null;
        score?: any | null;
        answers: Array<{
          __typename?: "Answer";
          option_id: number;
          question_id: number;
          option?: { __typename?: "Option"; id: number; label: string; value: number } | null;
          question?: {
            __typename?: "Question";
            question: string;
            back: boolean;
            id: number;
            isRecentlyUpdated: boolean;
            metric?: string | null;
            options: Array<{ __typename?: "Option"; id: number; label: string; value: number }>;
          } | null;
        }>;
        quiz: {
          __typename?: "Quiz";
          category?: any | null;
          description: string;
          href?: string | null;
          isRecentlyUpdated: boolean;
          maxScore: number;
          name: string;
          quizType: any;
          slug: string;
          questions: Array<{
            __typename?: "Question";
            back: boolean;
            id: number;
            isRecentlyUpdated: boolean;
            metric?: string | null;
            question: string;
            options: Array<{ __typename?: "Option"; id: number; label: string; value: number }>;
          }>;
        };
      }>;
    } | null;
  };
};

export type GetAccelerateProductDetailsForASpecificUserQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetAccelerateProductDetailsForASpecificUserQuery = {
  __typename?: "Query";
  product: {
    __typename?: "ProductResponse";
    id: string;
    permissions: { __typename?: "ProductPermissions"; edit: boolean; delete: boolean; linkage: boolean };
    repositoryInfo: {
      __typename?: "ProductRepositoryInfo";
      organisationName?: string | null;
      projectName?: string | null;
      repositories: Array<{ __typename?: "LinkedRepository"; name: string; id: string; link: string }>;
    };
    members: Array<{
      __typename?: "User";
      email?: string | null;
      jobTitle?: string | null;
      photo?: string | null;
      givenName?: string | null;
      surname?: string | null;
      userId?: string | null;
      enabled?: boolean | null;
    }>;
  };
};

export type GetAccelerateProductSupportGroupDetailsQueryVariables = Exact<{
  id: Scalars["String"]["input"];
  date: Scalars["Float"]["input"];
}>;

export type GetAccelerateProductSupportGroupDetailsQuery = {
  __typename?: "Query";
  product: {
    __typename?: "ProductResponse";
    id: string;
    name: string;
    ownerId?: string | null;
    techLeadId?: string | null;
    delegates?: Array<{ __typename?: "User"; email?: string | null }> | null;
    permissions: { __typename?: "ProductPermissions"; edit: boolean; delete: boolean; linkage: boolean };
    l3Entity?: {
      __typename?: "L3EntityResponse";
      serviceNowId: string;
      opsGroups: Array<{
        __typename?: "OpsGroup";
        id: string;
        name: string;
        supportGroup?: { __typename?: "SupportGroup"; opsGroupId: string; name: string } | null;
        users: Array<{ __typename?: "User"; _id: string; givenName?: string | null; surname?: string | null }>;
      }>;
    } | null;
    supportGroups: Array<{
      __typename?: "SupportGroup";
      isOnboarding: boolean;
      description?: string | null;
      name: string;
      areOnCallMembersPublic?: boolean | null;
      productId?: string | null;
      opsGroupId: string;
      opsGroup?: { __typename?: "OpsGroup"; id: string; name: string } | null;
      users?: Array<{
        __typename?: "User";
        _id: string;
        email?: string | null;
        givenName?: string | null;
        surname?: string | null;
        jobTitle?: string | null;
        userId?: string | null;
        photo?: string | null;
        contact?: {
          __typename?: "UserContactDetails";
          email: string;
          slack?: string | null;
          teams?: string | null;
        } | null;
      }> | null;
      contactMethod: { __typename?: "ContactMethod"; type: ContactMethodType; value: string };
      alerts: Array<{
        __typename?: "Alert";
        id: string;
        dateRaised: number;
        dateUpdated: number;
        alertStatus: AlertStatusType;
        description: string;
        resolutionNote?: string | null;
        raisedBy?: {
          __typename?: "User";
          email?: string | null;
          givenName?: string | null;
          surname?: string | null;
          jobTitle?: string | null;
          userId?: string | null;
          photo?: string | null;
          contact?: {
            __typename?: "UserContactDetails";
            email: string;
            slack?: string | null;
            teams?: string | null;
          } | null;
        } | null;
      }>;
      links?: {
        __typename?: "SupportGroupLinks";
        manageEscalationPoliciesLink: string;
        manageScheduleLink: string;
      } | null;
      userShifts?: Array<{
        __typename?: "SupportGroupUserShift";
        date: number;
        end?: number | null;
        isOnCall: boolean;
        scheduleName: string;
        start?: number | null;
      }> | null;
    }>;
  };
};

export const BasicProductFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BasicProductFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ProductResponse" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "permissions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "edit" } },
                { kind: "Field", name: { kind: "Name", value: "delete" } },
                { kind: "Field", name: { kind: "Name", value: "linkage" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "memberIds" } },
          { kind: "Field", name: { kind: "Name", value: "ownerId" } },
          { kind: "Field", name: { kind: "Name", value: "designLeadId" } },
          { kind: "Field", name: { kind: "Name", value: "techLeadId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "delegates" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "email" } }],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "l3EntityUid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "newCapabilityResult" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "score" } },
                { kind: "Field", name: { kind: "Name", value: "lastUpdated" } },
                { kind: "Field", name: { kind: "Name", value: "productId" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "yallaPlatform" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BasicProductFieldsFragment, unknown>;
export const UserFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "User" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "givenName" } },
          { kind: "Field", name: { kind: "Name", value: "surname" } },
          { kind: "Field", name: { kind: "Name", value: "jobTitle" } },
          { kind: "Field", name: { kind: "Name", value: "userId" } },
          { kind: "Field", name: { kind: "Name", value: "photo" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "contact" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "slack" } },
                { kind: "Field", name: { kind: "Name", value: "teams" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserFieldsFragment, unknown>;
export const AlertFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AlertFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Alert" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "dateRaised" } },
          { kind: "Field", name: { kind: "Name", value: "dateUpdated" } },
          { kind: "Field", name: { kind: "Name", value: "alertStatus" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "resolutionNote" } },
          {
            kind: "InlineFragment",
            directives: [{ kind: "Directive", name: { kind: "Name", value: "defer" } }],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "raisedBy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "UserFields" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "User" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "givenName" } },
          { kind: "Field", name: { kind: "Name", value: "surname" } },
          { kind: "Field", name: { kind: "Name", value: "jobTitle" } },
          { kind: "Field", name: { kind: "Name", value: "userId" } },
          { kind: "Field", name: { kind: "Name", value: "photo" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "contact" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "slack" } },
                { kind: "Field", name: { kind: "Name", value: "teams" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AlertFieldsFragment, unknown>;
export const SupportGroupMutationFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SupportGroupMutationFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "SupportGroup" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "opsGroupId" } },
          { kind: "Field", name: { kind: "Name", value: "productId" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "contactMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "value" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "users" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "givenName" } },
                { kind: "Field", name: { kind: "Name", value: "surname" } },
                { kind: "Field", name: { kind: "Name", value: "userId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SupportGroupMutationFieldsFragment, unknown>;
export const QuizPartsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "QuizParts" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Quiz" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "category" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "href" } },
          { kind: "Field", name: { kind: "Name", value: "isRecentlyUpdated" } },
          { kind: "Field", name: { kind: "Name", value: "maxScore" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "questions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "back" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "isRecentlyUpdated" } },
                { kind: "Field", name: { kind: "Name", value: "metric" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "options" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "label" } },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "question" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "quizType" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuizPartsFragment, unknown>;
export const DeploymentStatus_YallaDeploymentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DeploymentStatus_YallaDeployment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "YallaDeployment" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "deploymentSteps" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "environment" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeploymentStatus_YallaDeploymentFragment, unknown>;
export const DeploymentsTab_YallaServiceFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DeploymentsTab_YallaService" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "YallaService" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deployments" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "serviceName" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "version" } },
                { kind: "Field", name: { kind: "Name", value: "sourceCommitMessage" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "deploymentSteps" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "environment" } },
                      { kind: "Field", name: { kind: "Name", value: "status" } },
                    ],
                  },
                },
                { kind: "FragmentSpread", name: { kind: "Name", value: "DeploymentStatus_YallaDeployment" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DeploymentStatus_YallaDeployment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "YallaDeployment" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "deploymentSteps" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "environment" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeploymentsTab_YallaServiceFragment, unknown>;
export const InstanceCard_YallaServiceInstanceFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "InstanceCard_YallaServiceInstance" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "YallaServiceInstance" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "environment" } },
          { kind: "Field", name: { kind: "Name", value: "health" } },
          { kind: "Field", name: { kind: "Name", value: "syncStatus" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstanceCard_YallaServiceInstanceFragment, unknown>;
export const InstanceCard_YallaDeploymentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "InstanceCard_YallaDeployment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "YallaDeployment" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "version" } },
          { kind: "Field", name: { kind: "Name", value: "sourceCommitMessage" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstanceCard_YallaDeploymentFragment, unknown>;
export const InstanceTab_YallaServiceFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "InstanceTab_YallaService" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "YallaService" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "instances" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "version" } },
                { kind: "FragmentSpread", name: { kind: "Name", value: "InstanceCard_YallaServiceInstance" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "deployments" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "InstanceCard_YallaDeployment" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "InstanceCard_YallaServiceInstance" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "YallaServiceInstance" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "environment" } },
          { kind: "Field", name: { kind: "Name", value: "health" } },
          { kind: "Field", name: { kind: "Name", value: "syncStatus" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "InstanceCard_YallaDeployment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "YallaDeployment" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "version" } },
          { kind: "Field", name: { kind: "Name", value: "sourceCommitMessage" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstanceTab_YallaServiceFragment, unknown>;
export const DeploymentInfoItem_YallaServiceInstanceChangeFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DeploymentInfoItem_YallaServiceInstanceChange" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "YallaServiceInstanceChange" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "commitUrl" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "date" } },
          { kind: "Field", name: { kind: "Name", value: "kind" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeploymentInfoItem_YallaServiceInstanceChangeFragment, unknown>;
export const DeployInfoList_YallaServiceInstanceFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DeployInfoList_YallaServiceInstance" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "YallaServiceInstance" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "changes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "commit" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "DeploymentInfoItem_YallaServiceInstanceChange" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DeploymentInfoItem_YallaServiceInstanceChange" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "YallaServiceInstanceChange" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "commitUrl" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "date" } },
          { kind: "Field", name: { kind: "Name", value: "kind" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeployInfoList_YallaServiceInstanceFragment, unknown>;
export const InstanceLinks_YallaServiceInstanceFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "InstanceLinks_YallaServiceInstance" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "YallaServiceInstance" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "integrationLinks" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "argoCd" } },
                { kind: "Field", name: { kind: "Name", value: "repositories" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstanceLinks_YallaServiceInstanceFragment, unknown>;
export const StatusCard_YallaServiceInstanceFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "StatusCard_YallaServiceInstance" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "YallaServiceInstance" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "syncStatus" } },
          { kind: "Field", name: { kind: "Name", value: "health" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StatusCard_YallaServiceInstanceFragment, unknown>;
export const ListChatsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ListChats" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listChats" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "chatId" } },
                { kind: "Field", name: { kind: "Name", value: "lastUpdate" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListChatsQuery, ListChatsQueryVariables>;
export const OpenChatDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "OpenChat" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "chatId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "openChat" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "chatId" },
                value: { kind: "Variable", name: { kind: "Name", value: "chatId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "history" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "auditId" } },
                      { kind: "Field", name: { kind: "Name", value: "request" } },
                      { kind: "Field", name: { kind: "Name", value: "answer" } },
                      { kind: "Field", name: { kind: "Name", value: "kendraSearch" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sourceDocs" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "url" } },
                            { kind: "Field", name: { kind: "Name", value: "score" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "probablePublicData" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "chatId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OpenChatQuery, OpenChatQueryVariables>;
export const ChatRequestDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ChatRequest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "chatMessage" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ChatMessage" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "chatRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "chatMessage" },
                value: { kind: "Variable", name: { kind: "Name", value: "chatMessage" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "auditId" } },
                { kind: "Field", name: { kind: "Name", value: "request" } },
                { kind: "Field", name: { kind: "Name", value: "answer" } },
                { kind: "Field", name: { kind: "Name", value: "chatId" } },
                { kind: "Field", name: { kind: "Name", value: "chatName" } },
                { kind: "Field", name: { kind: "Name", value: "kendraSearch" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "keywordSearchData" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "isKeywordSearch" } },
                      { kind: "Field", name: { kind: "Name", value: "recommendations" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sourceDocs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                      { kind: "Field", name: { kind: "Name", value: "score" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "probablePublicData" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatRequestQuery, ChatRequestQueryVariables>;
export const GetSuggestionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetSuggestions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "requestSuggestions" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "RequestSuggestions" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getSuggestions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "requestSuggestions" },
                value: { kind: "Variable", name: { kind: "Name", value: "requestSuggestions" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "questions" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSuggestionsQuery, GetSuggestionsQueryVariables>;
export const RetrieveSuggestedTagsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "RetrieveSuggestedTags" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "retrieveSuggestedTags" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "tags" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RetrieveSuggestedTagsQuery, RetrieveSuggestedTagsQueryVariables>;
export const RenameChatDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RenameChat" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "renameMessage" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "RenameMessage" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "renameChat" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "renameMessage" },
                value: { kind: "Variable", name: { kind: "Name", value: "renameMessage" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "chatId" } },
                { kind: "Field", name: { kind: "Name", value: "lastUpdate" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RenameChatMutation, RenameChatMutationVariables>;
export const DeleteChatDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteChat" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "chatId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteChat" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "chatId" },
                value: { kind: "Variable", name: { kind: "Name", value: "chatId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "chatId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteChatMutation, DeleteChatMutationVariables>;
export const NewChatDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "NewChat" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "newChat" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "chatId" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NewChatMutation, NewChatMutationVariables>;
export const SubmitAxelIssueDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SubmitAxelIssue" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "submitIssue" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "SubmitIssue" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "submitAxelIssue" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "submitIssue" },
                value: { kind: "Variable", name: { kind: "Name", value: "submitIssue" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "auditId" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubmitAxelIssueMutation, SubmitAxelIssueMutationVariables>;
export const AllComponentsAndTagsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AllComponentsAndTags" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getAllCs" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "repoUrl" } },
                { kind: "Field", name: { kind: "Name", value: "readmeUrl" } },
                { kind: "Field", name: { kind: "Name", value: "authorEmail" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "tags" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "getAllSSAs" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "specFileUrl" } },
                { kind: "Field", name: { kind: "Name", value: "authorEmail" } },
                { kind: "Field", name: { kind: "Name", value: "authenticationType" } },
                { kind: "Field", name: { kind: "Name", value: "authenticationCredential" } },
                { kind: "Field", name: { kind: "Name", value: "authorizationHeaderName" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "tags" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "getAllTagDetails" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tagDetails" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "tagName" } },
                      { kind: "Field", name: { kind: "Name", value: "tagType" } },
                      { kind: "Field", name: { kind: "Name", value: "kHubOverviewPage" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      { kind: "Field", name: { kind: "Name", value: "highlighted" } },
                      { kind: "Field", name: { kind: "Name", value: "description" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllComponentsAndTagsQuery, AllComponentsAndTagsQueryVariables>;
export const AllUserComponentsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AllUserComponents" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "allUserComponents" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "authorEmail" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "componentType" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "tags" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "getAllTagDetails" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tagDetails" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "tagName" } },
                      { kind: "Field", name: { kind: "Name", value: "tagType" } },
                      { kind: "Field", name: { kind: "Name", value: "kHubOverviewPage" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      { kind: "Field", name: { kind: "Name", value: "highlighted" } },
                      { kind: "Field", name: { kind: "Name", value: "description" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllUserComponentsQuery, AllUserComponentsQueryVariables>;
export const DeleteComponentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteComponent" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "DeleteComponent" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteComponent" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "authorEmail" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "componentType" } },
                { kind: "Field", name: { kind: "Name", value: "tags" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteComponentMutation, DeleteComponentMutationVariables>;
export const UpdateComponentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateComponent" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UpdateComponent" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateComponent" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "authorEmail" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "componentType" } },
                { kind: "Field", name: { kind: "Name", value: "tags" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateComponentMutation, UpdateComponentMutationVariables>;
export const ExecuteSsaDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ExecuteSsa" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "SsaExecute" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "executeSsa" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "code" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExecuteSsaMutation, ExecuteSsaMutationVariables>;
export const SubmitCsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SubmitCs" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "CsCommand" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "submitCs" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "repoUrl" } },
                { kind: "Field", name: { kind: "Name", value: "readmeUrl" } },
                { kind: "Field", name: { kind: "Name", value: "authorEmail" } },
                { kind: "Field", name: { kind: "Name", value: "tags" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubmitCsMutation, SubmitCsMutationVariables>;
export const SubmitSsaDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SubmitSsa" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "SsaCommand" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "submitSsa" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "authenticationType" } },
                { kind: "Field", name: { kind: "Name", value: "authenticationCredential" } },
                { kind: "Field", name: { kind: "Name", value: "authorizationHeaderName" } },
                { kind: "Field", name: { kind: "Name", value: "tags" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubmitSsaMutation, SubmitSsaMutationVariables>;
export const ValidateSsaDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ValidateSsa" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "SsaValidate" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "validateSsa" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "code" } },
                { kind: "Field", name: { kind: "Name", value: "preview" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ValidateSsaMutation, ValidateSsaMutationVariables>;
export const RetrieveComponentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "RetrieveComponent" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "retrieveComponent" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: { kind: "Variable", name: { kind: "Name", value: "name" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "authorEmail" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "componentType" } },
                { kind: "Field", name: { kind: "Name", value: "tags" } },
                { kind: "Field", name: { kind: "Name", value: "repoUrl" } },
                { kind: "Field", name: { kind: "Name", value: "readmeUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userParameters" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "arg_type" } },
                      { kind: "Field", name: { kind: "Name", value: "description" } },
                      { kind: "Field", name: { kind: "Name", value: "http_parameter_type" } },
                      { kind: "Field", name: { kind: "Name", value: "default" } },
                      { kind: "Field", name: { kind: "Name", value: "options" } },
                      { kind: "Field", name: { kind: "Name", value: "max_length" } },
                      { kind: "Field", name: { kind: "Name", value: "max_value" } },
                      { kind: "Field", name: { kind: "Name", value: "min_length" } },
                      { kind: "Field", name: { kind: "Name", value: "min_value" } },
                      { kind: "Field", name: { kind: "Name", value: "hidden" } },
                      { kind: "Field", name: { kind: "Name", value: "regex" } },
                      { kind: "Field", name: { kind: "Name", value: "regex_hint" } },
                      { kind: "Field", name: { kind: "Name", value: "whats_this_link" } },
                      { kind: "Field", name: { kind: "Name", value: "options_api" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "info_link" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "url" } },
                            { kind: "Field", name: { kind: "Name", value: "placeholder_text" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RetrieveComponentQuery, RetrieveComponentQueryVariables>;
export const RetrieveSsaOptionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "RetrieveSsaOptions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "SsaOptionsApiRequest" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "retrieveSsaOptions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "options" } },
                { kind: "Field", name: { kind: "Name", value: "parameterName" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RetrieveSsaOptionsQuery, RetrieveSsaOptionsQueryVariables>;
export const AllRelatedComponentsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AllRelatedComponents" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "RelatedTags" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "allRelatedComponents" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "authorEmail" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "componentType" } },
                { kind: "Field", name: { kind: "Name", value: "tags" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllRelatedComponentsQuery, AllRelatedComponentsQueryVariables>;
export const AllUserComponentsProfilesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AllUserComponentsProfiles" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "allUserComponents" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "authorEmail" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "componentType" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "tags" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "allTags" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "tags" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllUserComponentsProfilesQuery, AllUserComponentsProfilesQueryVariables>;
export const AllExistingComponentsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AllExistingComponents" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "allExistingComponents" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "authorEmail" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "componentType" } },
                { kind: "Field", name: { kind: "Name", value: "tags" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllExistingComponentsQuery, AllExistingComponentsQueryVariables>;
export const ApproveComponentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ApproveComponent" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ApproveComponent" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "approveComponent" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "authorEmail" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "componentType" } },
                { kind: "Field", name: { kind: "Name", value: "tags" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApproveComponentMutation, ApproveComponentMutationVariables>;
export const RejectComponentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RejectComponent" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "RejectComponent" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "rejectComponent" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "authorEmail" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "componentType" } },
                { kind: "Field", name: { kind: "Name", value: "tags" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RejectComponentMutation, RejectComponentMutationVariables>;
export const GetApprovedQuestionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetApprovedQuestions" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "approvedQuestions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "body" } },
                { kind: "Field", name: { kind: "Name", value: "author" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "tags" } },
                { kind: "Field", name: { kind: "Name", value: "answers" } },
                { kind: "Field", name: { kind: "Name", value: "comments" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "deletedAt" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "updatedBy" } },
                { kind: "Field", name: { kind: "Name", value: "upvotes" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetApprovedQuestionsQuery, GetApprovedQuestionsQueryVariables>;
export const AllUserQuestionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AllUserQuestions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "hideInReview" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "hideApproved" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "hideRejected" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "user" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userQuestions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "hideInReview" },
                value: { kind: "Variable", name: { kind: "Name", value: "hideInReview" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "hideApproved" },
                value: { kind: "Variable", name: { kind: "Name", value: "hideApproved" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "hideRejected" },
                value: { kind: "Variable", name: { kind: "Name", value: "hideRejected" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "user" },
                value: { kind: "Variable", name: { kind: "Name", value: "user" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "body" } },
                { kind: "Field", name: { kind: "Name", value: "author" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "tags" } },
                { kind: "Field", name: { kind: "Name", value: "answers" } },
                { kind: "Field", name: { kind: "Name", value: "comments" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "deletedAt" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "updatedBy" } },
                { kind: "Field", name: { kind: "Name", value: "upvotes" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "approvedQuestions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "body" } },
                { kind: "Field", name: { kind: "Name", value: "author" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "tags" } },
                { kind: "Field", name: { kind: "Name", value: "answers" } },
                { kind: "Field", name: { kind: "Name", value: "comments" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "deletedAt" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "updatedBy" } },
                { kind: "Field", name: { kind: "Name", value: "upvotes" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllUserQuestionsQuery, AllUserQuestionsQueryVariables>;
export const QuestionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Questions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "hideInReview" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "hideApproved" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "hideRejected" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "questions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "hideInReview" },
                value: { kind: "Variable", name: { kind: "Name", value: "hideInReview" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "hideApproved" },
                value: { kind: "Variable", name: { kind: "Name", value: "hideApproved" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "hideRejected" },
                value: { kind: "Variable", name: { kind: "Name", value: "hideRejected" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "body" } },
                { kind: "Field", name: { kind: "Name", value: "author" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "tags" } },
                { kind: "Field", name: { kind: "Name", value: "answers" } },
                { kind: "Field", name: { kind: "Name", value: "comments" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "deletedAt" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "updatedBy" } },
                { kind: "Field", name: { kind: "Name", value: "upvotes" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuestionsQuery, QuestionsQueryVariables>;
export const GetTagsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetTags" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "hideInReviewQuestions" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "hideApprovedQuestions" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "hideRejectedQuestions" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tags" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "hideInReviewQuestions" },
                value: { kind: "Variable", name: { kind: "Name", value: "hideInReviewQuestions" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "hideApprovedQuestions" },
                value: { kind: "Variable", name: { kind: "Name", value: "hideApprovedQuestions" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "hideRejectedQuestions" },
                value: { kind: "Variable", name: { kind: "Name", value: "hideRejectedQuestions" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "questionCount" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTagsQuery, GetTagsQueryVariables>;
export const AllGlobalTagsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AllGlobalTags" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "allTags" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "tags" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllGlobalTagsQuery, AllGlobalTagsQueryVariables>;
export const GetTagDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetTag" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "hideInReviewQuestions" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "hideApprovedQuestions" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "hideRejectedQuestions" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tag" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: { kind: "Variable", name: { kind: "Name", value: "name" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "hideInReviewQuestions" },
                value: { kind: "Variable", name: { kind: "Name", value: "hideInReviewQuestions" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "hideApprovedQuestions" },
                value: { kind: "Variable", name: { kind: "Name", value: "hideApprovedQuestions" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "hideRejectedQuestions" },
                value: { kind: "Variable", name: { kind: "Name", value: "hideRejectedQuestions" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "questionCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "questions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "answers" } },
                      { kind: "Field", name: { kind: "Name", value: "author" } },
                      { kind: "Field", name: { kind: "Name", value: "body" } },
                      { kind: "Field", name: { kind: "Name", value: "comments" } },
                      { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                      { kind: "Field", name: { kind: "Name", value: "deletedAt" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "status" } },
                      { kind: "Field", name: { kind: "Name", value: "tags" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                      { kind: "Field", name: { kind: "Name", value: "updatedBy" } },
                      { kind: "Field", name: { kind: "Name", value: "upvotes" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTagQuery, GetTagQueryVariables>;
export const AddTagDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddTag" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addTag" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: { kind: "Variable", name: { kind: "Name", value: "name" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "deletedAt" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "questionCount" } },
                { kind: "Field", name: { kind: "Name", value: "questions" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "updatedBy" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddTagMutation, AddTagMutationVariables>;
export const AddQuestionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddQuestion" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "AddQuestionInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addQuestion" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "answers" } },
                { kind: "Field", name: { kind: "Name", value: "author" } },
                { kind: "Field", name: { kind: "Name", value: "body" } },
                { kind: "Field", name: { kind: "Name", value: "comments" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "deletedAt" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "tags" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "updatedBy" } },
                { kind: "Field", name: { kind: "Name", value: "upvotes" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddQuestionMutation, AddQuestionMutationVariables>;
export const QuestionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Question" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "questionId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "question" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "questionId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "updatedBy" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "author" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "body" } },
                { kind: "Field", name: { kind: "Name", value: "tags" } },
                { kind: "Field", name: { kind: "Name", value: "userHasUpvoted" } },
                { kind: "Field", name: { kind: "Name", value: "upvotes" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "answers" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                      { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                      { kind: "Field", name: { kind: "Name", value: "updatedBy" } },
                      { kind: "Field", name: { kind: "Name", value: "deletedAt" } },
                      { kind: "Field", name: { kind: "Name", value: "author" } },
                      { kind: "Field", name: { kind: "Name", value: "body" } },
                      { kind: "Field", name: { kind: "Name", value: "helpfulVotes" } },
                      { kind: "Field", name: { kind: "Name", value: "unhelpfulVotes" } },
                      { kind: "Field", name: { kind: "Name", value: "question" } },
                      { kind: "Field", name: { kind: "Name", value: "userVotedHelpful" } },
                      { kind: "Field", name: { kind: "Name", value: "userVotedUnhelpful" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "comments" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                      { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                      { kind: "Field", name: { kind: "Name", value: "updatedBy" } },
                      { kind: "Field", name: { kind: "Name", value: "deletedAt" } },
                      { kind: "Field", name: { kind: "Name", value: "author" } },
                      { kind: "Field", name: { kind: "Name", value: "body" } },
                      { kind: "Field", name: { kind: "Name", value: "question" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuestionQuery, QuestionQueryVariables>;
export const UpdateQuestionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateQuestion" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UpdateQuestionInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateQuestion" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "body" } },
                { kind: "Field", name: { kind: "Name", value: "tags" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateQuestionMutation, UpdateQuestionMutationVariables>;
export const ApproveQuestionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ApproveQuestion" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "approveQuestion" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApproveQuestionMutation, ApproveQuestionMutationVariables>;
export const RejectQuestionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RejectQuestion" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "rejectQuestion" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RejectQuestionMutation, RejectQuestionMutationVariables>;
export const ResetQuestionStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ResetQuestionStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "resetQuestionStatus" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResetQuestionStatusMutation, ResetQuestionStatusMutationVariables>;
export const DeleteQuestionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteQuestion" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "deleteQuestionId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteQuestion" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "deleteQuestionId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "deletedAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteQuestionMutation, DeleteQuestionMutationVariables>;
export const AddAnswerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddAnswer" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "AddAnswerInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addAnswer" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "author" } },
                { kind: "Field", name: { kind: "Name", value: "body" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "deletedAt" } },
                { kind: "Field", name: { kind: "Name", value: "helpfulVotes" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "question" } },
                { kind: "Field", name: { kind: "Name", value: "unhelpfulVotes" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "updatedBy" } },
                { kind: "Field", name: { kind: "Name", value: "userVotedHelpful" } },
                { kind: "Field", name: { kind: "Name", value: "userVotedUnhelpful" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddAnswerMutation, AddAnswerMutationVariables>;
export const UpdateAnswerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateAnswer" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UpdateAnswerInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateAnswer" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "author" } },
                { kind: "Field", name: { kind: "Name", value: "body" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "deletedAt" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "helpfulVotes" } },
                { kind: "Field", name: { kind: "Name", value: "question" } },
                { kind: "Field", name: { kind: "Name", value: "unhelpfulVotes" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "updatedBy" } },
                { kind: "Field", name: { kind: "Name", value: "userVotedHelpful" } },
                { kind: "Field", name: { kind: "Name", value: "userVotedUnhelpful" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateAnswerMutation, UpdateAnswerMutationVariables>;
export const DeleteAnswerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteAnswer" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "deleteAnswerId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteAnswer" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "deleteAnswerId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "body" } },
                { kind: "Field", name: { kind: "Name", value: "deletedAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteAnswerMutation, DeleteAnswerMutationVariables>;
export const AddCommentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddComment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "AddCommentInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addComment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddCommentMutation, AddCommentMutationVariables>;
export const DeleteCommentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteComment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "deleteCommentId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteComment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "deleteCommentId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteCommentMutation, DeleteCommentMutationVariables>;
export const UpdateCommentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateComment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UpdateCommentInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateComment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCommentMutation, UpdateCommentMutationVariables>;
export const UpvoteQuestionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "upvoteQuestion" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "questionId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "upvoteQuestion" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "questionId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "upvotes" } },
                { kind: "Field", name: { kind: "Name", value: "userHasUpvoted" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpvoteQuestionMutation, UpvoteQuestionMutationVariables>;
export const RemoveQuestionUpvoteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "removeQuestionUpvote" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "questionId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "removeQuestionUpvote" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "questionId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "upvotes" } },
                { kind: "Field", name: { kind: "Name", value: "userHasUpvoted" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveQuestionUpvoteMutation, RemoveQuestionUpvoteMutationVariables>;
export const MarkAnswerHelpfulDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "MarkAnswerHelpful" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "markAnswerHelpfulId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "markAnswerHelpful" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "markAnswerHelpfulId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "helpfulVotes" } },
                { kind: "Field", name: { kind: "Name", value: "unhelpfulVotes" } },
                { kind: "Field", name: { kind: "Name", value: "userVotedHelpful" } },
                { kind: "Field", name: { kind: "Name", value: "userVotedUnhelpful" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MarkAnswerHelpfulMutation, MarkAnswerHelpfulMutationVariables>;
export const MarkAnswerUnhelpfulDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "MarkAnswerUnhelpful" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "markAnswerUnhelpfulId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "markAnswerUnhelpful" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "markAnswerUnhelpfulId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "helpfulVotes" } },
                { kind: "Field", name: { kind: "Name", value: "unhelpfulVotes" } },
                { kind: "Field", name: { kind: "Name", value: "userVotedHelpful" } },
                { kind: "Field", name: { kind: "Name", value: "userVotedUnhelpful" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MarkAnswerUnhelpfulMutation, MarkAnswerUnhelpfulMutationVariables>;
export const UnmarkAnswerHelpfulDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UnmarkAnswerHelpful" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "unmarkAnswerHelpfulId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "unmarkAnswerHelpful" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "unmarkAnswerHelpfulId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "helpfulVotes" } },
                { kind: "Field", name: { kind: "Name", value: "unhelpfulVotes" } },
                { kind: "Field", name: { kind: "Name", value: "userVotedHelpful" } },
                { kind: "Field", name: { kind: "Name", value: "userVotedUnhelpful" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnmarkAnswerHelpfulMutation, UnmarkAnswerHelpfulMutationVariables>;
export const UnmarkAnswerUnhelpfulDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UnmarkAnswerUnhelpful" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "unmarkAnswerUnhelpfulId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "unmarkAnswerUnhelpful" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "unmarkAnswerUnhelpfulId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "unhelpfulVotes" } },
                { kind: "Field", name: { kind: "Name", value: "userVotedHelpful" } },
                { kind: "Field", name: { kind: "Name", value: "userVotedUnhelpful" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "helpfulVotes" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnmarkAnswerUnhelpfulMutation, UnmarkAnswerUnhelpfulMutationVariables>;
export const GetTagDetailsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetTagDetails" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "tags" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getTagDetails" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "tagDetails" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "tags" },
                      value: { kind: "Variable", name: { kind: "Name", value: "tags" } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tagDetails" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "tagName" } },
                      { kind: "Field", name: { kind: "Name", value: "tagType" } },
                      { kind: "Field", name: { kind: "Name", value: "kHubOverviewPage" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      { kind: "Field", name: { kind: "Name", value: "highlighted" } },
                      { kind: "Field", name: { kind: "Name", value: "description" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTagDetailsQuery, GetTagDetailsQueryVariables>;
export const GetResourcesForTagDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetResourcesForTag" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "tag" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getResourcesForTag" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "tagName" },
                value: { kind: "Variable", name: { kind: "Name", value: "tag" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "resources" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "sourceId" } },
                      { kind: "Field", name: { kind: "Name", value: "resourceType" } },
                      { kind: "Field", name: { kind: "Name", value: "resourceName" } },
                      { kind: "Field", name: { kind: "Name", value: "tags" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "relatedTags" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "tagName" } },
                      { kind: "Field", name: { kind: "Name", value: "tagType" } },
                      { kind: "Field", name: { kind: "Name", value: "description" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetResourcesForTagQuery, GetResourcesForTagQueryVariables>;
export const GetTagDetailsAndRelatedTagsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetTagDetailsAndRelatedTags" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "tags" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getRelatedTagDetails" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "tagDetails" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "tags" },
                      value: { kind: "Variable", name: { kind: "Name", value: "tags" } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tagDetails" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "tagName" } },
                      { kind: "Field", name: { kind: "Name", value: "tagType" } },
                      { kind: "Field", name: { kind: "Name", value: "kHubOverviewPage" } },
                      { kind: "Field", name: { kind: "Name", value: "highlighted" } },
                      { kind: "Field", name: { kind: "Name", value: "description" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relatedTags" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "tagName" } },
                            { kind: "Field", name: { kind: "Name", value: "tagType" } },
                            { kind: "Field", name: { kind: "Name", value: "kHubOverviewPage" } },
                            { kind: "Field", name: { kind: "Name", value: "highlighted" } },
                            { kind: "Field", name: { kind: "Name", value: "description" } },
                            { kind: "Field", name: { kind: "Name", value: "icon" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTagDetailsAndRelatedTagsQuery, GetTagDetailsAndRelatedTagsQueryVariables>;
export const GetAllTagDetailsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetAllTagDetails" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getAllTagDetails" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tagDetails" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "tagName" } },
                      { kind: "Field", name: { kind: "Name", value: "tagType" } },
                      { kind: "Field", name: { kind: "Name", value: "kHubOverviewPage" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      { kind: "Field", name: { kind: "Name", value: "highlighted" } },
                      { kind: "Field", name: { kind: "Name", value: "description" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllTagDetailsQuery, GetAllTagDetailsQueryVariables>;
export const DeleteGlobalTagDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteGlobalTag" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "tag" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteGlobalTag" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "tagName" },
                value: { kind: "Variable", name: { kind: "Name", value: "tag" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "tagName" } },
                { kind: "Field", name: { kind: "Name", value: "tagType" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteGlobalTagMutation, DeleteGlobalTagMutationVariables>;
export const AddGlobalTagDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddGlobalTag" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "command" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "TagSubmissionType" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addGlobalTag" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "command" },
                value: { kind: "Variable", name: { kind: "Name", value: "command" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "tagName" } },
                { kind: "Field", name: { kind: "Name", value: "tagType" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "icon" } },
                { kind: "Field", name: { kind: "Name", value: "highlighted" } },
                { kind: "Field", name: { kind: "Name", value: "kHubOverviewPage" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddGlobalTagMutation, AddGlobalTagMutationVariables>;
export const AddTagTypeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddTagType" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "tagType" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addTagType" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "tagType" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "tagType" },
                      value: { kind: "Variable", name: { kind: "Name", value: "tagType" } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "tagType" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "updatedDate" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddTagTypeMutation, AddTagTypeMutationVariables>;
export const GetAllTagTypesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetAllTagTypes" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getAllTagTypes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "tagType" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "updatedDate" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllTagTypesQuery, GetAllTagTypesQueryVariables>;
export const DeleteTagTypeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteTagType" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "tagType" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteUnusedTagType" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "tagType" },
                value: { kind: "Variable", name: { kind: "Name", value: "tagType" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "tagType" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "updatedDate" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteTagTypeMutation, DeleteTagTypeMutationVariables>;
export const GetBasicAccelerateProductDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetBasicAccelerateProduct" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "product" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "BasicProductFields" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BasicProductFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ProductResponse" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "permissions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "edit" } },
                { kind: "Field", name: { kind: "Name", value: "delete" } },
                { kind: "Field", name: { kind: "Name", value: "linkage" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "memberIds" } },
          { kind: "Field", name: { kind: "Name", value: "ownerId" } },
          { kind: "Field", name: { kind: "Name", value: "designLeadId" } },
          { kind: "Field", name: { kind: "Name", value: "techLeadId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "delegates" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "email" } }],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "l3EntityUid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "newCapabilityResult" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "score" } },
                { kind: "Field", name: { kind: "Name", value: "lastUpdated" } },
                { kind: "Field", name: { kind: "Name", value: "productId" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "yallaPlatform" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBasicAccelerateProductQuery, GetBasicAccelerateProductQueryVariables>;
export const DeleteProductDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteProduct" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "deleteProductId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteProduct" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "deleteProductId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "isDeleted" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteProductMutation, DeleteProductMutationVariables>;
export const ProductQuizAssessmentsQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "productQuizAssessmentsQuery" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "products" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "numberOfWorkers" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "newCapabilityResult" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "productId" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quizResults" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "lastUpdated" } },
                            { kind: "Field", name: { kind: "Name", value: "score" } },
                            { kind: "Field", name: { kind: "Name", value: "maxScore" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quiz" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [{ kind: "Field", name: { kind: "Name", value: "slug" } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductQuizAssessmentsQueryQuery, ProductQuizAssessmentsQueryQueryVariables>;
export const GetUserL3EntitiesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUserL3Entities" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userL3Entities" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "serviceNowId" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tier" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "parentName" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserL3EntitiesQuery, GetUserL3EntitiesQueryVariables>;
export const SearchL3EntitiesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SearchL3Entities" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "search" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pageSize" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "searchL3Entities" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "search" },
                value: { kind: "Variable", name: { kind: "Name", value: "search" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pageSize" },
                value: { kind: "Variable", name: { kind: "Name", value: "pageSize" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "page" },
                value: { kind: "Variable", name: { kind: "Name", value: "page" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "serviceNowId" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tier" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "parentName" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchL3EntitiesQuery, SearchL3EntitiesQueryVariables>;
export const DeleteAlertDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteAlert" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "deleteAlertId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteAlert" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "deleteAlertId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "isDeleted" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteAlertMutation, DeleteAlertMutationVariables>;
export const GetAccelerateProductsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetAccelerateProducts" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentUserProfile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "products" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "BasicProductFields" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BasicProductFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ProductResponse" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "permissions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "edit" } },
                { kind: "Field", name: { kind: "Name", value: "delete" } },
                { kind: "Field", name: { kind: "Name", value: "linkage" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "memberIds" } },
          { kind: "Field", name: { kind: "Name", value: "ownerId" } },
          { kind: "Field", name: { kind: "Name", value: "designLeadId" } },
          { kind: "Field", name: { kind: "Name", value: "techLeadId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "delegates" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "email" } }],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "l3EntityUid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "newCapabilityResult" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "score" } },
                { kind: "Field", name: { kind: "Name", value: "lastUpdated" } },
                { kind: "Field", name: { kind: "Name", value: "productId" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "yallaPlatform" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAccelerateProductsQuery, GetAccelerateProductsQueryVariables>;
export const GetL3EntityDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetL3Entity" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "l3Entity" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "serviceNowId" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tier" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "parentName" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "opsGroups" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "users" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "email" } },
                            { kind: "Field", name: { kind: "Name", value: "givenName" } },
                            { kind: "Field", name: { kind: "Name", value: "surname" } },
                            { kind: "Field", name: { kind: "Name", value: "jobTitle" } },
                            { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                            { kind: "Field", name: { kind: "Name", value: "userId" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "supportGroup" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetL3EntityQuery, GetL3EntityQueryVariables>;
export const GetProductImprovementGoalsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetProductImprovementGoals" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "productId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "improvementGoals" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "productId" },
                value: { kind: "Variable", name: { kind: "Name", value: "productId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "productId" } },
                { kind: "Field", name: { kind: "Name", value: "completionDate" } },
                { kind: "Field", name: { kind: "Name", value: "quizSlug" } },
                { kind: "Field", name: { kind: "Name", value: "targetScore" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProductImprovementGoalsQuery, GetProductImprovementGoalsQueryVariables>;
export const UpsertImprovementGoalDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpsertImprovementGoal" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "quizSlug" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "productId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "targetScore" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Float" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "completionDate" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Float" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "upsertImprovementGoal" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "quizSlug" },
                value: { kind: "Variable", name: { kind: "Name", value: "quizSlug" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "productId" },
                value: { kind: "Variable", name: { kind: "Name", value: "productId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "targetScore" },
                value: { kind: "Variable", name: { kind: "Name", value: "targetScore" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "completionDate" },
                value: { kind: "Variable", name: { kind: "Name", value: "completionDate" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "completionDate" } },
                { kind: "Field", name: { kind: "Name", value: "productId" } },
                { kind: "Field", name: { kind: "Name", value: "quizSlug" } },
                { kind: "Field", name: { kind: "Name", value: "targetScore" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertImprovementGoalMutation, UpsertImprovementGoalMutationVariables>;
export const DeleteImprovementGoalDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteImprovementGoal" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "quizSlug" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "productId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteImprovementGoal" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "quizSlug" },
                value: { kind: "Variable", name: { kind: "Name", value: "quizSlug" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "productId" },
                value: { kind: "Variable", name: { kind: "Name", value: "productId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "isDeleted" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteImprovementGoalMutation, DeleteImprovementGoalMutationVariables>;
export const CreateAccelerateProductDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateAccelerateProduct" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "l3Uid" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "description" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createProduct" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: { kind: "Variable", name: { kind: "Name", value: "name" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "l3Uid" },
                value: { kind: "Variable", name: { kind: "Name", value: "l3Uid" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "description" },
                value: { kind: "Variable", name: { kind: "Name", value: "description" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "l3Entity" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "serviceNowId" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "memberIds" } },
                { kind: "Field", name: { kind: "Name", value: "ownerId" } },
                { kind: "Field", name: { kind: "Name", value: "techLeadId" } },
                { kind: "Field", name: { kind: "Name", value: "designLeadId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "delegates" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "username" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateAccelerateProductMutation, CreateAccelerateProductMutationVariables>;
export const EditAccelerateProductDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "EditAccelerateProduct" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "description" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "l3Uid" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "repositories" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "EditProductRepositoriesInput" } },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "editProduct" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: { kind: "Variable", name: { kind: "Name", value: "name" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "description" },
                value: { kind: "Variable", name: { kind: "Name", value: "description" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "l3Uid" },
                value: { kind: "Variable", name: { kind: "Name", value: "l3Uid" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "repositories" },
                value: { kind: "Variable", name: { kind: "Name", value: "repositories" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "l3Entity" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "memberIds" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditAccelerateProductMutation, EditAccelerateProductMutationVariables>;
export const CreateProductLinkedResourceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "createProductLinkedResource" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "productId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "description" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "url" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createProductLinkedResource" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "productId" },
                value: { kind: "Variable", name: { kind: "Name", value: "productId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "description" },
                value: { kind: "Variable", name: { kind: "Name", value: "description" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "url" },
                value: { kind: "Variable", name: { kind: "Name", value: "url" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateProductLinkedResourceMutation, CreateProductLinkedResourceMutationVariables>;
export const DeleteProductLinkedResourceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteProductLinkedResource" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteProductLinkedResource" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "isDeleted" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteProductLinkedResourceMutation, DeleteProductLinkedResourceMutationVariables>;
export const UpdateProductLinkedResourceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateProductLinkedResource" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "description" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "url" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateProductLinkedResource" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "description" },
                value: { kind: "Variable", name: { kind: "Name", value: "description" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "url" },
                value: { kind: "Variable", name: { kind: "Name", value: "url" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProductLinkedResourceMutation, UpdateProductLinkedResourceMutationVariables>;
export const MaturityProgressDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MaturityProgress" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "quizTypes" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "QuizType" } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "maturityProgress" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "quizTypes" },
                value: { kind: "Variable", name: { kind: "Name", value: "quizTypes" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "productsWithCompletedAssessmentCount" } },
                { kind: "Field", name: { kind: "Name", value: "productsWithImprovementGoalCount" } },
                { kind: "Field", name: { kind: "Name", value: "totalProductsCount" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MaturityProgressQuery, MaturityProgressQueryVariables>;
export const SearchProductsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SearchProducts" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "search" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "skip" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "limit" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "productsPaginated" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "search" },
                value: { kind: "Variable", name: { kind: "Name", value: "search" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "skip" },
                value: { kind: "Variable", name: { kind: "Name", value: "skip" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "limit" },
                value: { kind: "Variable", name: { kind: "Name", value: "limit" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "items" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "BasicProductFields" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BasicProductFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ProductResponse" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "permissions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "edit" } },
                { kind: "Field", name: { kind: "Name", value: "delete" } },
                { kind: "Field", name: { kind: "Name", value: "linkage" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "memberIds" } },
          { kind: "Field", name: { kind: "Name", value: "ownerId" } },
          { kind: "Field", name: { kind: "Name", value: "designLeadId" } },
          { kind: "Field", name: { kind: "Name", value: "techLeadId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "delegates" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "email" } }],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "l3EntityUid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "newCapabilityResult" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "score" } },
                { kind: "Field", name: { kind: "Name", value: "lastUpdated" } },
                { kind: "Field", name: { kind: "Name", value: "productId" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "yallaPlatform" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchProductsQuery, SearchProductsQueryVariables>;
export const CreateSupportGroupDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateSupportGroup" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "SupportGroupCreateArgs" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createSupportGroup" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "SupportGroupMutationFields" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SupportGroupMutationFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "SupportGroup" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "opsGroupId" } },
          { kind: "Field", name: { kind: "Name", value: "productId" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "contactMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "value" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "users" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "givenName" } },
                { kind: "Field", name: { kind: "Name", value: "surname" } },
                { kind: "Field", name: { kind: "Name", value: "userId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSupportGroupMutation, CreateSupportGroupMutationVariables>;
export const UpdateSupportGroupDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateSupportGroup" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "SupportGroupUpdateArgs" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateSupportGroup" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "SupportGroupMutationFields" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SupportGroupMutationFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "SupportGroup" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "opsGroupId" } },
          { kind: "Field", name: { kind: "Name", value: "productId" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "contactMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "value" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "users" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "givenName" } },
                { kind: "Field", name: { kind: "Name", value: "surname" } },
                { kind: "Field", name: { kind: "Name", value: "userId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSupportGroupMutation, UpdateSupportGroupMutationVariables>;
export const DeleteSupportGroupDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteSupportGroup" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "SupportGroupDeleteArgs" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteSupportGroup" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteSupportGroupMutation, DeleteSupportGroupMutationVariables>;
export const CompleteSupportGroupDeletionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CompleteSupportGroupDeletion" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "opsGroupId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "completeSupportGroupDeletion" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "opsGroupId" },
                value: { kind: "Variable", name: { kind: "Name", value: "opsGroupId" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompleteSupportGroupDeletionMutation, CompleteSupportGroupDeletionMutationVariables>;
export const CreateAlertDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateAlert" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "AlertCreateArgs" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createAlert" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "AlertFields" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "supportGroup" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "opsGroupId" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "User" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "givenName" } },
          { kind: "Field", name: { kind: "Name", value: "surname" } },
          { kind: "Field", name: { kind: "Name", value: "jobTitle" } },
          { kind: "Field", name: { kind: "Name", value: "userId" } },
          { kind: "Field", name: { kind: "Name", value: "photo" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "contact" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "slack" } },
                { kind: "Field", name: { kind: "Name", value: "teams" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AlertFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Alert" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "dateRaised" } },
          { kind: "Field", name: { kind: "Name", value: "dateUpdated" } },
          { kind: "Field", name: { kind: "Name", value: "alertStatus" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "resolutionNote" } },
          {
            kind: "InlineFragment",
            directives: [{ kind: "Directive", name: { kind: "Name", value: "defer" } }],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "raisedBy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "UserFields" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateAlertMutation, CreateAlertMutationVariables>;
export const GetMetricsForBpDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetMetricsForBP" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "doraMetrics" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "leadTime" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "commitToMergeHours" } },
                      { kind: "Field", name: { kind: "Name", value: "mergeToProdHours" } },
                      { kind: "Field", name: { kind: "Name", value: "leadTimeHours" } },
                      { kind: "Field", name: { kind: "Name", value: "targetValue" } },
                      { kind: "Field", name: { kind: "Name", value: "trendingCommitToMergeHours" } },
                      { kind: "Field", name: { kind: "Name", value: "trendingMergeToProdHours" } },
                      { kind: "Field", name: { kind: "Name", value: "trendingLeadTimeHours" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMetricsForBpQuery, GetMetricsForBpQueryVariables>;
export const GetRepoInfoDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetRepoInfo" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "repositoryId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getRepoInfo" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "repositoryId" },
                value: { kind: "Variable", name: { kind: "Name", value: "repositoryId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "repositoryId" } },
                { kind: "Field", name: { kind: "Name", value: "repositoryName" } },
                { kind: "Field", name: { kind: "Name", value: "projectId" } },
                { kind: "Field", name: { kind: "Name", value: "projectName" } },
                { kind: "Field", name: { kind: "Name", value: "organisationName" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRepoInfoQuery, GetRepoInfoQueryVariables>;
export const GetAllActiveAdoReposDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetAllActiveADORepos" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "organisationName" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "projectName" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getAllActiveADORepos" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organisationName" },
                value: { kind: "Variable", name: { kind: "Name", value: "organisationName" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "projectName" },
                value: { kind: "Variable", name: { kind: "Name", value: "projectName" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "isDisabled" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllActiveAdoReposQuery, GetAllActiveAdoReposQueryVariables>;
export const AddProductMembersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddProductMembers" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "memberEmails" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "productId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addProductMembers" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "memberEmails" },
                value: { kind: "Variable", name: { kind: "Name", value: "memberEmails" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "productId" },
                value: { kind: "Variable", name: { kind: "Name", value: "productId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddProductMembersMutation, AddProductMembersMutationVariables>;
export const RemoveProductMembersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RemoveProductMembers" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "memberEmails" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "productId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "removeProductMembers" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "memberEmails" },
                value: { kind: "Variable", name: { kind: "Name", value: "memberEmails" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "productId" },
                value: { kind: "Variable", name: { kind: "Name", value: "productId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveProductMembersMutation, RemoveProductMembersMutationVariables>;
export const LeaveProductDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "LeaveProduct" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "productId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "leaveProduct" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "productId" },
                value: { kind: "Variable", name: { kind: "Name", value: "productId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "memberIds" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeaveProductMutation, LeaveProductMutationVariables>;
export const JoinProductDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "JoinProduct" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "productId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "joinProduct" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "productId" },
                value: { kind: "Variable", name: { kind: "Name", value: "productId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "memberIds" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JoinProductMutation, JoinProductMutationVariables>;
export const UserRolesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "UserRoles" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentUserProfile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "isOwner" } },
                { kind: "Field", name: { kind: "Name", value: "isDelegate" } },
                { kind: "Field", name: { kind: "Name", value: "isTechLead" } },
                { kind: "Field", name: { kind: "Name", value: "isDesignLead" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserRolesQuery, UserRolesQueryVariables>;
export const CurrentUserProfileDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CurrentUserProfile" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentUserProfile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CurrentUserProfileQuery, CurrentUserProfileQueryVariables>;
export const QuizzesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Quizzes" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "quizTypes" } },
          type: {
            kind: "ListType",
            type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "QuizType" } } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "quizzes" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "quizTypes" },
                value: { kind: "Variable", name: { kind: "Name", value: "quizTypes" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "category" } },
                { kind: "Field", name: { kind: "Name", value: "quizType" } },
                { kind: "Field", name: { kind: "Name", value: "isRecentlyUpdated" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "questions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "metric" } },
                      { kind: "Field", name: { kind: "Name", value: "question" } },
                      { kind: "Field", name: { kind: "Name", value: "isRecentlyUpdated" } },
                      { kind: "Field", name: { kind: "Name", value: "back" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "options" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "label" } },
                            { kind: "Field", name: { kind: "Name", value: "value" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuizzesQuery, QuizzesQueryVariables>;
export const QuizResultDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "QuizResult" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "entityId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "quizSlug" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "QuizSlug" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "productQuizResult" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "entityId" },
                value: { kind: "Variable", name: { kind: "Name", value: "entityId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "quizSlug" },
                value: { kind: "Variable", name: { kind: "Name", value: "quizSlug" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "answers" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "option_id" } },
                      { kind: "Field", name: { kind: "Name", value: "question_id" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "entityId" } },
                { kind: "Field", name: { kind: "Name", value: "lastUpdated" } },
                { kind: "Field", name: { kind: "Name", value: "maxScore" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quiz" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "QuizParts" } }],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "quizType" } },
                { kind: "Field", name: { kind: "Name", value: "score" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "QuizParts" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Quiz" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "category" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "href" } },
          { kind: "Field", name: { kind: "Name", value: "isRecentlyUpdated" } },
          { kind: "Field", name: { kind: "Name", value: "maxScore" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "questions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "back" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "isRecentlyUpdated" } },
                { kind: "Field", name: { kind: "Name", value: "metric" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "options" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "label" } },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "question" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "quizType" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuizResultQuery, QuizResultQueryVariables>;
export const SubmitProductQuizResultDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SubmitProductQuizResult" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ProductSubmissionInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "submitProductQuizResult" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "score" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quiz" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubmitProductQuizResultMutation, SubmitProductQuizResultMutationVariables>;
export const CreateSupportTicketDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateSupportTicket" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "description" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "supportType" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "SupportType" } },
          defaultValue: { kind: "NullValue" },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "title" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          defaultValue: { kind: "NullValue" },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "urgency" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Urgency" } },
          defaultValue: { kind: "NullValue" },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "serviceName" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "ServiceName" } },
          defaultValue: { kind: "NullValue" },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "incidentUrl" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          defaultValue: { kind: "NullValue" },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "incidentId" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          defaultValue: { kind: "NullValue" },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createSupportTicket" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "description" },
                value: { kind: "Variable", name: { kind: "Name", value: "description" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "supportType" },
                value: { kind: "Variable", name: { kind: "Name", value: "supportType" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "title" },
                value: { kind: "Variable", name: { kind: "Name", value: "title" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "urgency" },
                value: { kind: "Variable", name: { kind: "Name", value: "urgency" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "serviceName" },
                value: { kind: "Variable", name: { kind: "Name", value: "serviceName" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "incidentUrl" },
                value: { kind: "Variable", name: { kind: "Name", value: "incidentUrl" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "incidentId" },
                value: { kind: "Variable", name: { kind: "Name", value: "incidentId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "supportType" } },
                { kind: "Field", name: { kind: "Name", value: "serviceName" } },
                { kind: "Field", name: { kind: "Name", value: "incidentUrl" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSupportTicketMutation, CreateSupportTicketMutationVariables>;
export const GetUserByEmailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUserByEmail" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getUserByEmail" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: { kind: "Variable", name: { kind: "Name", value: "email" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "jobTitle" } },
                { kind: "Field", name: { kind: "Name", value: "photo" } },
                { kind: "Field", name: { kind: "Name", value: "givenName" } },
                { kind: "Field", name: { kind: "Name", value: "surname" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "userId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserByEmailQuery, GetUserByEmailQueryVariables>;
export const ListSurveysDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ListSurveys" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listSurveys" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "surveyId" } },
                { kind: "Field", name: { kind: "Name", value: "author" } },
                { kind: "Field", name: { kind: "Name", value: "linkToSurvey" } },
                { kind: "Field", name: { kind: "Name", value: "startDate" } },
                { kind: "Field", name: { kind: "Name", value: "endDate" } },
                { kind: "Field", name: { kind: "Name", value: "estimatedTime" } },
                { kind: "Field", name: { kind: "Name", value: "approved" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "surveyName" } },
                { kind: "Field", name: { kind: "Name", value: "lastUpdatedBy" } },
                { kind: "Field", name: { kind: "Name", value: "createdDate" } },
                { kind: "Field", name: { kind: "Name", value: "updatedDate" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListSurveysQuery, ListSurveysQueryVariables>;
export const HasSurveyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "HasSurvey" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "hasSurvey" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "surveyId" } },
                { kind: "Field", name: { kind: "Name", value: "surveyName" } },
                { kind: "Field", name: { kind: "Name", value: "linkToSurvey" } },
                { kind: "Field", name: { kind: "Name", value: "estimatedTime" } },
                { kind: "Field", name: { kind: "Name", value: "createdDate" } },
                { kind: "Field", name: { kind: "Name", value: "updatedDate" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HasSurveyQuery, HasSurveyQueryVariables>;
export const PersistSurveyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "PersistSurvey" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "survey" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "SurveyInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "persistSurvey" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "survey" },
                value: { kind: "Variable", name: { kind: "Name", value: "survey" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "author" } },
                { kind: "Field", name: { kind: "Name", value: "startDate" } },
                { kind: "Field", name: { kind: "Name", value: "endDate" } },
                { kind: "Field", name: { kind: "Name", value: "estimatedTime" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "linkToSurvey" } },
                { kind: "Field", name: { kind: "Name", value: "surveyName" } },
                { kind: "Field", name: { kind: "Name", value: "lastUpdatedBy" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PersistSurveyMutation, PersistSurveyMutationVariables>;
export const UpdateSurveyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateSurvey" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "survey" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "SurveyInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateSurvey" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "survey" },
                value: { kind: "Variable", name: { kind: "Name", value: "survey" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "surveyId" } },
                { kind: "Field", name: { kind: "Name", value: "author" } },
                { kind: "Field", name: { kind: "Name", value: "startDate" } },
                { kind: "Field", name: { kind: "Name", value: "endDate" } },
                { kind: "Field", name: { kind: "Name", value: "estimatedTime" } },
                { kind: "Field", name: { kind: "Name", value: "approved" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "linkToSurvey" } },
                { kind: "Field", name: { kind: "Name", value: "surveyName" } },
                { kind: "Field", name: { kind: "Name", value: "lastUpdatedBy" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSurveyMutation, UpdateSurveyMutationVariables>;
export const RemoveSurveyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RemoveSurvey" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "surveyId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "removeSurvey" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "surveyId" },
                value: { kind: "Variable", name: { kind: "Name", value: "surveyId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveSurveyMutation, RemoveSurveyMutationVariables>;
export const CompleteSurveyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CompleteSurvey" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "completeSurvey" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "CompleteSurveyInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "completeSurvey" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "completeSurvey" },
                value: { kind: "Variable", name: { kind: "Name", value: "completeSurvey" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "userId" } },
                { kind: "Field", name: { kind: "Name", value: "surveyId" } },
                { kind: "Field", name: { kind: "Name", value: "hasCompletedSurvey" } },
                { kind: "Field", name: { kind: "Name", value: "surveyStatus" } },
                { kind: "Field", name: { kind: "Name", value: "userEmail" } },
                { kind: "Field", name: { kind: "Name", value: "surveyName" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompleteSurveyMutation, CompleteSurveyMutationVariables>;
export const GetProductEnvironmentByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetProductEnvironmentById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "productId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "product" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "productId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "yallaPlatform" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "environments" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "instances" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "health" } },
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                  { kind: "Field", name: { kind: "Name", value: "syncStatus" } },
                                  { kind: "Field", name: { kind: "Name", value: "platformName" } },
                                  { kind: "Field", name: { kind: "Name", value: "serviceName" } },
                                  { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "deployment" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                                        { kind: "Field", name: { kind: "Name", value: "sourceCommitMessage" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProductEnvironmentByIdQuery, GetProductEnvironmentByIdQueryVariables>;
export const GetYallaServiceInfoForServicePageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetYallaServiceInfoForServicePage" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "serviceName" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "platformName" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getYallaService" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: { kind: "Variable", name: { kind: "Name", value: "serviceName" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "platformName" },
                value: { kind: "Variable", name: { kind: "Name", value: "platformName" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "FragmentSpread", name: { kind: "Name", value: "InstanceTab_YallaService" } },
                { kind: "FragmentSpread", name: { kind: "Name", value: "DeploymentsTab_YallaService" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "InstanceCard_YallaServiceInstance" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "YallaServiceInstance" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "environment" } },
          { kind: "Field", name: { kind: "Name", value: "health" } },
          { kind: "Field", name: { kind: "Name", value: "syncStatus" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "InstanceCard_YallaDeployment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "YallaDeployment" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "version" } },
          { kind: "Field", name: { kind: "Name", value: "sourceCommitMessage" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DeploymentStatus_YallaDeployment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "YallaDeployment" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "deploymentSteps" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "environment" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "InstanceTab_YallaService" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "YallaService" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "instances" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "version" } },
                { kind: "FragmentSpread", name: { kind: "Name", value: "InstanceCard_YallaServiceInstance" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "deployments" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "InstanceCard_YallaDeployment" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DeploymentsTab_YallaService" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "YallaService" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deployments" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "serviceName" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "version" } },
                { kind: "Field", name: { kind: "Name", value: "sourceCommitMessage" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "deploymentSteps" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "environment" } },
                      { kind: "Field", name: { kind: "Name", value: "status" } },
                    ],
                  },
                },
                { kind: "FragmentSpread", name: { kind: "Name", value: "DeploymentStatus_YallaDeployment" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetYallaServiceInfoForServicePageQuery, GetYallaServiceInfoForServicePageQueryVariables>;
export const GetYallaPlatformNameFromProductDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetYallaPlatformNameFromProduct" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "product" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "yallaPlatform" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetYallaPlatformNameFromProductQuery, GetYallaPlatformNameFromProductQueryVariables>;
export const GetYallaServiceInfoForInstancePageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetYallaServiceInfoForInstancePage" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "serviceName" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "platformName" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getYallaService" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: { kind: "Variable", name: { kind: "Name", value: "serviceName" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "platformName" },
                value: { kind: "Variable", name: { kind: "Name", value: "platformName" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "instances" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "version" } },
                      { kind: "Field", name: { kind: "Name", value: "environment" } },
                      { kind: "FragmentSpread", name: { kind: "Name", value: "DeployInfoList_YallaServiceInstance" } },
                      { kind: "FragmentSpread", name: { kind: "Name", value: "StatusCard_YallaServiceInstance" } },
                      { kind: "FragmentSpread", name: { kind: "Name", value: "InstanceLinks_YallaServiceInstance" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DeploymentInfoItem_YallaServiceInstanceChange" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "YallaServiceInstanceChange" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "commitUrl" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "date" } },
          { kind: "Field", name: { kind: "Name", value: "kind" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DeployInfoList_YallaServiceInstance" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "YallaServiceInstance" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "changes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "commit" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "DeploymentInfoItem_YallaServiceInstanceChange" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "StatusCard_YallaServiceInstance" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "YallaServiceInstance" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "syncStatus" } },
          { kind: "Field", name: { kind: "Name", value: "health" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "InstanceLinks_YallaServiceInstance" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "YallaServiceInstance" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "integrationLinks" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "argoCd" } },
                { kind: "Field", name: { kind: "Name", value: "repositories" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetYallaServiceInfoForInstancePageQuery, GetYallaServiceInfoForInstancePageQueryVariables>;
export const GetAccelerateProductDetailsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetAccelerateProductDetails" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "product" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "memberIds" } },
                { kind: "Field", name: { kind: "Name", value: "ownerId" } },
                { kind: "Field", name: { kind: "Name", value: "designLeadId" } },
                { kind: "Field", name: { kind: "Name", value: "techLeadId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "delegates" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "email" } }],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "l3EntityUid" } },
                { kind: "Field", name: { kind: "Name", value: "createdDate" } },
                { kind: "Field", name: { kind: "Name", value: "lastUpdatedById" } },
                { kind: "Field", name: { kind: "Name", value: "createdById" } },
                { kind: "Field", name: { kind: "Name", value: "lastUpdated" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "yallaPlatform" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cloudSpending" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "cloudabilityName" } },
                      { kind: "Field", name: { kind: "Name", value: "cloudabilityViewId" } },
                      { kind: "Field", name: { kind: "Name", value: "previousMonth" } },
                      { kind: "Field", name: { kind: "Name", value: "currentMonth" } },
                      { kind: "Field", name: { kind: "Name", value: "currentMonthEstimate" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "linkedResources" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "description" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAccelerateProductDetailsQuery, GetAccelerateProductDetailsQueryVariables>;
export const GetAccelerateProductDetailsThatAreVerySlowDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetAccelerateProductDetailsThatAreVerySlow" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "product" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "l3Entity" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "serviceNowId" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "tier" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "parentName" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "description" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "yallaPlatform" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "products" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "platformName" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "services" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                  { kind: "Field", name: { kind: "Name", value: "platformName" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "instances" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "syncStatus" } },
                                        { kind: "Field", name: { kind: "Name", value: "version" } },
                                        { kind: "Field", name: { kind: "Name", value: "health" } },
                                        { kind: "Field", name: { kind: "Name", value: "environment" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "deployments" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "serviceName" } },
                                        { kind: "Field", name: { kind: "Name", value: "status" } },
                                        { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                                        { kind: "Field", name: { kind: "Name", value: "version" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "deploymentSteps" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "environment" } },
                                              { kind: "Field", name: { kind: "Name", value: "status" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "newCapabilityResult" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "productId" } },
                      { kind: "Field", name: { kind: "Name", value: "lastUpdated" } },
                      { kind: "Field", name: { kind: "Name", value: "score" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "groupedByCategory" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "categoryName" } },
                            { kind: "Field", name: { kind: "Name", value: "lastUpdated" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quizResults" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "answers" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "option" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "label" } },
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                            ],
                                          },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "option_id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "question" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "back" } },
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "isRecentlyUpdated" } },
                                              { kind: "Field", name: { kind: "Name", value: "metric" } },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "options" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    { kind: "Field", name: { kind: "Name", value: "id" } },
                                                    { kind: "Field", name: { kind: "Name", value: "label" } },
                                                    { kind: "Field", name: { kind: "Name", value: "value" } },
                                                  ],
                                                },
                                              },
                                              { kind: "Field", name: { kind: "Name", value: "question" } },
                                            ],
                                          },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "question_id" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "entityId" } },
                                  { kind: "Field", name: { kind: "Name", value: "lastUpdated" } },
                                  { kind: "Field", name: { kind: "Name", value: "maxScore" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quiz" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "category" } },
                                        { kind: "Field", name: { kind: "Name", value: "description" } },
                                        { kind: "Field", name: { kind: "Name", value: "href" } },
                                        { kind: "Field", name: { kind: "Name", value: "isRecentlyUpdated" } },
                                        { kind: "Field", name: { kind: "Name", value: "maxScore" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "questions" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "back" } },
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "isRecentlyUpdated" } },
                                              { kind: "Field", name: { kind: "Name", value: "metric" } },
                                              { kind: "Field", name: { kind: "Name", value: "question" } },
                                            ],
                                          },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "quizType" } },
                                        { kind: "Field", name: { kind: "Name", value: "slug" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "quizType" } },
                                  { kind: "Field", name: { kind: "Name", value: "score" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "score" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quizResults" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "answers" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "option" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "label" } },
                                        { kind: "Field", name: { kind: "Name", value: "value" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "option_id" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "question" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "question" } },
                                        { kind: "Field", name: { kind: "Name", value: "back" } },
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "isRecentlyUpdated" } },
                                        { kind: "Field", name: { kind: "Name", value: "metric" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "options" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "label" } },
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "question_id" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "entityId" } },
                            { kind: "Field", name: { kind: "Name", value: "lastUpdated" } },
                            { kind: "Field", name: { kind: "Name", value: "maxScore" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quiz" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "category" } },
                                  { kind: "Field", name: { kind: "Name", value: "description" } },
                                  { kind: "Field", name: { kind: "Name", value: "href" } },
                                  { kind: "Field", name: { kind: "Name", value: "isRecentlyUpdated" } },
                                  { kind: "Field", name: { kind: "Name", value: "maxScore" } },
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "questions" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "back" } },
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "isRecentlyUpdated" } },
                                        { kind: "Field", name: { kind: "Name", value: "metric" } },
                                        { kind: "Field", name: { kind: "Name", value: "question" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "options" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "label" } },
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "quizType" } },
                                  { kind: "Field", name: { kind: "Name", value: "slug" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "quizType" } },
                            { kind: "Field", name: { kind: "Name", value: "score" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAccelerateProductDetailsThatAreVerySlowQuery,
  GetAccelerateProductDetailsThatAreVerySlowQueryVariables
>;
export const GetAccelerateProductDetailsForASpecificUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetAccelerateProductDetailsForASpecificUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "product" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "permissions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "edit" } },
                      { kind: "Field", name: { kind: "Name", value: "delete" } },
                      { kind: "Field", name: { kind: "Name", value: "linkage" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "repositoryInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "repositories" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "link" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "organisationName" } },
                      { kind: "Field", name: { kind: "Name", value: "projectName" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "members" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "jobTitle" } },
                      { kind: "Field", name: { kind: "Name", value: "photo" } },
                      { kind: "Field", name: { kind: "Name", value: "givenName" } },
                      { kind: "Field", name: { kind: "Name", value: "surname" } },
                      { kind: "Field", name: { kind: "Name", value: "userId" } },
                      { kind: "Field", name: { kind: "Name", value: "enabled" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAccelerateProductDetailsForASpecificUserQuery,
  GetAccelerateProductDetailsForASpecificUserQueryVariables
>;
export const GetAccelerateProductSupportGroupDetailsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetAccelerateProductSupportGroupDetails" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "date" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Float" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "product" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "ownerId" } },
                { kind: "Field", name: { kind: "Name", value: "techLeadId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "delegates" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "email" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "permissions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "edit" } },
                      { kind: "Field", name: { kind: "Name", value: "delete" } },
                      { kind: "Field", name: { kind: "Name", value: "linkage" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "l3Entity" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "serviceNowId" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "opsGroups" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supportGroup" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "opsGroupId" } },
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "users" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "_id" } },
                                  { kind: "Field", name: { kind: "Name", value: "givenName" } },
                                  { kind: "Field", name: { kind: "Name", value: "surname" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "supportGroups" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "isOnboarding" } },
                      { kind: "Field", name: { kind: "Name", value: "description" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "areOnCallMembersPublic" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "opsGroup" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "productId" } },
                      { kind: "Field", name: { kind: "Name", value: "opsGroupId" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "users" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "_id" } },
                            { kind: "Field", name: { kind: "Name", value: "email" } },
                            { kind: "Field", name: { kind: "Name", value: "givenName" } },
                            { kind: "Field", name: { kind: "Name", value: "surname" } },
                            { kind: "Field", name: { kind: "Name", value: "jobTitle" } },
                            { kind: "Field", name: { kind: "Name", value: "userId" } },
                            { kind: "Field", name: { kind: "Name", value: "photo" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "contact" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "email" } },
                                  { kind: "Field", name: { kind: "Name", value: "slack" } },
                                  { kind: "Field", name: { kind: "Name", value: "teams" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "contactMethod" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            { kind: "Field", name: { kind: "Name", value: "value" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "alerts" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "dateRaised" } },
                            { kind: "Field", name: { kind: "Name", value: "dateUpdated" } },
                            { kind: "Field", name: { kind: "Name", value: "alertStatus" } },
                            { kind: "Field", name: { kind: "Name", value: "description" } },
                            { kind: "Field", name: { kind: "Name", value: "resolutionNote" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "raisedBy" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "email" } },
                                  { kind: "Field", name: { kind: "Name", value: "givenName" } },
                                  { kind: "Field", name: { kind: "Name", value: "surname" } },
                                  { kind: "Field", name: { kind: "Name", value: "jobTitle" } },
                                  { kind: "Field", name: { kind: "Name", value: "userId" } },
                                  { kind: "Field", name: { kind: "Name", value: "photo" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "contact" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "email" } },
                                        { kind: "Field", name: { kind: "Name", value: "slack" } },
                                        { kind: "Field", name: { kind: "Name", value: "teams" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "links" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "manageEscalationPoliciesLink" } },
                            { kind: "Field", name: { kind: "Name", value: "manageScheduleLink" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userShifts" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "date" },
                            value: { kind: "Variable", name: { kind: "Name", value: "date" } },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "date" } },
                            { kind: "Field", name: { kind: "Name", value: "end" } },
                            { kind: "Field", name: { kind: "Name", value: "isOnCall" } },
                            { kind: "Field", name: { kind: "Name", value: "scheduleName" } },
                            { kind: "Field", name: { kind: "Name", value: "start" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAccelerateProductSupportGroupDetailsQuery,
  GetAccelerateProductSupportGroupDetailsQueryVariables
>;
