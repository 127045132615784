import { MsalProvider } from "@azure/msal-react";
import { AuthenticationWrapper } from "./authentication-wrapper";
import { AuthProvider } from "./auth-provider";
import { useEffect, type PropsWithChildren } from "react";
import { EventType, type PublicClientApplication } from "@azure/msal-browser";
import { identifyUser } from "../utils/event-tracker";
import { hash } from "../utils/sha256";

export const MsalAuthProvider = ({ children, instance }: PropsWithChildren<{ instance: PublicClientApplication }>) => {
  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        const canonicalEmail = instance.getAllAccounts()[0].username.trim().toLowerCase();
        identifyUser({
          uniqueId: hash(canonicalEmail),
        });
      }
    });

    // Clean up the event listener on unmount
    return () => {
      instance.removeEventCallback(callbackId as string);
    };
  }, [instance]);
  return (
    <MsalProvider instance={instance}>
      <AuthProvider>
        <AuthenticationWrapper>{children}</AuthenticationWrapper>
      </AuthProvider>
    </MsalProvider>
  );
};
