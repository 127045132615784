"use client";

import { createContext, type PropsWithChildren } from "react";
import type { UserRolesQuery } from "../gql/graphql";
import { useGetUserRoles } from "../hooks/use-get-user-roles";
import { useIsAuthenticated, type AuthState } from "../hooks/use-is-authenticated";

export const AuthContext = createContext<AuthState & Omit<UserRolesQuery["currentUserProfile"], "_id" | "username">>({
  isAuthenticated: false,
  isDelegate: false,
  isDesignLead: false,
  isOwner: false,
  isTechLead: false,
});

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const auth = useIsAuthenticated();
  const roles = useGetUserRoles();

  return <AuthContext.Provider value={{ ...auth, ...roles }}>{children}</AuthContext.Provider>;
};
